export const strings = {
  en: {
    courier: 'Courier',
    trackingId: 'Tracking ID',
    orderId: 'Order ID',
    lastUpdate: 'Last Update',
    orderPlacedOn: 'Order Placed On',
    orderType: 'Order Type',
    riderInfo: 'Rider Info',
    orderPlaced: 'Order Placed',
    dispatched: 'Dispatched',
    inTransit: 'In Transit',
    failedDelivery: 'Failed Delivery',
    ofd: 'Out For Delivery',
    delivered: 'Delivered',
    pickupRequested: 'Pickup Requested',
    updatedOn: 'Updated On',
    returnInstructions: 'Reattempt Instructions',
    returnPlaced: 'Return Placed',
    pickupCancelled: 'Pickup Cancelled',
    ofp: 'Out For Pickup',
    pickedup: 'Pickedup',
    returned: 'Returned',
    reverseReturned: 'Return Received',
    readyToShip: 'Ready To Ship',
    lost: 'Lost',
    damaged: 'Damaged',
    cancelled: 'Cancelled',
    requestPlaced: 'Request Placed',
    confirmed: 'Confirmed',
    requestAccepted: 'Request Accepted',
    requestAcceptedWithDate: date => `Your return has been accepted on ${date}`,
    confirm: 'Confirm',
    requestReturn: 'Request Return',
    pickupFailed: 'Pickup Failed',
    reachedW: 'Reached Warehouse',
    requestCancelled: 'Request Cancelled',
    requestRejected: 'Request Rejected',
    location: 'Location',
    time: 'Time',
    remark: 'Remark',
    shippingLabel: 'Shipping Label',
    shippingLabelInProgress:
      'Fetching the label from the carrier, it will be available for download in a few minutes',
    shippingLabelReady:
      'Your shipping label has been generated, now you can download it',
    pleaseTellUs: 'Please tell us what happened?',
    pressNextBtn: 'Press Next button at bottom after selection.',
    agentDidntCall: 'Delivery Agent did not call me',
    noAttemptToAddress: 'No attempt was made to the delivery address',
    wasNotAvailable: 'I was not available when delivery attempt was made',
    nprAgentDidntCall: 'Pickup Agent did not call me',
    nprNoAttemptToAddress: 'No attempt was made to the pickup address',
    nprWasNotAvailable: 'I was not available when pickup attempt was made',
    doNotWantProduct: 'Do not want the product anymore',
    doNotWantReturnProduct: 'Do not want to return the product any more',
    others: 'Others',
    yes: 'Yes',
    no: 'No',
    preferredDate: 'Preferred delivery date',
    nprPreferredDate: 'Preferred pickup date',
    landmark: 'Landmark',
    address: 'Address',
    pincode: 'Pincode',
    provideAltNo: 'Please provide an Alternate Number',
    comments: 'Comments',
    searchBy: 'Search By',
    checkCurrentStatus: 'Check current status of your shipment.',
    trackYourOrder: 'Track Your Order',
    incorrectSearch:
      "The order/shipment you're trying to track has not been shipped yet or the order number entered is incorrect.",
    viewTracking: 'View Tracking',
    on: 'on',
    status: 'Status',
    orderDetails: 'Order Details',
    noScan1: 'Ready to Ship!',
    noScan2: 'Tracking details will be available shortly.',
    addressErr: 'Address cannot be blank or less than 10 character.',
    pincodeErr: 'Please enter 6 digit pincode.',
    pincodeNotServiceable:
      'Pincode is not serviceable, please ship order to us.',
    provideLandmark:
      'Please provide a landmark for courier partner to locate your address.',
    landmarkErr: 'Landmark cannot be blank or less than 5 character.',
    dateErr: 'Please enter a valid date.',
    commentErr: 'Comment cannot be blank.',
    addressCharLimit:
      'Please enter at least 10 characters and no more than 600.',
    phoneErr: 'Please enter a 10 digit valid phone number.',
    emailidErr: 'Please enter a valid email Id.',
    invalidImageErr: 'The file format is not supported',
    chooseOption: 'Please choose any option.',
    actionInProgress: 'Action in progress..',
    feedbackToastr1:
      'Thanks for your feedback! Look forward to serving you again.',
    feedbackToastr2:
      'Thanks for the feedback! We will work on providing you a better experience next time!',
    abfrlFeedbackToast:
      'Thank you for the feedback, redirecting you to LimeSurvey',
    ratingErr: 'Rating cannot be empty',
    apiErr: 'We are unable to process the request, please try again later',
    updateContactToastr:
      'Thanks for the update!, we will start sending notifications on updated contact number.',
    noDataFound:
      "The order/shipment you're trying to track has not been shipped yet or the order number entered is incorrect.",
    veryPoorAbfrl: 'least likely to recommend',
    veryPoor: 'Very Poor',
    excellentAbfrl: 'highly likely to recommend',
    excellent: 'Excellent',
    veryUnlikely: 'Very Unlikely',
    veryLikely: 'Very Likely',
    submit: 'Submit',
    youFacedAnyIssue: 'Did you face any other issue?',
    updateContact: 'Update Contact',
    updateContactTooltip: 'Click here to change contact no',
    myOrders: 'My Orders',
    myOrdersTooltip: 'Click here to view your orders',
    takeAction: 'Take Action',
    takeActionTooltip: 'Click here to raise an issue',
    next: 'Next',
    anyOtherDate: 'Any other date?',
    share: 'Share',
    expectedDelivery: 'Expected Delivery',
    viewDetail: 'View Details',
    viewSummary: 'View Summary',
    inputFeedback: 'Please share your feedback',
    feedbackMatters: 'Your feedback matters!',
    ratingFeedback: 'Thank you for your valuable feedback!',
    youRatedUs: 'You rated us',
    youCommented: 'You commented',
    pageNotFound: 'Page Not Found',
    ops404: "Oops, that's a 404.",
    youFoundIncorrectLink: 'You seem to have found an incorrect link.',
    contactNumber: 'Contact Number',
    updateContactNumber: 'Update Contact Number',
    updateContactNumberToastr:
      'This allows us to contact you & send important updates about the shipment.',
    searchByTrackingId: 'Tracking ID/AWB',
    searchByOrderId: 'Order ID/No',
    langName: 'English',
    rateUs: 'How would you rate for:',
    orderPlaceOnDateFn: date => `Order Placed On ${date}`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    submittedConcern: courier =>
      `We will followup with ${courier} to process your request.`,
    askRatingFeedback: courier =>
      `How was your delivery experience with ${courier}?`,
    askRatingFeedbackTrell:
      'Based on your recent delivery experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvind: 'How was your shopping experience with NNNOW?',
    askRatingFeedbackAbfrl: brandName =>
      `Based on this delivery experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience: 'How was your overall returns experience?',
    askNonPickedExperience:
      'We noticed your shipment couldn’t be picked up. How was your overall returns experience?',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (title, websiteUrl) =>
      `Please contact ${title} at ${websiteUrl} for the correct tracking link and for information about your order.`,
    statusOnDateFn: (status, date) => `${status} on ${date}`,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'Please tell us what went wrong!';
        case 6:
        case 7:
        case 8:
          return 'How can we improve?';
        default:
          return 'Give a compliment!';
      }
    },
    edd: (expression, message) => {
      switch (expression) {
        case 1:
          return message;
        case 2:
          return 'Arriving Soon: Reached at a location near you';
        case 3:
          return 'Shipment delayed: Courier Partner notified to get the shipment delivered to you at the earliest';
        case 4:
          return 'Moving as expected';
        case 5:
          return 'Shipment delayed: Logistics team notified';
        case 7:
          return 'Will be reattempted in next 24-48 hours based on your delivery preference';
        case 8:
          return message;
        case 9:
          return 'Shipment will be delivered the same day once dispatched';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: (expression, shipment) => {
      switch (expression) {
        case 1:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our courier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since they could not get in touch with you. Please provide your preferred date for delivery:`;
        case 2:
        case 9:
        case 14:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our Courier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the ${
            shipment ? 'shipment' : 'order'
          } delivered, 'No' to cancel the ${shipment ? 'shipment' : 'order'}.`;
        case 3:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our courier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since you requested for a reschedule. We will come again and deliver! In case you want to let us know your preferred delivery, enter the information below:`;
        case 4:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our courier partner was unable to make a delivery attempt today. <br /><br /> Please provide your preferred date for delivery:`;
        case 5:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our courier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during ${
            shipment ? 'shipment' : 'order'
          } placement?`;
        case 6:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our courier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our courier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt at your preferred date for delivery. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our courier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our courier partner informed us that you will be collecting the shipment from their location. <br /><br /> If this is not the case, please provide your preferred delivery date. In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our courier partner informed us that you requested for open delivery. Please provide your preferred delivery date. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our Courier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. Please provide your preferred delivery date. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: We are identifying the issue with your shipment. Please provide your preferred delivery date. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    ndrBucketNoDate: (expression, shipment) => {
      switch (expression) {
        case 1:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the ${
            shipment ? 'shipment' : 'order'
          } delivered, 'No' to cancel the ${shipment ? 'shipment' : 'order'}.`;
        case 3:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during ${
            shipment ? 'shipment' : 'order'
          } placement?`;
        case 6:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner was unable to deliver your ${
            shipment ? 'shipment' : 'order'
          } since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `${
            shipment ? 'Shipment' : 'Order'
          } Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'The courier partner informs us that they tried to reach your address but could not find your address. Can you provide us more details about the address?',
      timing: {
        a:
          'Our courier partners attempt 3 times for a delivery. In the case, where the package could not be delivered the first time, we reattempt it again the next day excluding Sundays and public holidays.',
        b:
          'Kindly make sure that someone is available at your delivery address to receive the package.',
        c: 'Do you want to get the delivery attempt on specific days?'
      },
      other: shipment =>
        `Please share any additional details that could help us to deliver the ${
          shipment ? 'shipment' : 'order'
        }.`,
      phone: {
        a:
          'In some cases, courier partners call from landline which might feel as spam to you. We will ask the courier partner to reattempt the delivery.',
        b: shipment =>
          `Do you want the courier partner to call you on the same number as given during ${
            shipment ? 'shipment' : 'order'
          } placement?`
      },
      reject:
        'Please confirm if you want us to make another delivery attempt for your shipment:'
    },
    // Truncated content (3a, 5a, 8a) shown for Nykaa brands when form is not visible
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `Our carrier partner informed us that they were unable to pick up your order as they couldn't reach you. Should we call on same number? <br /><br /> Please click "Yes' to confirm or 'No' to edit your contact number.`;
        case 2:
          return `Our carrier partner informed us that they could not pick up your package due to your return cancellation request. <br /><br /> Please click 'Yes' to confirm cancellation or 'No' to proceed with pickup.`;
        case 3:
          return `Our carrier partner informed us that they were unable to process your pickup request as the product appeared to have been damaged or used. <br /><br /> In case of concerns, please share them in the comments section below and reschedule.`;
        case '3a':
          return `Our carrier partner informed us that they were unable to process your pickup request as the product appeared to have been damaged or used`;
        case 4:
          return `Either product color or brand is different`;
        case 5:
          return `Our carrier partner informed us that they were unable to complete the pickup due to a discrepancy in item count. <br /><br /> Kindly select your preferred pickup date below. Ensure that the items are ready for pickup on the rescheduled date.`;
        case '5a':
          return `Our carrier partner informed us that they were unable to complete the pickup due to a discrepancy in item count`;
        case 6:
          return `Please select an alternate date for order pickup`;
        case 7:
          return `Our carrier partner informed us that they were unable to pick up your package as your address falls out of their service area. <br /><br /> In case of concerns, please share them in the comments section below and reschedule.`;
        case 8:
          return 'Our carrier partner informed us that they attempted to pick up your package multiple times without success.  <br /><br /> Kindly provide a preferred pickup date based on your availability. In case of concerns, please share them in the comments section below.';
        case '8a':
          return 'Our carrier partner informed us that they attempted to pick up your package multiple times without success';
        case 9:
          return 'Our carrier partner could not attempt the Pickup for your item/(s). Please keep the item/(s) handy as the pickup will be attempted on the next business day.  <br /><br /> In case of concerns, please share them in the comments section below.';
        case 10:
          return 'Our carrier partner notified that you refused to accept the Pickup request, if this is not the case then please provide the next preference of your Pickup date.In case you have a concern, feel free to write to us in comment section below';
        case 11:
          return 'Our carrier partner informed us that your package was not ready for pickup. Please provide your next preferred pickup date.   <br /><br /> In case of concerns, please share them in the comments section below.';
        case 13:
          return `Our carrier partner informed us that they couldn't pick up your package as they were unable to locate your address. <br /><br /> Kindly provide your complete address, including any landmarks, below.`;
        case 14:
          return `Our carrier partner informed us that they couldn't process your pickup request as you were unavailable. Please select your preferred pickup date.`;
        case 0:
        default:
          return 'We are currently working to identify the issue with your request. Kindly select your preferred pickup date. <br /><br />  In case of concerns, please share them in the comments section below.';
      }
    },
    nprFdForm: {
      address: `Our carrier partner informed us that they couldn't pick up your package as they were unable to locate your address. Kindly provide your complete address, including any landmarks, below.`,
      timing: {
        a:
          'Our courier partners attempt 3 times for a pickup. In the case, where the package could not be picked up the first time, we reattempt it again the next day excluding Sundays and public holidays.',
        b:
          'Kindly make sure that someone is available at your pickup address to handover the package.',
        c: 'Do you want to get the pickup attempt on specific days?'
      },
      other:
        'Please share any additional details that could help us to pickup the order, also please cross verify your return request in case of any product/ quantity mismatch',
      phone: {
        a:
          'In some cases, courier partners call from landline which might feel as spam to you. We will ask the courier partner to reattempt the pickup.',
        b:
          'Do you want the courier partner to call you on the same number as given during order placement?'
      },
      reject:
        'Please confirm if you want us to cancel the pickup attempt for your shipment:'
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `Total <strong>${creditValue}</strong> credits used for payment of this order will be credited back to your store account against this order`,
      totalAsCredit: creditValue =>
        `Would you like refund for ${
          creditValue > 0 ? 'rest of' : ''
        } the amount paid as store credit?`,
      totalAsCreditNew: 'Please choose your preferred mode of refund payment',
      prepaid: (calculatedAmt, currency) =>
        `Your refund amount of <strong>${currency} ${calculatedAmt}</strong> will be credited back to the source.`,
      cod: (calculatedAmt, brandName) =>
        ({
          rforrabbit:
            'Please enter your bank account details. You will receive cash paid in the bank account provided below.'
        }[brandName] ??
        `Please enter your bank account details. You will receive cash paid(Rs. <strong>${calculatedAmt}</strong>) in the bank account provided below.`),
      refundTotalAsCredit: (totalAmount, brandName) =>
        ({
          TGM:
            'You will receive your refund as a TGM store credit voucher. (Within 24 Hours)',
          Brand: `Refund to Wallet Credits: <strong>${totalAmount}</strong>`,
          rforrabbit: 'Refund to Wallet Credits',
          plumgoodness: `You will be receiving an equivalent valued coupon worth ${totalAmount}, which you can use during your future purchases at Plumgoodness.com.`
          // HUFT: `Refund to ${brandName} Wallet: <strong>${totalAmount}</strong>`
        }[brandName] ??
        `Refund to ${brandName} Credits: <strong>${totalAmount}</strong>`),
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: brandName =>
        brandName === 'TGM' // Can be modified to switch case in future to cater to multiple brands
          ? 'You will receive your refund as a TGM store credit voucher. (Within 24 Hours)'
          : `Your refund amount will be credited back to your ${brandName} Credits`,
      defaultPrepaid: (totalAmount, currency, brandName) =>
        ({
          Jolene: `Your refund amount of INR ${totalAmount} -(minus) the Shipping and Returns charges will be credited back to the source subject to Quality Checks approval on return.`,
          urturms: `Your refund amount of INR ${totalAmount} -(minus) the Shipping and Returns charges will be credited back to the source subject to Quality Checks approval on return.`,
          baccabucci: `Your refund amount of INR ${totalAmount -
            39} will be credited back to the source.`,
          bonkerscorner: `Your refund amount  <strong>${
            currency ? `of ${currency}` : ''
          } ${totalAmount}</strong> will be credited <strong>to your Bonkers Corner wallet</strong>`
        }[brandName] ??
        `Your refund amount  <strong>${currency ? `of ${currency}` : ''} 
        ${totalAmount}</strong> will be credited back to the source`)
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'Shipped',
    readyForDispatch: 'Ready For Dispatch',
    trackOrder: 'Track',
    deliveryFeedback: 'Delivery Feedback',
    raiseConcern: 'Raise Concern',
    expired: 'Expired',
    downloadApp: 'Download the app',
    allRightsReserved: 'All rights reserved',
    emailUs: 'Email us: ',
    callUs: 'Call us: ',
    poweredBy: 'Powered by ',
    login: 'Login',
    username: 'Username',
    psswrd: 'Password',
    returnRequestReceived: 'Return request received',
    returnPlaceDate: 'Return placed date',
    reason: 'Reason',
    anyOtherComment: 'Comment',
    addImage: 'Add Image',
    quantity: 'Quantity',
    back: 'Back',
    edit: 'Edit',
    returnSummary: 'Return Summary',
    returnStatus: 'Return Status',
    pickup: 'Pickup',
    selectReversePickupAddress: 'Select Reverse Pickup Address',
    selectOrAddAddress: 'Select/Add Pickup Address',
    phoneFormat: format =>
      `Please enter a phone number in the format ${format} without the Phone Code`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Request Pickup',
    selfShip: 'Self Ship',
    review: 'Review',
    drop: 'Drop Instruction',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Pickup Date',
    orderStatus: 'Order Status',
    expectedPickupDate: 'Expected Pickup Date',
    estimatedReturnDate: 'Estimated Return Date',
    expectedDropDate: 'Expected Drop Date',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Approved',
    goHome: 'Go Home',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Update Pickup Address',
    fullName: 'Full Name',
    emailId: 'Email ID',
    city: 'City',
    area: 'Area',
    state: 'State',
    country: 'Country',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    bankDetail: 'Bank Details For Refund',
    upiDetails: 'UPI Details',
    brandCredits: (brandName, brandCreditText) =>
      `${brandName}  Credits ${brandCreditText}`,
    backToSource: 'Back to Source',
    bankAccount: 'Bank Account',
    accountHolderName: 'Account Holder Name',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Account Number',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Fetch',
    enterReversePickupAddress: 'Enter Reverse Pickup Address',
    pleaseUploadSupportingImage: (imageUploadNote, imageQuantity) =>
      `Note: Please upload ${imageQuantity} ${imageUploadNote}`,
    processing: 'Processing',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} images required for this request`,
    returns: 'Returns',
    exchange: (exchangeText, returnText) => `${returnText} and ${exchangeText}`,
    enterYourOrderInformation: 'Enter your order information to get started.',
    ourReturnExchangePolicy: (exchangeText, returnText) =>
      `Our ${returnText} & ${exchangeText} Policy`,
    refundPolicy: 'Refund Policy',
    termsConditions: 'Terms & Conditions',
    stepOf: currentStep => `Step ${currentStep} of 3`,
    allPriceShownPerItem: 'All prices shown are at item level',
    qtyPerOrderNote: 'Note: Qty shows original ordered item quantity',
    qty: 'Qty',
    returnedQty: 'Returned quantity',
    placeFreshPickupRequest: 'Pickup cancelled, place fresh pickup request.',
    remove: 'Remove',
    return: 'Return',
    startReturn: 'Start Return',
    whyAreYouReturning: 'Why are you returning the product?', // optional
    whyYouWantReturn: 'Why do you want to return?',
    selfShipMode:
      'As you selected Self Ship Mode. Please pack the items securely to prevent any loss or damage during transit.',
    requestPlacedDate: 'Request Placed Date',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'Shipping Soon',
    dropInstruction:
      'Request you to please drop the shipment at the nearest shipping center of the assigned carrier partner.',
    max200Chars: 'Max. 200 characters',
    select: 'Select',
    charsLeft: chars => `${chars} characters left`
  },
  ar: {
    courier: 'شركة الشحن',
    trackingId: 'رقم تتبع الشحنة',
    orderId: 'رقم الطلب',
    lastUpdate: 'آخر تحديث',
    orderPlacedOn: 'تم الطلب في',
    orderType: 'نوع الأمر',
    riderInfo: 'معلومات رايدر',
    orderPlaced: 'تم الطلب',
    dispatched: 'تم إرسال الشحنة',
    inTransit: 'الشحنة في الطريق',
    failedDelivery: 'فشلت عملية التوصيل',
    ofd: 'جاري التوصيل',
    delivered: 'تم التوصيل',
    pickupRequested: 'تم وضع طلب لاستلام الشحنة',
    updatedOn: 'تحديث في',
    returnInstructions: 'تعليمات إعادة المحاولة',
    returnPlaced: 'تم تقديم طلب الإرجاع',
    pickupCancelled: 'تم الغاء طلب استلام الشحنة',
    ofp: 'في الطريق لاستلام الشحنة',
    pickedup: 'تم استلام الشحنة',
    returned: 'تم الإرجاع',
    reverseReturned: 'تم الإرجاع',
    readyToShip: 'جاهزة للشحن',
    lost: 'فقدت',
    damaged: 'تالفة',
    cancelled: 'تم الغاء الشحنة',
    requestPlaced: 'تم إنشاء الطلب',
    requestAccepted: 'تم قبول الطلب',
    requestAcceptedWithDate: date => `${date} قبول طلب الإرجاع الخاص بك بتاريخ`,
    confirmed: 'تم التأكيد',
    confirm: 'تأكيد',
    requestReturn: 'تسجيل طلب إرجاع',
    pickupFailed: 'لم يتم التسليم بنجاح',
    reachedW: 'وصلت للمخازن',
    requestCancelled: 'تم إلغاء الطلب',
    requestRejected: 'طلب رفض',
    location: 'الموقع',
    time: 'الوقت',
    remark: 'الملاحظات',
    shippingLabel: 'بطاقة شحن',
    shippingLabelInProgress:
      'جارٍ جلب الملصق من شركة الاتصالات، وسيكون متاحًا للتنزيل خلال بضع دقائق',
    shippingLabelReady: 'تم إنشاء ملصق الشحن الخاص بك، ويمكنك الآن تنزيله',
    pleaseTellUs: 'من فضلك أعلمنا بما حدث',
    pressNextBtn: 'اضغط على الزر الموجود بالأسفل بعد إتمام عملية الاختيار',
    agentDidntCall: 'لم يتصل بي مندوب الشحن',
    noAttemptToAddress: 'لم يتم عمل أي محاولة للتوصيل إلى العنوان المرفق',
    wasNotAvailable: 'لم أتواجد عند قدوم مندوب الشحن للتوصيل',
    doNotWantProduct: 'لم اعد أريد المنتج',
    nprAgentDidntCall: 'مندوب الاستلام لم يتصل بي',
    nprNoAttemptToAddress: 'لم تكن هناك أي محاولة للاستلام على العنوان المحدد',
    nprWasNotAvailable: 'لم أكن متاح عند محاولة الاستلام',
    doNotWantReturnProduct: 'لم أعد أريد إعادة المنتج',
    others: 'أخرى',
    yes: 'نعم',
    no: 'لا',
    preferredDate: 'تاريخ التوصيل المفضل',
    nprPreferredDate: 'تاريخ الاستلام المفضل',
    landmark: 'علامة مُميزة',
    address: 'العنوان',
    pincode: 'الرمز البريدي/ رمز تعريف الموقع',
    provideAltNo: 'يرجى إدخال رقم بديل',
    comments: 'التعليقات',
    searchBy: 'ابحث بواسطة',
    checkCurrentStatus: 'تفقد حالة شحنتك الحالية',
    trackYourOrder: 'Track Your Order',
    incorrectSearch:
      'الشحنة التي تحاول تتبعها لم يتم إرسالها بعد أو رقم الشحنة الذي أدخلته غير صحيح',
    viewTracking: 'استعرض تفاصيل تتبع الشحنة',
    on: 'on',
    status: 'حالة الطلب',
    orderDetails: 'تفاصيل الطلب',
    noScan1: '!جاهزة للشحن',
    noScan2: 'تفاصيل تتبع الشحنة ستتوفر قريبًا',
    addressErr: 'لا يمكنك ترك العنوان فارغًا أو أقل من 10 رموز',
    pincodeErr: 'أدخل رقم سري مكون من 6 أرقام',
    pincodeNotServiceable:
      'رمز تعريف الموقع خارج نطاق خدمتنا، يرجى إرسال شحنتك إلينا',
    provideLandmark: 'يرجى إدخال معلم معروف لتيسر وصول مندوب الشحن لموقعك',
    landmarkErr: 'لا يمكنك ترك خانة المعلم المعروف خالية أو أقل من 5 رموز',
    dateErr: 'يرجى إدخال تاريخ صحيح',
    commentErr: 'لا يمكن ترك خانة التعليق خالية',
    addressCharLimit: 'يرجى إدخال 10 رموز على الأقل أو 600 على الأكثر',
    phoneErr: 'يرجى إدخال رقم تليفون مكون من 10 أرقام',
    emailidErr: 'يرجى إدخال بريد إلكتروني صحيح',
    invalidImageErr: 'تنسيق الملف غير مدعوم',
    chooseOption: 'من فضلك قم باختيار أحد الخيارات التالية',
    actionInProgress: 'جاري تنفيذ الأمر',
    feedbackToastr1: '!شكرًا لمشاركتنا رأيك! نتمنى خدمتك مرة أخرى',
    feedbackToastr2:
      'شكرًا لمشاركتنا رأيك! سنعمل على تحسين خدماتنا المقدمة إليك في المرة المقبلة',
    abfrlFeedbackToast: 'شكرًا لك على التعليقات ، وإعادة توجيهك إلى الاستبيان',
    ratingErr: 'لا يمكنك ترك خانة التقييم فارغة',
    apiErr: 'لم نتمكن من معالجة الطلب، يرجى المحاولة مرة أخرى لاحقًا',
    updateContactToastr:
      'شكرًا لقيامك بالتحديث! سنقوم بإرسال الإشعارات على الرقم الجديد',
    noDataFound:
      'الشحنة التي تحاول تتبعها لم يتم إرسالها بعد أو رقم الشحنة الذي أدخلته غير صحيح',
    veryPoor: 'سيء جدًا',
    excellent: 'ممتاز',
    veryPoorAbfrl: 'الأقل احتمالا للتوصية',
    excellentAbfrl: 'من المرجح بشدة أن يوصي',
    veryUnlikely: 'من المستبعد جدا',
    veryLikely: 'من المحتمل جدا',
    submit: 'قدم',
    youFacedAnyIssue: 'هل واجهت أية صعوبات أخرى؟',
    updateContact: 'حدث بيانات الاتصال',
    updateContactTooltip: 'اضغط هنا لتغيير رقم الاتصال الخاص بك',
    myOrders: 'طلباتي',
    myOrdersTooltip: 'انقر هنا لعرض طلباتك',
    takeAction: 'قم بإتخاذ إجراء',
    takeActionTooltip: 'اضغط هنا لعمل شكوى',
    next: 'التالي',
    anyOtherDate: 'هل ترغب في أي موعد آخر؟',
    share: 'مشاركة',
    expectedDelivery: 'تاريخ الوصول المتوقع',
    viewDetail: 'استعرض التفاصيل',
    viewSummary: 'استعرض ملخص الطلبية',
    inputFeedback: 'من فضلك شاركنا رأيك وملاحظاتك',
    submittedConcern: courier => ` لنتمكن من مساعدتك ${courier} سنتواصل مع`,
    feedbackMatters: '!ملاحظاتك مهمة',
    ratingFeedback: 'شكرًا لمشاركة رأيك وملاحظاتك القيّمة معنا',
    youRatedUs: 'لقد قيمتنا',
    youCommented: 'أنت علقت',
    askRatingFeedback: courier => `؟ ${courier} ما هو تقييمك في خدمة من`,
    askRatingFeedbackArvind: 'كيف كانت تجربة التسوق الخاصة بك مع NNNOW؟',
    askRatingFeedbackAbfrl: brandName =>
      `بناءً على تجربة التسليم هذه ، على مقياس من 0 إلى 10 مدى احتمالية أن توصي ${brandName} إلى صديق؟    `,
    askRatingFeedbackTrell:
      'لأصدقائك وعائلتك؟ Trell بناءً على تجربة التسليم الأخيرة ، ما مدى احتمالية أن توصي',
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience: 'كيف كانت تجربة الإرجاع بشكل عام؟',
    askNonPickedExperience:
      'لقد لاحظنا أنه لا يمكن استلام شحنتك. كيف كانت تجربة الإرجاع بشكل عام؟',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    pageNotFound: 'لم يتم العثور على هذه الصفحة',
    ops404: 'لم يتم العثور على هذه الصفحة',
    youFoundIncorrectLink: 'يبدو أنك حصلت على رابط غير صحيح',
    notFoundDescFn: (title, websiteUrl) =>
      `للحصول على رابط تعقب الشحنة الصحيح ولأي معلومات إضافية عن طلبك ${websiteUrl}  على ${title} الرجاء التواصل مع`,
    statusOnDateFn: (status, date) => `${date} في ${status}`,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    contactNumber: 'رقم التواصل',
    updateContactNumber: 'تحديث رقم التواصل',
    updateContactNumberToastr:
      'سيسمح لنا هذا بالتواصل معك وتزويدك بمعلومات هامّة حول حال الطلب والشحن',
    searchByTrackingId: 'AWB / رقم تتبع الشحنة',
    searchByOrderId: 'معرف الطلب / لا',
    langName: 'عربى',
    rateUs: 'How would you rate for:',
    orderPlaceOnDateFn: date => `${date} تم الطلب في`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'أخبرنا ما الخطأ الذي تم';
        case 6:
        case 7:
        case 8:
          return 'كيف يمكن أن نحسن خدمتنا؟';
        default:
          return '!أعطي تقييمًا إيجابيًا';
      }
    },
    edd: (expression, message) => {
      let statusName = '';
      let days = null;
      if (expression === 1) {
        const digit = message.match(/\d+/);
        days = digit[0];
      } else if (expression === 8) {
        const splitted = message.split(' ');
        if (splitted.length === 2) {
          statusName = splitted[0];
          if (statusName === 'Lost') {
            statusName = 'فقدت';
          } else if (statusName === 'Damaged') {
            statusName = 'تالفة';
          }
        }
      }

      switch (expression) {
        case 1:
          return `ستيم شحن المنتج خلال... أيام من مغادرتها المخازن`.replace(
            '...',
            ` ${days} `
          );
        case 2:
          return `سيصلك قريبًا: طلبك في موقع مجاور لك`;
        case 3:
          return '.تم تأخير الشحنة: تم إعلام مندوب الشحن لتوصيل طلبك في أسرع وقت ممكن';
        case 4:
          return 'عملية الشحن تسير بشكل اعتيادي';
        case 5:
          return `تم تأخير عملية الشحن: تم إخطار فريق الأعمال اللوجيستية`;
        case 7:
          return '.ستتم محاولة التوصيل مرة أخرى في خلال 24 إلى 48 ساعة حسب موعد التوصيل المفضل لديك';
        case 8:
          return `${statusName} تم تأخير`;
        case 9:
          return 'سيتم تسليم الشحنة في نفس اليوم بمجرد إرسالها';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `لم يتم توصيل الطلب: لم يستطع مندوب الشحن توصيل الطلب إليك لعدم تمكنه من التواصل معك. يرجى اختيار موعد آخر للتوصيل`;
        case 2:
        case 9:
        case 14:
          return `<p>لم يتم توصيل الطلب: لم يقم مندوب الشحن بتسليمك الطلب نظرًا لرفضك استلامه عند الوصول <br/><br/> اضغط نعم لتوصيل الطلب إليك أو لا لإلغاء الطلب</p>`;
        case 3:
          return '.لم يتم توصيل الطلب: لم يستطع مندوب الشحن توصيل الطلب إليك نظرًا لطلبك تغيير الموعد. سنقوم بالتواصل معك لاحقًا. يمكنك اطلاعنا على الموعد المفضل عندك لاستلام الطلب';
        case 4:
          return `.لم يتم توصيل الطلب: لم يستطع مندوب الشحن توصيل طلبك اليوم <br /><br /> يرجى اختيار موعدك المفضل لاستلام الطلب`;
        case 5:
          return `لم يتم توصيل الطلب: لم يستطع مندوب الشحن توصيل الطلب إليك لعدم تمكنه من التواصل معك. يرجى إدخال رقم تليفون بديل للتواصل معك عليه. <br /><br /> هل ترغب في أن يتواصل معك مندوب الشحن على نفس الرقم الذي قمت بإدخاله عند عمل طلبك؟`;
        case 6:
          return '.لم يتم توصيل الطلب: لم يستطع مندوب الشحن توصيل طلبك نظرًا لعدم تمكنه من الوصول لموقعك. يرجى إدخال موقعك بشكل صحيح بالأسفل مع بعض المعالم المميزة للمكان';
        case 7:
          return `لم يتم توصيل الطلب: لم يستطع مندوب الشحن تسليمك الطلب نظرًا لعدم توافر المبلغ المستحق للطلب عند الاستلام. <br /><br /> لا تقلق سيتم محاولط توصيل طلبك مرة أخرى لاحقًا. في الموعد المفضل لديك. في حالة مواجهتك لأمر مختلف، يرجى اطلاعنا عليه في الأسفل`;
        case 8:
          return `لم يتم توصيل الطلب: لم يستطع مندوب الشحن توصيل طلبك نظرًا لعدم تمكنه من الوصول لموقعك. يرجى إدخال موقعك بشكل صحيح بالأسفل مع بعض المعالم المميزة للمكان`;
        case 10:
          return `لم يتم توصيل الطلب: أخبرنا مندوب الشحن أنك ستقوم باستلام الطلب بنفسك من موقع شركة الشحن <br /><br /> يرجى اختيار موعدك المفضل لاستلام الطلب في حالة وجود أمور مغايرة. في حالة مواجهتك لأمر مختلف، يرجى اطلاعنا عليه في الأسفل`;
        case 11:
          return `هل واجهتك أي صعوبة عند عملية استلام طلبك؟ أخبرنا بتعليقك في الأسفل`;
        case 12:
          return `لم يتم توصيل الطلب: أخبرنا مندوب الشحن أنك قمت بطلب فتح الطلبية عند الاستلام. يرجى اختيار تاريخ الاستلام المفضل لديك. اكتب لنا في خانة التعليقات بالأسفل في حالة وجود أي تعليقات لديك وسنحرص على مشاركتها مع الفريق المختص`;
        case 13:
          return '.لم يتم توصيل الطلب: للأسف تم شحن طلبك لعنوان خطأ. نقوم حاليًا بأقصى جهدنا لتوصيل طلبك سريعًا. يرجى اختيار تاريخ الاستلام المفضل عندك. اكتب لنا في خانة التعليقات بالأسفل في حالة وجود أي تعليقات لديك';
        case 0:
        default:
          return '.لم يتم توصيل الطلب: نقوم حاليًا بتفقد أمر شحنتك. يرجى اختيار تاريخ الاستلام المفضل عندك. اكتب لنا في خانة التعليقات بالأسفل في حالة وجود أي تعليقات لديك';
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'قام مندوب الشحن بمحاولة الوصول لعنوانك، لكن لم يتمكن من ذلك. يرجى  توفير المزيد من المعلومات حول عنوانك لنتمكن من توصيل طلبك',
      timing: {
        a:
          'حاول مندوب الشحن توصيل طلبك لمدة 3 مرات مختلفة. في حالة عدم التمكن من الاستلام في المرة الأولى، يقوم مندوب الشحن بالمحاولة لاحقًا خلال اليوم التالي وخلال أيام العمل فيما عدا أيام الأحد والعطلات الرسمية',
        b:
          'يرجى التأكد من تواجدك  في عنوان التوصيل في الوقت المحدد للاستلام حتى نستطيع توصيل الطلب إليك بنجاح',
        c: 'هل ترغب في إعادة محاولة توصيل طلبك في أيام محددة؟'
      },
      other: () =>
        'يرجى تزويدنا  بأي معلومات إضافية قد تساعدنا في توصيل الطلب إليك بنجاح',
      phone: {
        a:
          'في بعض الحالات، يقوم مندوبون الشحن بالتواصل مع العملاء بالاتصال من رقم أرضي، فربما فوته. سنقوم بالتواصل معهم لكي يعاودوا المحاولة مرة أخرى',
        b: () =>
          'هل ترغب في أن يتواصل معك مندوب الشحن على نفس الرقم الذي قمت بإدخاله عند عمل طلبك؟'
      },
      reject: 'يرجى التأكيد إذا كنت تريد منا إجراء محاولة تسليم أخرى لشحنتك'
    },
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `فشل الاستلام: لم يتمكن مندوب الشحن من استلام الطلب لعدم قدرته على التواصل معك. يرجى تقديم رقم للاتصال أو العنوان`;
        case 2:
          return `فشل الاستلام: أبلغنا مندوب الشحن بعدم إمكانية استلام شحنتك بسبب طلبك إلغاء الاستلام.<br /><br /> انقر على "نعم" للاستلام، أو "لا" لإلغاء الاستلام`;
        case 3:
        case '3a':
          return `المنتج تالف أو مستخدم`;
        case 4:
          return `اختلاف لون المنتج أو العلامة التجارية`;
        case 5:
        case '5a':
          return `العدد الإجمالي للعناصر يختلف عن المطلوب`;
        case 6:
          return `فشل الاستلام: يرجى تقديم تاريخ الاستلام المفضل`;
        case 7:
          return `فشل الاستلام: لم تتمكن شركة الشحن من الاستلام لأنك عنوانك خارج نطاق عمل شركة الشحن`;
        case 8:
        case '8a':
          return 'أخطرنا مندوب الشحن بمحاولاته العديدة للاستلام دون فائدة، يرجى تقديم تاريخ الاستلام المفضل بناء على تواجدك، إذا كانت لديك أي تساؤلات، يمكنك إخبارنا في قسم التعليمات بالأسفل';
        case 9:
          return 'لم يتمكن مندوب الشحن من استلام شحنتك، يرجى الاحتفاظ بالشحنة في متناول يدك لأن الاستلام سيتم خلال أيام العمل القليلة المقبلة، إذا أردت مشاركة أي تقييم أو ملاحظة، يمكنك كتابة ذلك في قسم التعليقات';
        case 10:
          return 'أخطرنا مندوب الشحن برفضك الموافقة على طلب الاستلام، إذا كان ذلك غير صحيح يرجى إبلاغنا بتاريخ الاستلام التالي المفضل. إذا كانت لديك أي تساؤلات، يمكنك إخبارنا في قسم التعليمات بالأسفل';
        case 11:
          return 'أخطرنا مندوب الشحن بأن المنتج لم يكن جاهزًا للاستلام، إذا كان ذلك غير صحيح يرجى إبلاغنا بتاريخ الاستلام التالي المفضل. إذا كانت لديك أي تساؤلات، يمكنك إخبارنا في قسم التعليمات بالأسفل';
        case 13:
          return `أبلغنا شريكنا الناقل أنهم لا يستطيعون استلام الطرد الخاص بك لأنهم لم يتمكنوا من تحديد عنوانك. <br /><br /> يرجى تقديم عنوانك الكامل، بما في ذلك أي معالم، أدناه.`;
        case 14:
          return `أبلغنا شريكنا الناقل أنه لا يمكنه معالجة طلب الالتقاط الخاص بك لأنك لم تكن متاحًا. يرجى تحديد تاريخ الاستلام المفضل لديك.`;
        case 0:
        default:
          return `فشل الاستلام: نواجه مشكلة مع طلبك. يرجى تقديم تاريخ الاستلام المفضل. إذا كانت لديك أي تساؤلات، يمكنك إخبارنا في قسم التعليمات بالأسفل`;
      }
    },
    nprFdForm: {
      address:
        'أبلغنا مندوب الشحن بمحاولة الوصول إلى عنوانك دون العثور على العنوان. هل يمكنك تقديم مزيد من التفاصيل عن العنوان؟',
      timing: {
        a:
          'حاول مندوب الشحن استلام الشحنة 3 مرات. في حالة عدم إمكانية استلام الشحنة في المرة الأولى، سوف نعيد المحاولة في اليوم التالي باستثناء أيام الأحد والعطلات الرسمية',
        b: 'يرجى التأكد من تواجد شخص ما في عنوان الاستلام لتسليم الشحنة',
        c: 'هل تريد الاستلام في أيام معينة؟'
      },
      other:
        'يرجى مشاركة تفاصيل إضافية يمكنها مساعدتنا لاستلام الطلب، كما يرجى التحقق من طلب الاسترجاع في حالة عدم تطابق أي منتج / كمية',
      phone: {
        a:
          'في بعض الحالات، تتصل شركة الشحن من خط أرضي قد يظهر بمثابة اتصال مزعج. سوف نطلب من مندوب الشحن تكرار محاولة الاستلام',
        b:
          'هل تريد أن يتصل بك مندوب الشحن على نفس الرقم الذي قدمته أثناء تقديم الطلب؟'
      },
      reject: 'يرجى التأكيد إذا كنت تريد إلغاء استلام شحنتك'
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `سوف يتم إعادة إجمالي {0} نقطة رصيد مستخدمة للدفع لهذا الطلب إلى حسابك في المتجر`.replace(
          '{0}',
          creditValue
        ),
      totalAsCredit: creditValue =>
        'هل تود استعادة المبلغ المدفوع {0} على هيئة نقاط رصيد في المتجر؟'.replace(
          '{0}',
          creditValue > 0 ? 'البقية من' : ''
        ),
      totalAsCreditNew:
        'برجاء اختيار الطريقة التي تناسبك لاسترداد المبالغ المستحقة',
      prepaid: calculatedAmt =>
        `وفقًا لطريقة الدفع الإلكترونية الأصلية Rs. ${calculatedAmt} سوف تسترد`,
      cod: calculatedAmt =>
        `المدفوعة في الحساب البنكي (Rs. ${calculatedAmt}) يرجى إدخال بيانات حسابك البنكي. سوف تستلم`,
      refundTotalAsCredit: totalAmount =>
        `${totalAmount}: استرداد نقاط رصيد في المتجر`,
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: () =>
        'ستتم إعادة المبلغ المسترد إلى أرصدة علامتك التجارية',
      defaultPrepaid: totalAmount => `ستتم إعادة مبلغ ${totalAmount} إلى المصدر`
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'شحنها',
    readyForDispatch: 'جاهز للإرسال',
    trackOrder: 'تتبع الطلب',
    deliveryFeedback: 'تسليم ردود الفعل',
    raiseConcern: 'يثير الاهتمام',
    expired: 'منتهية الصلاحية',
    downloadApp: 'قم بتنزيل التطبيق',
    allRightsReserved: 'كل الحقوق محفوظة',
    emailUs: 'ارسل لنا عبر البريد الإلكتروني: ',
    callUs: 'اتصل بنا: ',
    poweredBy: 'مشغل بواسطة ',
    login: 'تسجيل الدخول',
    username: 'اسم المستخدم',
    psswrd: 'كلمه السر',
    returnRequestReceived: 'تم استلام طلب الإرجاع',
    returnPlaceDate: 'تاريخ العودة',
    reason: 'السبب',
    anyOtherComment: 'أي تعليق آخر',
    addImage: 'إضافة صورة',
    quantity: 'الكمية',
    back: 'العودة',
    edit: 'تعديل',
    returnSummary: 'مُلخص طلب الإرجاع',
    returnStatus: 'حالة طلب الإرجاع',
    pickup: 'الاستلام',
    selectReversePickupAddress: 'حدد عنوان الالتقاط العكسي',
    selectOrAddAddress: 'حدد / اختر عنوان الاستلام',
    phoneFormat: format =>
      `بدون رمز الهاتف ${format} الرجاء إدخال رقم هاتف صالح مكون من 9 أرقام كالتالي`,
    format: 'مثال',
    addNewAddress: 'أضف عنوان جديد',
    proceed: 'المتابعة',
    requestPickup: 'طلب التقاط',
    selfShip: 'السفينة الذاتية',
    review: 'مراجعة الطلب',
    pickupAddress: 'عنوان الاستلام',
    pickupDate: 'اختر تاريخا',
    orderStatus: 'حالة الطلب',
    expectedPickupDate: 'تاريخ الاستلام المتوقع',
    reachedWarehouse: 'وصلت إلى المستودع',
    approved: 'وافق',
    goHome: 'اذهب للمنزل',
    updateReversePickupAddress: 'تحديث عنوان الالتقاط العكسي',
    updatePickupAddress: 'تحديث عنوان الاستلام',
    fullName: 'الاسم الكامل',
    emailId: 'البريد الإلكتروني',
    city: 'مدينة',
    area: 'منطقة',
    state: 'حالة',
    country: 'بلد',
    phoneNumber: 'رقم الهاتف',
    phoneCode: 'رمز الهاتف',
    bankDetail: 'تفاصيل البنك',
    upiDetail: 'تفاصيل واجهة الدفع الموحدة (UPI).',
    brandCredits: () => 'ائتمانات العلامة التجارية',
    backToSource: 'استرداد عن طريق وسيلة الدفع الأصلية',
    bankAccount: 'حساب البنك',
    accountHolderName: 'اسم صاحب الحساب',
    upiName: 'اسم واجهة الدفعات الموحدة (UPI).',
    upiId: 'UPI ID',
    accountNumber: 'رقم حساب',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'الشفرة',
    bankName: 'اسم البنك',
    branchName: 'اسم الفرع',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'جلب',
    enterReversePickupAddress: 'أدخل عنوان الاسترجاع',
    pleaseUploadSupportingImage: imageQuantity =>
      `يرجى تحميل ${imageQuantity} صورة داعمة`,
    processing: 'جاري المعالجة',
    minimumImagesRequired: imageQuantity =>
      `تحتاج إلى ${imageQuantity} صورة بحد أدنى لهذا الطلب`,
    returns: 'الاسترجاع',
    exchange: () => 'تبادل',
    enterYourOrderInformation: 'أدخل معلومات الطلب للبدء',
    ourReturnExchangePolicy: () => 'سياسة الاسترجاع والاستبدال',
    refundPolicy: 'سياسة الاسترجاع',
    termsConditions: 'الشروط والأحكام',
    stepOf: currentStep => `3 من ${currentStep} الخطوة`,
    allPriceShownPerItem: 'جميع الأسعار المعروضة هي لكل سلعة مُنفردة',
    qtyPerOrderNote: 'ملحوظة: الكمية المعروضة هي الكمية الأصلية المطلوبة',
    returnedQty: 'الكمية المرتجعة',
    qty: 'كمية',
    placeFreshPickupRequest: 'تم إلغاء الاستلام، قدّم طلب استلام جديد',
    remove: 'حذف',
    return: 'إرجاع',
    startReturn: 'ابدأ العودة',
    whyAreYouReturning: 'لماذا تعيد المنتج؟', // optional
    whyYouWantReturn: 'لماذا تود إرجاع هذه السلع؟',
    selfShipMode:
      'وفقًا لاختيار وضعية الشحن الذاتي، يرجى تغليف العناصر بأمان لمنع أي خسائر أو تلف أثناء النقل',
    requestPlacedDate: 'تاريخ تقديم الطلب',
    logMeIn: 'تسجيل الدخول',
    selectDate: 'حدد تاريخ',
    shippingSoon: 'يشحن قريبا',
    max200Chars: 'مُتبقي 200 حرف',
    select: 'اختر',
    charsLeft: chars => `${chars} شخصيات تركت`
  },
  vi: {
    courier: 'Bưu tá',
    trackingId: 'ID theo dõi',
    orderId: 'ID đặt hàng',
    lastUpdate: 'Cập nhật cuối cùng',
    orderPlacedOn: 'Đơn hàng được đặt vào',
    orderType: 'Kiểu đơn hàng',
    riderInfo: 'Thông tin cậu bé giao hàng',
    orderPlaced: 'Đơn hàng được đặt',
    dispatched: 'Đã gửi đi',
    inTransit: 'Trên đường vận chuyển',
    failedDelivery: 'Giao hàng thất bại',
    ofd: 'Đưa cho đơn vị giao hàng',
    delivered: 'Đã giao hàng',
    pickupRequested: 'Yêu cầu bốc hàng',
    updatedOn: 'Đã cập nhật vào',
    returnInstructions: 'Hướng dẫn thử lại',
    returnPlaced: 'Trả hàng đã đặt',
    pickupCancelled: 'Hủy bốc hàng',
    ofp: 'Đưa cho bốc hàng',
    pickedup: 'Đã bốc hàng',
    returned: 'Đã trả lại',
    reverseReturned: 'Đã trả lại',
    readyToShip: 'Sẵn sàng vận chuyển',
    lost: 'Mất',
    damaged: 'Hư hỏng',
    cancelled: 'Đã hủy',
    requestPlaced: 'Yêu cầu được đặt',
    requestAccepted: 'Request Accepted',
    requestAcceptedWithDate: date => `Your return has been accepted on ${date}`,
    confirmed: 'Đã xác nhận',
    confirm: 'Xác nhận',
    requestReturn: 'Yêu cầu trả lại',
    pickupFailed: 'Bốc hàng thất bại',
    reachedW: 'Đã đến kho hàng',
    requestCancelled: 'Yêu cầu bị hủy',
    requestRejected: 'Request Rejected',
    location: 'Vị trí',
    time: 'Thời gian',
    remark: 'Chú ý',
    shippingLabel: 'Shipping Label',
    shippingLabelInProgress:
      'Fetching the label from the carrier, it will be available for download in a few minutes',
    shippingLabelReady:
      'Your shipping label has been generated, now you can download it',
    pleaseTellUs: 'Hãy cho chúng tôi biết những gì đã xảy ra?',
    pressNextBtn: 'Nhấn nút Kế tiếp ở dưới sau khi lựa chọn.',
    agentDidntCall: 'Nhân viên giao hàng không gọi cho tôi',
    noAttemptToAddress: 'Không có đơn giao nào đến địa chỉ giao hàng',
    wasNotAvailable: 'Tôi đã không có ở đó khi có đơn giao hàng.',
    nprAgentDidntCall: 'Nhân viên bốc hàng đã không gọi cho tôi',
    nprNoAttemptToAddress: 'Không có ai tại địa chỉ bốc hàng',
    nprWasNotAvailable: 'Tôi đã không có ở đó khi bốc hàng.',
    doNotWantProduct: 'Không muốn mua sản phẩm nữa',
    doNotWantReturnProduct: 'Không muốn trả lại sản phẩm nữa.',
    others: 'Khác',
    yes: 'Có',
    no: 'Không',
    preferredDate: 'Ngày giao hàng mong muốn',
    nprPreferredDate: 'Ngày bốc hàng ưu tiên',
    landmark: 'Điểm mốc',
    address: 'Địa chỉ',
    pincode: 'Mã PIN',
    provideAltNo: 'Vui lòng cung cấp số thay thế khác',
    comments: 'Bình luận',
    searchBy: 'Tìm kiếm bằng',
    checkCurrentStatus: 'Kiểm tra tình trạng giao hàng hiện tại của bạn.',
    trackYourOrder: 'Track Your Order',
    incorrectSearch:
      'Đơn hàng/giao hàng mà bạn đang cố gắng theo dõi hiện chưa được vận chuyển hoặc số đơn hàng được nhập không đúng.',
    viewTracking: 'Xem Theo dõi',
    on: 'on',
    status: 'Trạng thái',
    orderDetails: 'Chi tiết đơn hàng',
    noScan1: 'Sẵn sàng vận chuyển!',
    noScan2: 'Chi tiết theo dõi sẽ có trong thời gian ngắn.',
    addressErr: 'Địa chỉ không thể để trống hoặc ít hơn 10 ký tự.',
    pincodeErr: 'Vui lòng nhập 6 chữ số Mã PIN.',
    pincodeNotServiceable:
      'Mã PIN không dùng được, xin vui lòng chuyển đơn hàng cho chúng tôi.',
    provideLandmark:
      'Vui lòng cung cấp điểm mốc cho đối tác bưu tá để xác định vị trí địa chỉ của bạn.',
    landmarkErr: 'Điểm mốc không thể để trống hoặc ít hơn 5 ký tự.',
    dateErr: 'Vui lòng nhập một ngày hợp lệ.',
    commentErr: 'Bình luận không thể để trống.',
    addressCharLimit: 'Vui lòng nhập ít nhất 10 ký tự và không quá 600.',
    phoneErr: 'Vui lòng nhập số điện thoại hợp lệ 10 chữ số.',
    emailidErr: 'Vui lòng nhập email hợp lệ.',
    invalidImageErr: 'Định dạng tệp không được hỗ trợ',
    chooseOption: 'Vui lòng chọn bất kỳ tùy chọn nào.',
    actionInProgress: 'Tác vụ đang được tiến hành ..',
    feedbackToastr1:
      'Cảm ơn phản hồi của bạn! Mong được phục vụ bạn một lần nữa.',
    abfrlFeedbackToast: 'Cảm ơn bạn đã phản hồi, chuyển hướng bạn đến khảo sát',
    feedbackToastr2:
      'Cảm ơn vì bạn đã phản hồi! Chúng tôi sẽ cố gắng cung cấp cho bạn những trãi nghiệm tốt hơn thời gian tới!',
    ratingErr: 'Đánh giá không thể để trống',
    apiErr: 'Chúng tôi không thể xử lý yêu cầu, vui lòng thử lại sau',
    updateContactToastr:
      'Cảm ơn đã cập nhật !, chúng tôi sẽ bắt đầu gửi thông báo về số điện thoại được cập nhật.',
    noDataFound:
      'Đơn hàng/giao hàng mà bạn đang cố gắng theo dõi hiện chưa được vận chuyển hoặc số đơn hàng được nhập không đúng.',
    veryPoor: 'Rất tệ',
    excellent: 'Xuất sắc',
    veryPoorAbfrl: 'ít có khả năng giới thiệu',
    excellentAbfrl: 'rất có khả năng giới thiệu',
    veryUnlikely: 'Very Unlikely',
    veryLikely: 'Very Likely',
    submit: 'Gửi đi',
    youFacedAnyIssue: 'Bạn có gặp phải bất kỳ vấn đề nào khác không?',
    updateContact: 'Cập nhật Liên lạc',
    updateContactTooltip: 'Nhấn vào đây để thay đổi số liên lạc',
    myOrders: 'Đơn đặt hàng của tôi',
    myOrdersTooltip: 'Bấm vào đây để xem đơn đặt hàng của bạn',
    takeAction: 'Bắt đầu tác vụ',
    takeActionTooltip: 'Nhấn vào đây để đưa ra vấn đề',
    next: 'Kế tiếp',
    anyOtherDate: 'Bất kỳ ngày nào khác?',
    share: 'Chia sẻ',
    expectedDelivery: 'Giao hàng dự kiến',
    viewDetail: 'Xem chi tiết',
    viewSummary: 'Xem tóm tắt',
    inputFeedback: 'Xin hãy chia sẻ thông tin phản hồi của bạn',
    feedbackMatters: 'Phản hồi của bạn quan trọng!',
    ratingFeedback: 'Cám ơn vì những phản hồi có giá trị của bạn!',
    youRatedUs: 'Bạn đánh giá chúng tôi',
    youCommented: 'Bạn đã nhận xét',
    pageNotFound: 'Không tìm thấy trang',
    ops404: 'Rất tiếc, đó là lỗi 404.',
    youFoundIncorrectLink:
      'Bạn dường như đã tìm thấy một liên kết không chính xác.',
    contactNumber: 'Số liên lạc',
    updateContactNumber: 'Cập nhật Số liên lạc',
    updateContactNumberToastr:
      'Điều này cho phép chúng tôi liên lạc với bạn và gửi thông tin cập nhật quan trọng về giao hàng.',
    searchByTrackingId: 'ID/AWB theo dõi',
    searchByOrderId: 'ID đặt hàng / Không',
    langName: 'Tiếng Anh',
    rateUs: 'How would you rate for:',
    orderPlaceOnDateFn: date => `Đơn hàng được đặt vào ${date}`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    submittedConcern: courier =>
      `Chúng tôi sẽ theo dõi với ${courier} để xử lý yêu cầu của bạn.`,
    askRatingFeedback: courier =>
      `Trãi nghiệm về giao hàng của bạn với ${courier} như thế nào?`,
    askRatingFeedbackArvind:
      'Trải nghiệm mua sắm của bạn với NNNOW như thế nào?',
    askRatingFeedbackAbfrl: brandName =>
      `Dựa trên trải nghiệm phân phối này, trên thang điểm 0-10, khả năng bạn đề xuất ${brandName} Tới một người bạn?    `,
    askRatingFeedbackTrell:
      'Dựa trên kinh nghiệm giao hàng gần đây của bạn, bạn có thể giới thiệu Trell cho bạn bè và gia đình của mình như thế nào?',
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience: 'Trải nghiệm trả lại tổng thể của bạn như thế nào?',
    askNonPickedExperience:
      'Chúng tôi nhận thấy lô hàng của bạn không thể nhận được. Trải nghiệm trả lại tổng thể của bạn như thế nào?',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (title, websiteUrl) =>
      `Hãy liên lạc ${title} tại ${websiteUrl} đề tìm liên kết theo dõi chính xác và cung cấp thông tin về đơn đặt hàng.`,
    statusOnDateFn: (status, date) => `${status} vào ${date}`,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'Hãy cho chúng tôi biết những gì đã xảy ra!';
        case 6:
        case 7:
        case 8:
          return 'Chúng cần cải thiện như thế nào?';
        default:
          return 'Đưa ra lời khen!';
      }
    },
    edd: (expression, message) => {
      switch (expression) {
        case 1:
          return message;
        case 2:
          return 'Sẽ xuất hiện sớm: Đến địa điểm gần bạn';
        case 3:
          return 'Giao hàng bị trì hoãn: Đối tác bưu tá được thông báo để hàng được giao cho bạn trong thời gian sớm';
        case 4:
          return 'Vận chuyển như mong đợi';
        case 5:
          return 'Giao hàng bị trì hoãn: Đội hậu cần được thông báo';
        case 7:
          return 'Sẽ được thử lại trong 24-48 giờ tiếp theo dựa trên ưu tiên giao hàng của bạn';
        case 8:
          return message;
        case 9:
          return 'Lô hàng sẽ được giao trong ngày sau khi được gửi đi';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `Đơn hàng chưa được gửi: đối tác bưu tá của chúng tôi đã không thể giao đơn hàng của bạn vì họ không thể liên lạc với bạn. Vui lòng cung cấp ngày ưu tiên của bạn để giao hàng:`;
        case 2:
        case 9:
        case 14:
          return `Đơn hàng chưa được gửi: đối tác bưu tá của chúng tôi đã thông báo với chúng tôi rằng gói hàng của bạn không giao được theo yêu cầu Từ chối giao hàng của bạn. <br /><br /> Nhấp vào 'Có' để được giao hàng, 'Không' để hủy bỏ đơn đặt hàng.`;
        case 3:
          return `Đơn hàng chưa được gửi: đối tác bưu ta của chúng tôi đã không thể giao được đơn hàng cho bạn vì bạn yêu cầu lại thời điểm giao hàng. Chúng tôi sẽ quay lại và giao hàng! Trong trường hợp bạn muốn cho chúng tôi biết ưu tiên giao hàng của bạn, hãy nhập thông tin vào bên dưới:`;
        case 4:
          return `Đơn hàng chưa được gửi: đối tác bưu tá của chúng tôi là không thể giao được hàng ngày hôm nay. <br /><br /> Vui lòng cung cấp ngày ưu tiên giao hàng của bạn:`;
        case 5:
          return `Đơn hàng chưa được gửi: đối tác bưu tá của chúng tôi đã không thể giao đơn hàng của bạn vì chúng tôi không thể liên lạc với bạn. Vui lòng cung cấp số liên lạc thay thế của bạn để đối tác bưu tá của chúng tôi có thể tiếp cận được bạn. <br /><br /> Bạn có muốn đối tác bưu tá của chúng tôi tiếp tục gọi cho bạn với số cũ trong khi giao hàng không?`;
        case 6:
          return `Đơn hàng chưa được gửi: đối tác bưu tá của chúng tôi đã không thể giao được đơn hàng cho bạn vì họ không thể xác định vị trí địa chỉ của bạn. Vui lòng cung cấp địa chỉ đầy đủ của bạn kèm điểm mốc dưới đây:`;
        case 7:
          return `Đơn hàng chưa được gửi: đối tác bưu tá của chúng tôi đã không thể giao được đơn hàng vì việc thanh toán chưa sẵn sàng tại thời điểm giao hàng. <br /><br /> Đừng lo lắng, chúng tôi sẽ yêu cầu họ giao lại vào ngày ưu tiên khác của bạn. Trong trường hợp bạn phải đối mặt với một vấn đề khác, xin vui lòng cung cấp thông tin phản hồi của bạn dưới đây.`;
        case 8:
          return `Đơn hàng chưa được gửi: đối tác bưu tá của chúng tôi đã không thể giao đơn hàng của bạn vì họ không thể xác định vị trí địa chỉ. Vui lòng cung cấp địa chỉ đầy đủ của bạn kèm điểm mốc bên dưới.`;
        case 10:
          return `Đơn hàng chưa được gửi: đối tác bưu tá của chúng tôi đã thông báo với chúng tôi rằng bạn sẽ lấy đơn hàng từ vị trí của họ. <br /><br /> Nếu đây không phải là trường hợp như vậy, vui lòng cung cấp ngày giao hàng ưu tiên của bạn. Trong trường hợp bạn có thắc mắc, xin vui lòng cho chúng tôi biết trong phần bình luận bên dưới:`;
        case 11:
          return `Bạn có gặp phải bất kỳ vấn đề gì trong quá trình giao hàng? Vui lòng cung cấp thông tin phản hồi của bạn dưới đây:`;
        case 12:
          return `Đơn hàng chưa được gửi: đối tác bưu tá của chúng tôi đã thông báo với chúng tôi rằng bạn yêu cầu giao hàng mở. Vui lòng cung cấp ngày giao hàng ưu tiên của bạn. Trong trường hợp bạn có thắc mắc, vui lòng viết cho chúng tôi trong phần bình luận dưới đây và chúng tôi chuyển phản hồi của bạn đến đội ngũ của chúng tôi.`;
        case 13:
          return `Đơn hàng chưa được gửi: Đối tác bưu tá của chúng tôi không may đã chuyển hàng của bạn đến một địa điểm khác. Chúng tôi đang làm tất cả mọi thứ để đảm bảo giao hàng nhanh nhất đến bạn. Vui lòng cung cấp ngày giao hàng ưu tiên của bạn. Nếu bạn muốn chia sẻ bất kỳ thông tin phản hồi não, xin vui lòng viết cho chúng tôi trong phần bình luận dưới đây:`;
        case 0:
        default:
          return `Đơn hàng chưa được gửi: Chúng tôi đang xác định vấn đề với đơn hàng của bạn. Vui lòng cung cấp ngày giao hàng ưu tiên của bạn. Trong trường hợp bạn có thắc mắc, vui lòng viết cho chúng tôi trong phần bình luận dưới đây:`;
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'Đối tác bưu tá thông báo cho chúng tôi rằng họ đã cố gắng tìm đến địa chỉ của bạn, nhưng không thể tìm thấy được địa chỉ của bạn. Bạn vui lòng có thể cung cấp cho chúng tôi biết thêm chi tiết về địa chỉ không?',
      timing: {
        a:
          'Đối tác bưu tá của chúng tôi sẽ cố gắng giao 3 lần cho một đơn hàng. Trong trường hợp kiện hàng không thể giao được vào lần đầu tiên, chúng tôi sẽ thực hiện lại nó một lần nữa vào ngày hôm sau trừ chủ nhật và ngày lễ.',
        b:
          'Vui lòng chắc chắn rằng có người nhận hàng tại địa chỉ giao hàng của bạn.',
        c: 'Bạn có muốn được giao hàng vào những ngày cụ thể không?'
      },
      other: () =>
        'Hãy chia sẻ bất kỳ chi tiết bổ sung mà có thể giúp chúng tôi giao được đơn hàng.',
      phone: {
        a:
          'Trong một số trường hợp, các đối tác bưu tá gọi từ điện thoại cố định mà có thể cảm thấy như là lừa đảo. Chúng tôi sẽ yêu cầu đối tác bưu tá thực hiện lại việc giao hàng.',
        b: () =>
          'Bạn có muốn đối tác bưu tá gọi cho bạn theo cùng với số đã đặt đơn hàng?'
      },
      reject:
        'Vui lòng xác nhận nếu bạn muốn chúng tôi thực hiện một đợt giao hàng nữa cho bạn:'
    },
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `Bốc hàng thất bại: đối tác bưu tá của chúng tôi đã không thể thực hiện bốc hàng vì họ không thể liên lạc với bạn. Vui lòng cung cấp số điện thoại hoặc địa chỉ của bạn`;
        case 2:
          return `Bốc hàng thất bại: đối tác bưu tá của chúng tôi thông báo với chúng tôi rằng gói hàng của bạn không thể bốc lên theo yêu cầu hủy bỏ bốc hàng của bạn. <br /><br /> Nhấp vào 'Có' để bốc hàng, 'Không' để hủy bỏ bốc hàng.`;
        case 3:
        case '3a':
          return `Sản phẩm bị hư hại hoặc đã được sử dụng`;
        case 4:
          return `Cả màu sản phẩm và thương hiệu đều khác`;
        case 5:
        case '5a':
          return `Tổng số mặt hàng là khác so với yêu cầu`;
        case 6:
          return `Bốc hàng thất bại: Vui lòng cung cấp ngày bốc hàng ưu tiên của bạn.`;
        case 7:
          return `Bốc hàng thất bại: Công ty chuyển phát của chúng tôi đã không thể bốc hàng vì địa chỉ của bạn đã ngưng hoạt động đối với bưu tá của chúng tôi.`;
        case 8:
        case '8a':
          return 'Bưu tá của chúng tôi thông báo rằng đã cố gắng thực hiện bốc hàng nhiều lần nhưng không được vì thế vui lòng cung cấp một ngày thuận lợi nhất để bốc hàng khi bạn có mặt, trong trường hợp bạn có thắc mắc, vui lòng viết cho chúng tôi trong phần bình luận phía dưới';
        case 9:
          return 'Bưu tá không thể bốc hàng cho các mặt hàng, vui lòng giữ mặt hàng vì việc bốc hàng sẽ được thực hiện vào ngày làm việc tiếp theo, nếu bạn muốn chia sẻ bất kỳ thông tin phản hồi nào, xin vui lòng viết nó trong phần bình luận.';
        case 10:
          return 'Đối tác bưu tá của chúng tôi thông báo rằng bạn đã từ chối chấp nhận yêu cầu bốc hàng, nếu đây không phải là trường hợp như vậy, hãy cung cấp các ưu tiên tiếp theo về ngày bốc hàng. Trường hợp bạn có thắc mắc, hãy viết cho chúng tôi trong phần bình luận dưới đây';
        case 11:
          return 'Đối tác bưu tá của chúng tôi thông báo rằng các sản phẩm chưa sẵn sàng cho việc bốc hàng, nếu đây không phải là trường hợp nhưu vậy, vui lòng cung cấp các ưu tiên tiếp theo của ngày bốc hàng. Trường hợp bạn có thắc mắc, vui lòng viết cho chúng tôi trong phần bình luận dưới đây';
        case 13:
          return `Đối tác chuyển phát nhanh của chúng tôi đã thông báo với chúng tôi rằng họ không thể nhận gói hàng của bạn vì họ không thể xác định được địa chỉ của bạn. <br /><br /> Vui lòng cung cấp địa chỉ đầy đủ của bạn, bao gồm mọi địa danh bên dưới.`;
        case 14:
          return `Đối tác vận chuyển của chúng tôi đã thông báo với chúng tôi rằng họ không thể xử lý yêu cầu lấy hàng của bạn vì bạn không có mặt. Vui lòng chọn ngày nhận hàng bạn muốn.`;
        case 0:
        default:
          return `Bốc hàng thất bại: Chúng tôi đang xác định vấn đề với yêu cầu của bạn. Vui lòng cung cấp ngày bốc hàng ưu tiên của bạn. Trong trường hợp bạn có thắc mắc, vui lòng viết chúng tôi trong phần bình luận dưới đây:`;
      }
    },
    nprFdForm: {
      address:
        'Đối tác bưu tá thông báo cho chúng tôi rằng họ đã cố gắng tìm đến địa chỉ của bạn, nhưng không thể tìm thấy địa chỉ của bạn. Bạn có thể cung cấp cho chúng tôi biết thêm chi tiết về địa chỉ của bạn không?',
      timing: {
        a:
          'Đối tác bưu tá của chúng tôi sẽ cố gắng 3 lần cho một đơn bốc hàng. Trong trường hợp, gói hàng không bốc được lần đầu tiên, chúng tôi sẽ thực hiện lại nó một lần nữa vào ngày hôm sau trừ chủ nhật và ngày lễ.',
        b:
          'Vui lòng đảm bảo rằng một người nào đó có sẵn tại địa chỉ bốc hàng để bàn giao gói hàng.',
        c: 'Bạn có muốn bốc hàng vào những ngày cụ thể không?'
      },
      other:
        'Hãy chia sẻ bất kỳ chi tiết bổ sung mà có thể giúp chúng tôi bốc được đơn hàng, đồng thời xác minh chéo yêu cầu trả lại của bạn trong trường hợp có sai lệch về mặt hàng và số lượng.',
      phone: {
        a:
          'Trong một số trường hợp, các đối tác bưu tá gọi từ điện thoại cố định mà có thể cảm thấy như là lừa đảo. Chúng tôi sẽ yêu cầu đối tác bưu tá thực hiện lại việc bốc hàng.',
        b:
          'Bạn có muốn đối tác bưu tá gọi cho bạn trên cùng một số khi đặt đơn hàng không?'
      },
      reject:
        'Vui lòng xác nhận nếu bạn muốn chúng tôi hủy bỏ việc bốc hàng cho đơn hàng của bạn:'
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `Tổng tính dụng <strong>${creditValue}</strong> dùng để thanh toán cho đơn hàng này sẽ được trả lại vào tài khoản cửa hàng của bạn đối với đơn hàng này.`,
      totalAsCredit: creditValue =>
        `Bạn có muốn hoàn lại ${
          creditValue > 0 ? 'phần còn lại của' : ''
        } số tiền đã thanh toán như là tín dụng cửa hàng?`,
      totalAsCreditNew:
        'Vui lòng chọn phương thức thanh toán hoàn phí ưa thích của bạn',
      prepaid: calculatedAmt =>
        `Bạn sẽ nhận được <strong>dong ${calculatedAmt}</strong> trở lại trong chế độ giao dịch trực tuyến ban đầu của bạn.`,
      cod: calculatedAmt =>
        `Vui lòng nhập thông tin tài khoản ngân hàng của bạn. Bạn sẽ nhận được khoảng tiền trả (dong <strong>${calculatedAmt}</strong>) trong tài khoản ngân hàng cung cấp dưới đây.`,
      refundTotalAsCredit: totalAmount =>
        `Hoàn trả cho tín dụng cửa hàng: <strong>${totalAmount}</strong>`,
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: () =>
        'Số tiền hoàn lại của bạn sẽ được ghi có trở lại Tín dụng thương hiệu của bạn',
      defaultPrepaid: (totalAmount, currency) =>
        `Số tiền hoàn lại <strong>${currency ??
          ''} ${totalAmount}</strong> của bạn sẽ được ghi có trở lại nguồn`
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'Đã vận chuyển',
    readyForDispatch: 'Sẵn sàng cho Công văn',
    trackOrder: 'Theo dõi thứ tự',
    deliveryFeedback: 'Phản hồi giao hàng',
    raiseConcern: 'Quan hệ phát triển',
    expired: 'Đã hết hạn',
    downloadApp: 'Tải xuống ứng dụng',
    allRightsReserved: 'Đã đăng ký Bản quyền',
    emailUs: 'Email us: ',
    callUs: 'Call us: ',
    poweredBy: 'Powered by ',
    login: 'Login',
    username: 'Username',
    psswrd: 'Password',
    returnRequestReceived: 'Return request received',
    returnPlaceDate: 'Return placed date',
    reason: 'Reason',
    anyOtherComment: 'Comment',
    addImage: 'Add Image',
    quantity: 'Quantity',
    back: 'Back',
    edit: 'Edit',
    returnSummary: 'Return Summary',
    returnStatus: 'Return Status',
    pickup: 'Pickup',
    selectReversePickupAddress: 'Select Reverse Pickup Address',
    selectOrAddAddress: 'Select/Add Pickup Address',
    phoneFormat: format =>
      `Please enter a phone number in the format ${format} without the Phone Code`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Request Pickup',
    selfShip: 'Self Ship',
    review: 'Review',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Pickup Date',
    orderStatus: 'Order Status',
    expectedPickupDate: 'Expected Pickup Date',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Approved',
    goHome: 'Go Home',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Update Pickup Address',
    fullName: 'Full Name',
    emailId: 'Email ID',
    city: 'City',
    area: 'Area',
    state: 'State',
    country: 'Country',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    bankDetail: 'Bank Details For Refund',
    upiDetail: 'UPI Details',
    brandCredits: () => 'Tín dụng thương hiệu',
    backToSource: 'Quay lại nguồn',
    bankAccount: 'Tài khoản ngân hàng',
    accountHolderName: 'Account Holder Name',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Account Number',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Fetch',
    enterReversePickupAddress: 'Enter Reverse Pickup Address',
    pleaseUploadSupportingImage: imageQuantity =>
      `Please upload ${imageQuantity} supporting image`,
    processing: 'Processing',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} images required for this request`,
    returns: 'Returns',
    exchange: () => 'Return and Exchange',
    enterYourOrderInformation: 'Enter your order information to get started.',
    ourReturnExchangePolicy: () => 'Our Returns & Exchange Policy',
    refundPolicy: 'Refund Policy',
    termsConditions: 'Terms & Conditions',
    stepOf: currentStep => `Step ${currentStep} of 3`,
    allPriceShownPerItem: 'Tất cả giá hiển thị đều ở mức mặt hàng',
    qtyPerOrderNote: 'Note: Qty shows original ordered item quantity',
    returnedQty: 'Returned quantity',
    qty: 'Qty',
    placeFreshPickupRequest: 'Pickup cancelled, place fresh pickup request.',
    remove: 'Remove',
    return: 'Return',
    startReturn: 'Start Return',
    whyAreYouReturning: 'Why are you returning the product?', // optional
    whyYouWantReturn: 'Why do you want to return?',
    selfShipMode:
      'As you selected Self Ship Mode. Please pack the items securely to prevent any loss or damage during transit.',
    requestPlacedDate: 'Request Placed Date',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'Giao hàng sớm',
    max200Chars: 'Max. 200 characters',
    select: 'Select',
    charsLeft: chars => `${chars} characters left`
  },
  hi: {
    courier: 'कूरियर',
    trackingId: 'ट्रैकिंग आईडी',
    orderId: 'ऑर्डर आईडी',
    lastUpdate: 'आखिरी प्राप्त जानकारी',
    orderPlacedOn: 'ऑर्डर करने की तारीख',
    orderType: 'ऑर्डर का प्रकार',
    riderInfo: 'राइडर इन्फो',
    orderPlaced: 'ऑर्डर हो गया',
    dispatched: 'ऑर्डर निकल गया',
    inTransit: 'ट्रांजिट में है',
    failedDelivery: 'डिलीवरी असफल',
    ofd: 'डिलीवरी के लिए निकल गया',
    delivered: 'डिलीवर हो गया',
    pickupRequested: 'पिकअप की मांग की गई है',
    updatedOn: 'अपडेटेड',
    returnInstructions: 'पुन: निर्देश',
    returnPlaced: 'Return Placed',
    pickupCancelled: 'पिकअप रद्द कर दिया गया',
    ofp: 'पिकअप के लिए निकल गया',
    pickedup: 'पिकअप हो गया',
    returned: 'वापस कर दिया गया',
    reverseReturned: 'वापस कर दिया गया',
    readyToShip: 'भेजने के लिए तैयार',
    lost: 'नहीं मिला',
    damaged: 'क्षतिग्रस्त हो गया/टूट गया',
    cancelled: 'रद्द कर दिया गया',
    requestPlaced: 'अनुरोध किया गया',
    requestAccepted: 'Request Accepted',
    requestAcceptedWithDate: date => `Your return has been accepted on ${date}`,
    confirmed: 'पक्का हो गया',
    confirm: 'पुष्टि करें',
    requestReturn: 'वापसी का अनुरोध करें',
    pickupFailed: 'पिकअप असफल रहा',
    reachedW: 'गोदाम पहुंच गया',
    requestCancelled: 'मांग रद्द की गई',
    requestRejected: 'Request Rejected',
    location: 'लोकेशन',
    time: 'समय',
    remark: 'टिप्पणी',
    shippingLabel: 'Shipping Label',
    shippingLabelInProgress:
      'Fetching the label from the carrier, it will be available for download in a few minutes',
    shippingLabelReady:
      'Your shipping label has been generated, now you can download it',
    pleaseTellUs: 'कृपया हमें बताएं क्या हुआ?',
    pressNextBtn: 'चुनने के बाद नीचे दी गई Next बटन दबाएं।',
    agentDidntCall: 'डिलीवरी एजेंट ने मुझे कॉल नहीं किया',
    noAttemptToAddress: 'पते पर डिलीवरी करने का कोई प्रयास नहीं किया गया',
    wasNotAvailable: 'जब डिलीवरी का प्रयास किया गया उस वक्त मैं उपलब्ध नहीं था',
    nprAgentDidntCall: 'पिकअप एजेंट ने मुझे कॉल नहीं किया',
    nprNoAttemptToAddress: 'दिए गए पते से पिकअप का कोई प्रयास नहीं किया गया',
    nprWasNotAvailable:
      'जब पिकअप का प्रयास किया गया उस वक्त मैं उपलब्ध नहीं था',
    doNotWantProduct: 'मुझे अब यह उत्पाद नहीं चाहिए',
    doNotWantReturnProduct: 'अब उत्पाद वापस नहीं करता चाहता',
    others: 'अन्य',
    yes: 'हां',
    no: 'नहीं',
    preferredDate: 'डिलीवरी की पसंदीदा तारीख',
    nprPreferredDate: 'पिकअप के लिए पसंदीदी तिथि',
    landmark: 'लैंडमार्क',
    address: 'पता',
    pincode: 'पिन कोड',
    provideAltNo: 'कृपया एक वैकल्पिक नंबर बताएं',
    comments: 'कमेंट',
    searchBy: 'इसके अनुसार छांटे',
    checkCurrentStatus: 'अपने शिपमेंट की मौजूदा स्थिति जांचें',
    trackYourOrder: 'Track Your Order',
    incorrectSearch:
      'आप जिस ऑर्डर/शिपमेंट को ट्रैक करने का प्रयास कर रहें हैं उसे अब तक भेजा नहीं गया है या डाला गया ऑर्डर नंबर गलत है।',
    viewTracking: 'ट्रैकिंग पर नज़र डाले',
    on: 'on',
    status: 'स्थिति',
    orderDetails: 'आर्डर का ब्योरा',
    noScan1: 'भेजने के लिए तैयार!',
    noScan2: 'ट्रैकिंग ब्योरा जल्द ही उपलब्ध होगा',
    addressErr: 'पता 10 अक्षर से छोटा या खाली नहीं हो सकता',
    pincodeErr: 'कृपया 6 अंको का पिन कोड डालें।',
    pincodeNotServiceable:
      'इस पिन कोड पर अभी हमारी सेवा शुरू नहीं हुई है, कृपया ऑर्डर हम तक भेजें।',
    provideLandmark:
      'आपके पते तक पहुंचने के लिए कृपया कूरियर पार्टनर को लैंडमार्क बताएं।',
    landmarkErr: 'लैंडमाार्क 5 अक्षर से छोटा या खाली नहीं हो सकता',
    dateErr: 'कृपया एक वैैध तारीख डालें।',
    commentErr: 'कमेंट खाली नहीं हो सकता है।',
    addressCharLimit:
      'कृपया कम से कम 10 अक्षर लिखें, अधितम 600 अक्षर लिखे जा सकते हैं।',
    phoneErr: 'कृपया 10 अंकों का एक वैध फोन नंबर अंकित करें।',
    emailidErr: 'कृपया एक वैध ईमेल आईडी डालें।',
    invalidImageErr: 'फ़ाइल स्वरूप समर्थित नहीं है',
    chooseOption: 'कृपया कोई एक विकल्प चुनें।',
    actionInProgress: 'कार्रवाई प्रगति पर है',
    feedbackToastr1:
      'प्रतिक्रिया देने के लिए आपका धन्यवाद। हम दोबारा सेवा के लिए आपकी राह देख रहे हैं',
    feedbackToastr2:
      'प्रतिक्रिया के लिए धन्यवाद! भविष्य में आपको सुखद अनुभव प्रदान करने के लिए हम काम कर रहे हैं।',
    abfrlFeedbackToast:
      'फीडबैक के लिए धन्यवाद, आपको सर्वेक्षण पर पुनर्निर्देशित करता है',

    ratingErr: 'रेटिंग खाली नहीं हो सकती',
    apiErr:
      'हम अनुरोध संसाधित करने में असमर्थ हैं, कृपया बाद में पुनः प्रयास करें',
    updateContactToastr:
      'अपडेट करने के लिए धन्यवाद! हम अब आपके अपडेटेड कॉन्टैक्ट नंबर पर सूचनाएं भेजेंगे।',
    noDataFound:
      'आप जिस ऑर्डर/शिपमेंट को ट्रैक करने का प्रयास कर रहें हैं उसे अब तक भेजा नहीं गया है या डाला गया ऑर्डर नंबर गलत है।',
    veryPoor: 'बहुत खराब',
    excellent: 'शानदार',
    veryPoorAbfrl: 'सिफारिश करने की कम से कम संभावना',
    excellentAbfrl: 'सिफारिश करने की अत्यधिक संभावना',
    veryUnlikely: 'Very Unlikely',
    veryLikely: 'Very Likely',
    submit: 'जमा करें',
    youFacedAnyIssue: 'क्या आपको किसी अन्य दिक्कत का सामना करना पड़ा?',
    updateContact: 'कॉन्टैक्ट अपडेट करें',
    updateContactTooltip: 'कॉन्टैक्ट नंबर बदलने के लिए यहां क्लिक करें',
    myOrders: 'मेरे आर्डर',
    myOrdersTooltip: 'अपने आर्डर देखने के लिए यहां क्लिक करें',
    takeAction: 'कोई कार्रवाई करें',
    takeActionTooltip: 'कोई समस्या बताने के लिए यहां क्लिक करें',
    next: 'अगला',
    anyOtherDate: 'कोई अन्य तारीख',
    share: 'साझा करें',
    expectedDelivery: 'अनुमानित डिलीवरी',
    viewDetail: 'ब्योरा देखें',
    viewSummary: 'विवरण देखें',
    inputFeedback: 'कृपया अपनी प्रतिक्रिया साझा करें',
    feedbackMatters: 'आपकी प्रतिक्रिया मायने रखती है!',
    ratingFeedback: 'बहुमूल्य प्रतिक्रिया देने के लिए आपका धन्यवाद!',
    youRatedUs: 'आपने हमें रेट किया',
    youCommented: 'आपने टिप्पणी की',
    pageNotFound: 'पेज नहीं मिला',
    ops404: 'धत्त तेरे की, यह तो एक 404 है',
    youFoundIncorrectLink:
      'ऐसा प्रतीत हो रहा है कि आपके द्वारा डाला गया लिंक गलत है',
    contactNumber: 'कांटैक्ट नंबर',
    updateContactNumber: 'कांटैक्ट नंबर अपडेट करें',
    updateContactNumberToastr:
      'यह हमें आपसे संपर्क करने व शिपमेंट से जुड़े जरूरी अपडेट भेजनें की अनुमति देता है।',
    searchByTrackingId: 'ट्रैकिंग आईडी/एडब्ल्यूडी',
    searchByOrderId: 'ऑर्डर आईडी/नंबर',
    langName: 'अंग्रेजी',
    rateUs: 'How would you rate for:',
    orderPlaceOnDateFn: date => `${date} को ऑर्डर किया गया`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    submittedConcern: courier =>
      `आपकी मांग को आगे बढ़ाने के लिए हम ${courier} से बात करेंगे।`,
    askRatingFeedback: courier =>
      `${courier} के साथ आपका डिलीवरी का अनुभव कैसा रहा?`,
    askRatingFeedbackArvind: 'NNNOW के साथ आपका खरीदारी का अनुभव कैसा रहा?',
    askRatingFeedbackAbfrl: brandName =>
      `डिलीवरी के इस अनुभव के आधार पर, 0-10 के पैमाने पर, आप किसी मित्र को  ${brandName} की सिफारिश करने की कितनी संभावना रखते हैं?`,
    askRatingFeedbackTrell:
      'अपने हालिया डिलीवरी अनुभव के आधार पर, आप अपने दोस्तों और परिवार को Trell की सिफारिश करने की कितनी संभावना है?',
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience: 'आपका समग्र रिटर्न अनुभव कैसा रहा?',
    askNonPickedExperience:
      'हमने पाया कि आपका शिपमेंट पिक नहीं किया जा सका। आपका कुल मिलाकर रिटर्न अनुभव कैसा रहा?',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (title, websiteUrl) =>
      `अपने ऑर्डर का सही ट्रैकिंग लिंक जानने व उससे जुड़ी जानकारी पाने के लिए कृपया ${websiteUrl} पर संपर्क करें।`,
    statusOnDateFn: (status, date) => `${date} पर ${status}`,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'कृपया हमें बताएं कि क्या समस्या हुई!';
        case 6:
        case 7:
        case 8:
          return 'हम कैसे अपना काम बेहतर कर सकते हैं?';
        default:
          return 'सराहना करें';
      }
    },
    edd: (expression, message) => {
      switch (expression) {
        case 1:
          return message;
        case 2:
          return 'जल्द आ रहा है : आपके नजदीकी लोकेशन तक पहुंच गया है';
        case 3:
          return 'शिपमेंट विलंबित हो गया : कुरियर पार्टनर ने सूचित किया कि आप तक शिपमेंट जल्द से जल्द डिलीवर किया जाएगा';
        case 4:
          return 'अपेक्षा के अनुरूप चल रहा है';
        case 5:
          return 'शिपमेंट विलंबित हो गया : लॉजिस्टिक टीम ने सूचित किया';
        case 7:
          return 'आपके तय की गई डिलीवरी की प्राथमिकता के आधार पर अलगे 24-48 घंटे में डिलीवरी का पुन: प्रयास किया जाएगा';
        case 8:
          return message;
        case 9:
          return 'डिस्पैच के बाद शिपमेंट उसी दिन वितरित की जाएगी';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : आपसे संपर्क न हो पाने के कारण हमारे कुरियर पार्टनर डिलीवरी करने में असमर्थ थे। कृपया अपनी पसंद के अनुसार डिलीवरी की तारीख चुनें।`;
        case 2:
        case 9:
        case 14:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : हमारे कूरियर पार्टनर ने सूचित किया कि आपके द्वारा पैकेज लेने से मना करने के कारण डिलीवरी नहीं हो पाई। ऑर्डर की डिलीवरी प्राप्त करने के लिए 'हां' और ऑर्डर रद्द करने के लिए 'नहीं' पर <br/><br/> क्लिक करें।`;
        case 3:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : हमाारे कूरियर पार्टनर ने सूचित किया कि आपके द्वारा डिलीवरी की तारीख बदलने का अनुरोध डालने के कारण डिलीवरी नहीं की गई। हम आपका ऑर्डर डिलीवर करने का प्रयास फिर से करेंगे! अगर आप हमें अपनी पसंद की तारीख बताना चाहते है तो नीचें जानकारी दर्ज करें:`;
        case 4:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : हमारे कूरियर पार्टनर आज डिलीवरी करने में असमर्थ रहे। <br /><br /> कृपया अपनी पसंद की डिलीवरी तिथि चुनें:`;
        case 5:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : आपसे हमारा संपर्क न हो पाने के कारण हमारे कूरियर पार्टनर आज डिलीवरी करने में असमर्थ रहे। कृपया अपने वैकल्पिक नंबर की जानकारी साझा करें ताकि हमारे कूरियर पार्टनर आपसे संपर्क कर सकें। क्या आप चाहते हैं कि कूरियर पार्टनर आर्डर करते समय दिए गए नंबर पर आपसे संपर्क करें?`;
        case 6:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : आपका पता न खोज पाने के कारण हमारे कूरियर पार्टनर डिलीवरी करने में असमर्थ रहे। कृपया लैंडमार्क की जानकारी के साथ अपना पूरा पता नीचे दर्ज करें:`;
        case 7:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : डिलीवरी के वक्त पेमेंट तैयार न होने के कारण हमारे कूरियर पार्टनर डिलीवरी करने में असमर्थ रहे। <br /><br /> परेशान न हों, हम उसने आपके द्वारा चुनी गई तारीख पर दोबारा डिलीवरी करने के लिए कहेंगे। अगर आप को किसी अन्य समस्या का सामना करना पड़ा है, तो नीचे अपनी प्रतिक्रिया दें`;
        case 8:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : आपका पता न खोज पाने के कारण हमारे कूरियर पार्टनर डिलीवरी करने में असमर्थ रहे। कृपया लैंडमार्क की जानकारी के साथ अपना पूरा पता नीचे दर्ज करें:`;
        case 10:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : हमारे कूरियर पार्टनर ने सूचित किया कि आप उनके स्थान से आर्डर उठाएंगे।  <br /><br /> अगर यह वजह नहीं है, तो कृपया हमें अपने पसंद की डिलीवरी तिथि बताएं। अगर आपके मन में कोई शंका है, तो नीचे दिए कमेंट सेक्शन में लिखकर हमें बताएं:`;
        case 11:
          return `क्या आपको डिलीवरी के दौरान किसी समस्या का सामना करना पड़ा? कृपया अपनी प्रतिक्रिया नीचे दें:`;
        case 12:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : हमारे कूरियर पार्टनर ने सूचित किया कि आपने ओपन डिलीवरी की मांग की है। कृपया अपनी पसंद की डिलीवरी तिथि बताएं। अगर आपके मन में कोई शंका है, तो हमें नीचे दिए कमेंट सेक्शन में लिखकर बताएं। आपकी प्रतिक्रिया हम हमारी टीम तक पहुंचा देंगे।`;
        case 13:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : दुर्भाग्य से हमारे कूरियर पार्टनर ने आपका शिपमेंट गलत स्थान पर भेज दिया है। आपका आर्डर आप तक जल्द पहुंचे हम यह सुनिश्चित करने के लिए हर संभव प्रयास कर रहे हैं । कृपया अपने पसंद की डिलीवरी तिथि बताएं। अगर आप कोई भी प्रतिक्रिया साझा करना चाहते है, तो नीचे कमेंट सेक्शन में लिखकर हमें बताएं:`;
        case 0:
        default:
          return `ऑर्डर की डिलीवरी नहीं हो पाई : आपके शिपमेंट को लेकर हुई समस्या का हम पता लगा रहे हैं। कृपया हमें अपने पसंद की डिलीवरी तिथि बताएं। अगर आपको कोई शंका है, तो नीचे कमेंट सेक्शन में लिखकर हमें बताएं:`;
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'हमारे कूरियर पार्टनर ने हमें सूचित किया कि वे आपका पता खोजने में असमर्थ रहे। क्या आप अपने पते के बारे में अधिक जानकारी साझा कर सकते हैं?',
      timing: {
        a:
          'हमारे कूरियर पार्टनर 3 बार डिलीवरी करने का प्रयास करते हैं। अगर किसी कारणवश पहली बार में आपको डिलीवरी नहीं हो पाती है तो हम रविवार व सार्वजनिक छुट्टियों को छोड़कर अन्य दिनों में फिर से डिलीवरी करने का प्रयास करते हैं।',
        b:
          'कृपया यह सुनिश्चित कर लें कि पैकेज को प्राप्त करने के लिए आपके पते पर कोई मौजूद है।',
        c:
          'क्या आप चाहते हैं कि किसी खास दिन आपको डिलीवरी देने का प्रयास किया जाए?'
      },
      other: () =>
        'कृपया कोई भी अतिरिक्त जानकारी साझा करें, जो हमें आपका ऑर्डर आप तक पहुंचाने में मदद करे',
      phone: {
        a:
          'कई बार कूरियर पार्टनर लैंडलाइन से आपको फोन करते हैं, जिसे आप फर्जी कॉल समझकर उठाते नहीं हैं। हम कूरियर पार्टनर से दोबारा डिलीवरी करने की कोशिश करने के लिए कहेगें।',
        b: () =>
          'क्याया आप चाहते हैं कि ऑर्डर करने के दौरान दिए गए नंबर पर डिलीवरी पार्टनर आपसे संपर्क करे?'
      },
      reject:
        'कृपया पक्का करें कि आप चाहते हैं कि हम आपके शिपमेंट की डिलीवरी का प्रयास दोबारा करें?'
    },
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `पिकअप असफल रहा : आपसे संपर्क न हो पाने के कारण हमारे कूरियर पार्टनर पिकअप करने में असमर्थ रहे। कृपया अपना पता व कांटैक्ट नंबर साझा करें।`;
        case 2:
          return `पिकअप असफल रहा: हमारे कूरियर पार्टनर ने सूचित किया कि आपके द्वारा पिकअप के अनुरोध को रद्द करने की मांग करने के कारण पैकेज पिकअप नहीं किया जा सका।   <br /><br /> पिकअप करने के लिए 'हां' व पिकअप रद्द करने के लिए 'नहीं' पर क्लिक करें।`;
        case 3:
        case '3a':
          return `उत्पाद टूटा या इस्तेमाल किया हुआ था`;
        case 4:
          return `उत्पाद का रंग या ब्रांड, दोनों में से एक अलग था`;
        case 5:
        case '5a':
          return `कुल वस्तुओं की संख्या मंगाई गई वस्तुओं की संख्या से अलग थी`;
        case 6:
          return `पिकअप असफल रहा: कृपया पिकअप के लिए अपने पसंद की तिथि बताएं`;
        case 7:
          return `पिकअप असफल रहा : आपका पता सर्विस क्षेत्र से बाहर होने के कारण हमारी कूरियर कंपनी पिकअप करने में असफल रही।`;
        case 8:
        case '8a':
          return 'हमारी कूरियर कंपनी ने हमें सूचित किया कि पिकअप करने का कई बार प्रयास किया गया लेकिन कुछ कारणों से पिकअप नहीं हो पाया, कृपया अपनी उपलब्धता के आधार पर पिकअप के लिए अपनी पसंद की तिथि चुने। अगर आपके मन में कोई संदेह हो तो नीचे कमेंट सेक्शन में लिखकर हमें बताएं';
        case 9:
          return 'कूरियर कंपनी पिकअप का प्रयास करने में असमर्थ रही है। कृपया ऑर्डर को पिकअप के लिए तैयार रखे, अगला पिकअप का प्रयास आमामी बिजनेस डे पर होगा। अगर आप कोई प्रतिक्रया साझा करना चाहते हैं, तो नीचे कमेंट सेक्शन में लिखे।';
        case 10:
          return 'हमारे कूरियर पार्टनर नेे हमें सूचित किया है कि आपने पिकअप के लिए मना कर दिया है। अगर कोई अन्य समस्या है, तो अपने पसंद के अनुसार पिकअप की अगली तारीख बताएं। अगर आपके कोई सवाल हैं, तो हमें नीचे कमेंट सेक्शन में लिखकर बताएं';
        case 11:
          return 'हमारे कूरियर पार्टनर ने बताया कि उत्पाद पिकअप के लिए तैयार नहीं था। अगर कोई अन्य समस्या है, तो अपने पसंद के अनुसार पिकअप की अगली तारीख बताएं। अगर आपके कोई सवाल हैं तो हमें नीचे कमेंट सेक्शन में लिखकर बताएं';
        case 13:
          return `हमारे कूरियर पार्टनर ने हमें सूचित किया कि वे आपका पैकेज नहीं उठा सकते क्योंकि वे आपका पता ढूंढने में असमर्थ थे। <br /><br /> कृपया नीचे किसी भी स्थलचिह्न सहित अपना पूरा पता प्रदान करें।`;
        case 14:
          return `हमारे वाहक भागीदार ने हमें सूचित किया कि वे आपके अनुपलब्ध होने के कारण आपके पिकअप अनुरोध को संसाधित नहीं कर सके। कृपया अपनी पसंदीदा पिकअप तिथि चुनें।`;
        case 0:
        default:
          return `पिकअप असफल रहा : हम आपके अनुरोध को लेकर हुई समस्या का पता लगाने का प्रयास कर रहे हैं।  अपनी पसंद के अनुसार पिकअप की अगली तारीख बताएं। अगर आपके कोई सवाल हैं, तो हमें नीचे कमेंट सेक्शन में लिखकर बताएं`;
      }
    },
    nprFdForm: {
      address:
        'कूरियर पार्टनर ने हमें सूचित किया कि उन्होंने आपके पते तक पहुंचने का प्रयास किया लेकिन वह पता खोजने में असमर्थ रहे। क्या आप अपने पते के बारे में और जानकारी साझा कर सकते हैं?',
      timing: {
        a:
          'हमारे कूरियर पार्टनर 3 बार पिकअप करने का प्रयास करते हैं। अगर किसी कारणवश पहली बार में पिकअप नहीं हो पाता है, तो हम रविवार व सार्वजनिक छुट्टियों को छोड़कर अन्य दिनों में फिर से पिकअप करने का प्रयास करते हैं।',
        b:
          'कृपया यह सुनिश्चित कर लें कि पिकअप के दौरान पैकेज को सौंपने के लिए आपके पते पर कोई मौजूद है।',
        c: 'क्या आप किसी खास दिन पिकअप करवाना चाहते हैं?'
      },
      other:
        'कृपया हमसे कोई भी अतिरिक्त जानकारी साझा करें जो हमें ऑर्डर पिकअप करने में सहायता प्रदान करे। साथ ही उत्पाद/गुणवत्ता मेल न खाने पर अपने वापसी के आवेदन की दोबारा जांच कर लें।',
      phone: {
        a:
          'कई बार कूरियर पार्टनर लैंडलाइन से आपको फोन करते हैं, जिसे आप फर्जी कॉल समझकर उठाते नहीं हैं। हम कूरियर पार्टनर से दोबारा पिकअप का प्रयास करने के लिए कहेगें।',
        b:
          'क्या आप चाहते हैं कि आर्डर करने के दौरान दिए गए नंबर पर कूरियर पार्टनर आपसे संपर्क करें?'
      },
      reject:
        'कृपया पक्का करें कि आप चाहते हैं कि हम आपके शिपमेंट के पिकअप की मांग को रद्द करें?'
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `इस ऑर्डर के पेमेंट में इस्तेमाल किए गए कुल <strong>${creditValue}</strong> क्रेडिट आपके स्टोर खाते में फिर से जोड़ दिए गए हैं`,
      totalAsCredit: creditValue =>
        `क्या आप ${
          creditValue > 0 ? 'रिफंड' : 'शेष'
        } राशि को स्टोर क्रेडिट के रूप में स्वीकार करना चाहेंगे।`,
      totalAsCreditNew: 'कृपया धनवापसी भुगतान का अपना पसंदीदा तरीका चुनें',
      prepaid: calculatedAmt =>
        `${calculatedAmt} रुपए की रकम भुगतान के दौरान इस्तेमाल हुए ऑनलाइन मोड में आपको प्राप्त हो जाएगी।`,
      cod: calculatedAmt =>
        `कृपया अपने बैंक खाते की जानकारी दर्ज करें। आपके द्वारा किया गया कैश भुगतान (Rs. ${calculatedAmt}) आपको नीचे दिए बैंक खाते में प्राप्त होगा`,
      refundTotalAsCredit: totalAmount =>
        `${totalAmount} का रिफंड स्टोर क्रेडिट में प्राप्त हुए`,
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: () =>
        'आपकी धनवापसी राशि आपके ब्रांड क्रेडिट में वापस क्रेडिट कर दी जाएगी',
      defaultPrepaid: (totalAmount, currency) =>
        `आपकी ${currency ??
          ''} ${totalAmount} की धनवापसी राशि वापस स्रोत में जमा कर दी जाएगी`
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'भेज दिया',
    readyForDispatch: 'निकलने को तैयार',
    trackOrder: 'ऑर्डर ट्रैक करें',
    deliveryFeedback: 'डिलीवरी पर प्रतिक्रिया',
    raiseConcern: 'सवाल पूछें',
    expired: 'समय सीमा समाप्त',
    downloadApp: 'एप्लिकेशन डाउनलोड करें',
    allRightsReserved: 'सर्वाधिकार सुरक्षित',
    emailUs: 'Email us: ',
    callUs: 'Call us: ',
    poweredBy: 'Powered by ',
    login: 'Login',
    username: 'Username',
    psswrd: 'Password',
    returnRequestReceived: 'Return request received',
    returnPlaceDate: 'Return placed date',
    reason: 'Reason',
    anyOtherComment: 'Comment',
    addImage: 'Add Image',
    quantity: 'Quantity',
    back: 'Back',
    edit: 'Edit',
    returnSummary: 'Return Summary',
    returnStatus: 'Return Status',
    pickup: 'Pickup',
    selectReversePickupAddress: 'Select Reverse Pickup Address',
    selectOrAddAddress: 'Select/Add Pickup Address',
    phoneFormat: format =>
      `Please enter a phone number in the format ${format} without the Phone Code`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Request Pickup',
    selfShip: 'Self Ship',
    review: 'Review',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Pickup Date',
    orderStatus: 'Order Status',
    expectedPickupDate: 'Expected Pickup Date',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Approved',
    goHome: 'Go Home',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Update Pickup Address',
    fullName: 'Full Name',
    emailId: 'Email ID',
    city: 'City',
    area: 'Area',
    state: 'State',
    country: 'Country',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    bankDetail: 'Bank Details For Refund',
    upiDetail: 'UPI Details',
    brandCredits: () => 'ब्रांड क्रेडिट',
    backToSource: 'स्रोत पर वापस जाएं',
    bankAccount: 'बैंक खाता',
    accountHolderName: 'Account Holder Name',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Account Number',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Fetch',
    enterReversePickupAddress: 'Enter Reverse Pickup Address',
    pleaseUploadSupportingImage: imageQuantity =>
      `Please upload ${imageQuantity} supporting image`,
    processing: 'Processing',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} images required for this request`,
    returns: 'Returns',
    exchange: () => 'Return and Exchange',
    enterYourOrderInformation: 'Enter your order information to get started.',
    ourReturnExchangePolicy: () => 'Our Returns & Exchange Policy',
    refundPolicy: 'Refund Policy',
    termsConditions: 'Terms & Conditions',
    stepOf: currentStep => `Step ${currentStep} of 3`,
    allPriceShownPerItem: 'दर्शाई गई सभी कीमतें वस्तु स्तर पर हैं',
    qtyPerOrderNote: 'Note: Qty shows original ordered item quantity',
    qty: 'Qty',
    returnedQty: 'Returned quantity',
    placeFreshPickupRequest: 'Pickup cancelled, place fresh pickup request.',
    remove: 'Remove',
    return: 'Return',
    startReturn: 'Start Return',
    whyAreYouReturning: 'Why are you returning the product?', // optional
    whyYouWantReturn: 'Why do you want to return?',
    selfShipMode:
      'As you selected Self Ship Mode. Please pack the items securely to prevent any loss or damage during transit.',
    requestPlacedDate: 'Request Placed Date',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'शिपिंग जल्द ही',
    max200Chars: 'Max. 200 characters',
    select: 'Select',
    charsLeft: chars => `${chars} characters left`
  },
  mr: {
    actionInProgress: 'कृती सुरु आहे..',
    address: 'पत्ता',
    addressCharLimit:
      'कृपया कमीत कमी १० अक्षरे भरा आणि ६०० पेक्षा जास्त अक्षरे भरू नका.',
    addressErr:
      'पत्ता भरण्याची जागा रिकामी सोडता किंवा १० अक्षरांपेक्षा कमी मजकुरात भरता येणार नाही',
    agentDidntCall: 'डिलिव्हरी एजंटने मला कॉल केला नाही',
    anyOtherDate: 'इतर कोणती तारीख?',
    cancelled: 'रद्द',
    checkCurrentStatus: 'तुमच्या शिपमेंटची सद्यस्थिती तपासा',
    trackYourOrder: 'Track Your Order',
    chooseOption: 'कृपया कुठलाही पर्याय निवडा.',
    commentErr: 'कॉमेंटचा रकाना रिकामा सोडला जाऊ शकत नाही',
    comments: 'कॉमेंट्स',
    confirmed: 'निश्चित झाले',
    confirm: 'Confirm',
    requestReturn: 'परतीची विनंती',
    contactNumber:
      'यामुळे तुम्हाला संपर्क करता येईल आणि शिपमेंटबद्दल महत्वाचे अपडेट्स पाठवता येतील.',
    courier: 'कुरियर',
    damaged: 'खराब झाले',
    dateErr: 'कृपया एक वैध तारीख प्रविष्ट करा.',
    delivered: 'डिलिव्हरी पूर्ण झाली',
    dispatched: 'पाठवले आहे',
    doNotWantProduct: 'हे उत्पादन मला यापुढे नको आहे',
    emailidErr: 'कृपया वैध ईमेल आय.डी. प्रविष्ट करा.',
    invalidImageErr: 'फाइल स्वरूप समर्थित नाही',
    excellent: 'अत्युत्तम',
    expectedDelivery: 'अपेक्षित डिलिव्हरी',
    failedDelivery: 'डिलिव्हरीमध्ये त्रुटी',
    feedbackToastr1:
      'प्रतिक्रिया दिल्याबद्दल धन्यवाद! पुन्हा तुमच्या सेवेच्या संधीची वाट पाहत आहोत.',
    feedbackToastr2:
      'प्रतिक्रिया दिल्याबद्दल धन्यवाद! पुढच्या वेळी आम्ही तुम्हाला अधिक चांगली सेवा देऊ!',
    abfrlFeedbackToast:
      'तुम्हाला सर्वेक्षणात पुनर्निर्देशित करत अभिप्रायाबद्दल धन्यवाद',

    inTransit: 'प्रवासात आहे',
    incorrectSearch:
      'तुम्ही जी ट्रॅक करण्याचा प्रयत्न करत आहात ती ऑर्डर/ शिपमेंट अजून शिप झालेली नाही किंवा प्रविष्ट केलेला ऑर्डर क्रमांक चुकीचा आहे.',
    inputFeedback: 'कृपया तुमची प्रतिक्रिया द्या',
    landmark: 'खुणेची जागा',
    landmarkErr:
      'खुणेच्या जागेचा रकाना रिकामा सोडता किंवा त्यात ५ अक्षरांपेक्षा कमी मजकूर भरता येणार नाही.',
    langName: 'इंग्रजी',
    rateUs: 'How would you rate for:',
    lastUpdate: 'शेवटचा अपडेट',
    location: 'स्थळ',
    lost: 'गहाळ झाले',
    myOrders: 'कृती करा',
    myOrdersTooltip: 'समस्येबद्दल सांगण्यासाठी इथे क्लिक करा',
    next: 'पुढे',
    no: 'नाही',
    noAttemptToAddress:
      'या डिलिव्हरी पत्त्यावर डिलिव्हरीचा कोणताही प्रयत्न झालेला नाही',
    noDataFound:
      'तुम्ही जी ट्रॅक करण्याचा प्रयत्न करत आहात ती ऑर्डर/ शिपमेंट अजून शिप झालेली नाही किंवा प्रविष्ट केलेला ऑर्डर क्रमांक चुकीचा आहे.',
    noScan1: 'शिपिंगसाठी तयार!',
    noScan2: 'ट्रॅकिंगचा तपशील थोड्याच वेळात उपलब्ध होईल',
    ofd: 'डिलिव्हरी सुरु झाली आहे',
    ofp: 'पिकअप सुरु',
    ops404: 'ऊप्स दॅट्स अ ४०४',
    on: 'on',
    orderDetails: 'ऑर्डरचा तपशील',
    orderId: 'ऑर्डर आय.डी.',
    orderPlaced: 'ऑर्डर पूर्ण झाली',
    orderPlacedOn: 'या दिवशी ऑर्डर केली',
    orderType: 'ऑर्डर प्रकार',
    riderInfo: 'रायडर माहिती',
    pageNotFound: 'पेज नॉट फाउंड',
    phoneErr: 'कृपया १० अंकी वैध फोन नंबर प्रविष्ट करा.',
    pickedup: 'पिकअप पूर्ण झाले',
    pickupCancelled: 'पिकअप रद्द केले',
    pickupFailed: 'पिकअप झाले नाही',
    pickupRequested: 'पिकअपसाठी विनंती केली आहे',
    updatedOn: 'वर अद्यतनित',
    returnInstructions: 'पुन्हा प्रयत्न सूचना',
    pincode: 'पिनकोड',
    pincodeErr: 'कृपया ६ अंकी पिनकोड प्रविष्ट करा',
    pincodeNotServiceable:
      'या पिनकोडवर सेवा उपलब्ध नाही, कृपया ऑर्डर आम्हाला शिप करा',
    pleaseTellUs: 'काय झाले हे कृपया आम्हाला सांगा?',
    preferredDate: 'डिलिव्हरीसाठी सोयीस्कर तारीख',
    pressNextBtn: 'निवड केल्यानंतर तळाशी असलेले नेक्स्ट बटण दाबा',
    provideAltNo: 'कृपया पर्यायी नंबर द्या',
    provideLandmark:
      'तुमचा पत्ता कुरियर पार्टनरला शोधता यावा यासाठी कृपया काही खुणेची जागा सांगा.',
    ratingErr: 'रेटिंगचा रकाना रिकामा सोडला जाऊ शकत नाही',
    apiErr:
      'आम्ही विनंतीवर प्रक्रिया करण्यात अक्षम आहोत, कृपया नंतर पुन्हा प्रयत्न करा',
    feedbackMatters: 'तुमचा अभिप्राय महत्त्वाचा!',
    ratingFeedback: 'तुमची बहुमूल्य प्रतिक्रिया दिल्याबद्दल धन्यवाद!',
    youRatedUs: 'तुम्ही आम्हाला रेट केले',
    youCommented: 'आपण टिप्पणी दिली',
    reachedW: 'गोदामामध्ये पोहोचले',
    reverseReturned: 'रिटर्न केले आहे',
    readyToShip: 'शिप करण्यासाठी तयार',
    remark: 'टिप्पणी',
    shippingLabel: 'Shipping Label',
    shippingLabelInProgress:
      'Fetching the label from the carrier, it will be available for download in a few minutes',
    shippingLabelReady:
      'Your shipping label has been generated, now you can download it',
    requestCancelled: 'विनंती रद्द झाली',
    requestRejected: 'Request Rejected',
    requestPlaced: 'विनंती नोंदवली',
    requestAccepted: 'Request Accepted',
    requestAcceptedWithDate: date => `Your return has been accepted on ${date}`,
    returned: 'रिटर्न केले आहे',
    searchBy: 'ने शोधा',
    searchByTrackingId: 'ट्रॅकिंग आय.डी. / ए.डब्लू.बी.',
    searchByOrderId: 'ऑर्डर आय.डी. / क्रमांक',
    share: 'शेअर करा',
    status: 'स्थिती',
    submit: 'सबमिट करा',
    takeAction: 'कृती करा',
    time: 'वेळ',
    trackingId: 'ट्रॅकिंग आय.डी.',
    updateContact: 'संपर्काचा तपशील अपडेट करा',
    updateContactNumber: 'संपर्क क्रमांक अपडेट करा',
    updateContactNumberToastr:
      'यामुळे तुम्हाला संपर्क करण्यात आणि शिपमेंटबद्दल महत्वाचे अपडेट्स पाठवण्यात आम्हाला मदत होते.',
    updateContactToastr:
      'अपडेट केल्याबद्दल धन्यवाद!, अपडेट केलेल्या संपर्क क्रमांकावर आम्ही नोटिफिकेशन्स पाठवू.',
    updateContactTooltip: 'संपर्क क्रमांक बदलण्यासाठी इथे क्लिक करा',
    veryPoor: 'खूप वाईट',
    veryPoorAbfrl: 'किमान शिफारस करणे',
    excellentAbfrl: 'बहुधा शिफारस करणे',
    veryUnlikely: 'Very Unlikely',
    veryLikely: 'Very Likely',
    viewDetail: 'तपशील पाहा',
    viewSummary: 'सारांश पाहा',
    viewTracking: 'ट्रॅकिंग पाहा',
    wasNotAvailable:
      'डिलिव्हरीचा प्रयत्न केला गेला तेव्हा मी उपस्थित नव्हतो/ते',
    yes: 'हो',
    youFacedAnyIssue: 'तुम्हाला इतर कोणती समस्या जाणवली का?',
    youFoundIncorrectLink: 'तुम्हाला चुकीची लिंक मिळाली आहे असे दिसते.',
    orderPlaceOnDateFn: date => `या ${date} तारखेला ऑर्डर केली`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    submittedConcern: courier =>
      `तुमची विनंती प्रोसेस करण्यासाठी आम्ही ${courier} कडे विचारणा करू.`,
    askRatingFeedback: courier =>
      `${courier} डिलिव्हरीचा तुमचा अनुभव कसा होता?`,
    askRatingFeedbackArvind: 'NNNOW सह आपला खरेदी अनुभव कसा होता?',
    askRatingFeedbackAbfrl: brandName =>
      `या डिलिव्हरी अनुभवाच्या आधारे 0-10 च्या प्रमाणात आपण  ${brandName} मित्राची शिफारस कशी करावी?`,
    askRatingFeedbackTrell:
      'Based on your recent delivery experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience: 'तुमचा एकूण परतीचा अनुभव कसा होता?',
    askNonPickedExperience:
      'तुमची शिपमेंट उचलली जाऊ शकत नाही हे आमच्या लक्षात आले. तुमचा एकूण परतीचा अनुभव कसा होता?',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (title, websiteUrl) =>
      `अचूक ट्रॅकिंग लिंक आणि तुमच्या ऑर्डरबद्दलच्या माहितीसाठी कृपया ${title} ${websiteUrl} यांच्याशी संपर्क साधा.`,
    statusOnDateFn: (status, date) => `${status} या ${date} `,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'कोणती समस्या आली ते कृपया आम्हाला सांगा!';
        case 6:
        case 7:
        case 8:
          return 'आम्ही आमची सेवा अधिक चांगली कशी बनवू शकतो?';
        default:
          return 'कौतुक करा!';
      }
    },
    edd: (expression, message) => {
      switch (expression) {
        case 1:
          return message;
        case 2:
          return 'लवकरच येत आहे:  तुमच्या जवळच्याच एका ठिकाणी पोहोचले आहे';
        case 3:
          return 'शिपमेंटला उशीर झाला आहे:  तुम्हाला लवकरात लवकर शिपमेंट डिलिव्हर करावे असे कुरियर पार्टनरला सांगण्यात आले आहे';
        case 4:
          return 'अपेक्षेप्रमाणे प्रवास होत आहे';
        case 5:
          return 'शिपमेंटला उशीर झाला आहे:  लॉजिस्टिक्स टीमला कळवले आहे';
        case 7:
          return 'तुमच्या डिलिव्हरी प्राधान्यानुसार पुढच्या २४-४८ तासांत पुन्हा प्रयत्न केला जाईल';
        case 8:
          return message;
        case 9:
          return 'एकदा पाठविल्यानंतर त्याच दिवशी शिपमेंट वितरित केले जाईल';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `ऑर्डर डिलिव्हर झाली नाही: तुम्हाला संपर्क साधणे शक्य न झाल्यामुळे आमच्या कुरियर पार्टनरला तुमची ऑर्डर डिलिव्हर करता आली नाही.कृपया डिलिव्हरीसाठी तुमच्या पसंतीची तारीख निवडा: `;
        case 2:
        case 9:
        case 14:
          return `ऑर्डर डिलिव्हर झाली नाही: आमच्या कुरियर पार्टनरने आम्हाला कळवले की तुमचे पॅकेज डिलिव्हर होऊ शकले नाही कारण तुम्ही डिलिव्हरी नाकारण्याची विनंती केलीत.ऑर्डर डिलिव्हर व्हावी यासाठी 'हो' वर क्लिक करा आणि ऑर्डर रद्द करण्यासाठी 'नाही' वर क्लिक करा.`;
        case 3:
          return `ऑर्डर डिलिव्हर झाली नाही: आमच्या कुरियर पार्टनरला तुमची ऑर्डर डिलिव्हर करणे शक्य झाले नाही कारण तुम्ही वेळापत्रक बदलण्याची विनंती केलीत.आम्ही पुन्हा येऊ आणि डिलिव्हर करू! तुम्हाला जर तुमचे डिलिव्हरीचे प्राधान्य आम्हाला सांगायचे असेल,
  तर खालील माहिती भरा: `;
        case 4:
          return `ऑर्डर डिलिव्हर झाली नाही: आमच्या कुरियर पार्टनरला आज डिलिव्हरी करणे शक्य झाले नाही.कृपया डिलिव्हरीसाठी वेगळी तारीख निवडा: `;
        case 5:
          return `Order Undelivered: Our courier partner was unable to deliver your order since we could not get in touch with you.Please provide your alternate contact so that our courier partner can reach you.

तुम्ही ऑर्डर करताना जो संपर्क क्रमांक दिला होता त्याच क्रमांकावर कुरियर पार्टनरने कॉल करावा का ? `;
        case 6:
          return `ऑर्डर डिलिव्हर झाली नाही: तुमचा पत्ता न सापडल्याने आमच्या कुरियर पार्टनरला तुमची ऑर्डर डिलिव्हर करणे शक्य झाले नाही.कृपया खुणेच्या जागेसोबत तुमचा संपूर्ण पत्ता खाली लिहा: `;
        case 7:
          return `Order Undelivered: Our courier partner was unable to deliver your order since the payment was not ready at the time of delivery. Don't worry, we will ask them to make another attempt at your preferred date for delivery.In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `ऑर्डर डिलिव्हर झाली नाही: तुमचा पत्ता न सापडल्याने आमच्या कुरियर पार्टनरला तुमची ऑर्डर डिलिव्हर करणे शक्य झाले नाही.कृपया खुणेच्या जागेसोबत तुमचा संपूर्ण पत्ता खाली लिहा.`;
        case 10:
          return `ऑर्डर डिलिव्हर झाली नाही: आमच्या कुरियर पार्टनरने आम्हाला सांगितले की तुम्ही त्यांच्याकडून तुमची शिपमेंट घेऊन जाणार आहात.असे नसल्यास,
  कृपया डिलिव्हरीसाठी तुमच्या सोयीची तारीख सांगा.तुमची काही समस्या असल्यास,
    खालील कॉमेंट सेक्शनमध्ये त्याबद्दल लिहा: `;
        case 11:
          return `डिलिव्हरीदरम्यान तुम्हाला काही समस्या जाणवली का ? कृपया तुमची प्रतिक्रिया खाली लिहा: `;
        case 12:
          return `ऑर्डर डिलिव्हर झाली नाही: आमच्या कुरियर पार्टनरने आम्हाला सांगितले की तुम्ही ओपन डिलिव्हरीची विनंती केली आहे.कृपया तुमच्या सोयीची डिलिव्हरीची तारीख सांगा.तुमची काही समस्या असल्यास खालील कॉमेंट सेक्शनमध्ये ती लिहा आणि तुमच्या प्रतिक्रिया आम्ही आमच्या टीमपर्यंत पोहोचवू.`;
        case 13:
          return `ऑर्डर डिलिव्हर झाली नाही: दुर्दैवाने आमच्या कुरियर पार्टनरने तुमची शिपमेंट चुकीच्या ठिकाणी पाठवली.तुम्हाला लवकरात लवकर डिलिव्हरी मिळावी म्हणून आम्ही सर्वतोपरी प्रयत्न करत आहोत.कृपया डिलिव्हरीसाठी तुमच्या सोयीची तारीख सांगा.तुम्हाला तुमची प्रतिक्रिया कळवायची असल्यास,
  कृपया खालील कॉमेंट सेक्शनमध्ये ती लिहा: `;
        case 0:
        default:
          return `ऑर्डर डिलिव्हर झाली नाही: तुमच्या शिपमेंटच्या बाबतीत काय समस्या आहे हे आम्ही तपासत आहोत.कृपया डिलिव्हरीसाठी तुमच्या सोयीची तारीख सांगा.तुमची काही समस्या असल्यास ती खालील कॉमेंट सेक्शनमध्ये लिहा: `;
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'कुरियर पार्टनरने आम्हाला सांगितले की त्यांनी तुमच्या पत्त्यावर पोहोचण्याचा प्रयत्न केला पण त्यांना तुमचा पत्ता सापडला नाही. तुम्ही तुमचा पत्ता अधिक तपशिलात सांगू शकता का?',
      timing: {
        a:
          'आमचे कुरियर पार्टनर्स डिलिव्हरी करण्याचा ३ वेळा प्रयत्न करतात. पहिल्या वेळी पॅकेज डिलिव्हर न झाल्यास आम्ही रविवार आणि सार्वजनिक सुट्ट्या सोडून पुढच्या दिवशी पुन्हा डिलिव्हरी करण्याचा प्रयत्न करतो.',
        b:
          'तुम्ही डिलिव्हरीसाठी दिलेल्या पत्त्यावर तुमचे पॅकेज स्वीकारण्यासाठी कोणीतरी असेल याची कृपया खात्री करून घ्या.',
        c: 'तुम्हाला काही विशिष्ट दिवशी डिलिव्हरी हवी आहे का?'
      },
      other: () =>
        'कृपया आम्हाला ही ऑर्डर डिलिव्हर करण्यात मदत करतील असे आणखी काही तपशील पुरवा.',
      phone: {
        a:
          'काही वेळा, कुरियर पार्टनर्स लॅण्डलाइनवरून कॉल करतात जो तुम्हाला स्पॅम वाटू शकतो.आम्ही कुरियर पार्टनरला पुन्हा डिलिव्हरी करण्यास सांगू.',
        b: () =>
          'Do you want the courier partner to call you on the same number as given during order placement?'
      },
      reject:
        'तुमच्या शिपमेंटच्या डिलिव्हरीचा आणखी एक प्रयत्न आम्ही करावा अशी तुमची इच्छा असल्यास कृपया तसे निश्चित सांगा: '
    },
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `पिकअप झाले नाही: तुमच्याशी संपर्क साधता न आल्याने आमच्या कुरियर पार्टनरला ऑर्डर पिकअप करणे शक्य झाले नाही.कृपया तुमचा संपर्कसाठीचा नंबर किंवा पत्ता सांगा`;
        case 2:
          return `पिकअप झाले नाही: आमच्या कुरियर पार्टनरने आम्हाला सांगितले की तुमचे पॅकेज पिकअप करता आले नाही कारण तुम्ही पिकअपची विनंती रद्द केलीत.पिकअप करायचे असल्यास 'हो' वर क्लिक करा,
  आणि पिकअप रद्द करायचे असल्यास 'नाही' वर क्लिक करा.`;
        case 3:
        case '3a':
          return `प्रॉडक्ट खराब होते किंवा वापरलेले होते`;
        case 4:
          return `प्रॉडक्टचा रंग किंवा ब्रँड वेगळा आहे`;
        case 5:
        case '5a':
          return `मागवलेल्या वस्तूंच्या संख्येपेक्षा मिळालेल्या वस्तूंची एकूण संख्या वेगळी आहे`;
        case 6:
          return `पिकअप झाले नाही: कृपया पिकअपसाठी तुमच्या सोयीची तारीख सांगा.`;
        case 7:
          return `पिकअप झाले नाही: तुमचा पत्ता आमच्या कुरियर सेवेच्या कक्षेबाहेरचा असल्याने आमची कुरियर कंपनी पिकअप करू शकली नाही.`;
        case 8:
        case '8a':
          return 'आमच्या कुरियरने सांगितले की पिकअप करण्याचे अनेक प्रयत्न करण्यात आले पण ते यशस्वी झाले नाहीत म्हणून कृपया तुमच्या उपलब्धतेनुसार पिकअपसाठी तुम्हाला सोयीची असलेली तारीख सांगा, तुमची काही समस्या असल्यास खाली कॉमेंट सेक्शनमध्ये ती लिहा ';
        case 9:
          return 'तुमच्या वस्तू / वस्तूंचे कुरियरला पिकअप करता आले नाही, कृपया वस्तू हाताशी ठेवा कारण पुढच्या कामकाजी दिवसामध्ये पिकअपचा पुन्हा प्रयत्न केला जाईल, तुम्हाला काही प्रतिक्रिया नोंदवायची असल्यास क्रृपाया ती कॉमेंट्स सेक्शनमध्ये लिहा';
        case 10:
          return 'आमच्या कुरियर पार्टनरने कळवले की तुम्ही पिकअपची विनंती नाकारलीत, जर असे नसेल तर कृपया पिकअपसाठी तुम्हाला सोयीची पुढची तारीख सांगा.तुमची काही समस्या असल्यास ती खालील कॉमेंट्स सेक्शनमध्ये लिहा ';
        case 11:
          return 'आमच्या कुरियर पार्टनरने कळवले की प्रॉडक्ट पिकअपसाठी तयार ठेवले गेले नव्हते, जर असे नसेल तर कृपया पिकअपसाठी तुम्हाला सोयीची पुढची तारीख सांगा.तुमची काही समस्या असल्यास ती खाली कॉमेंट्स सेक्शनमध्ये लिहून आम्हाला कळवा ';
        case 13:
          return `आमच्या कुरिअर भागीदाराने आम्हाला कळवले की ते तुमचे पॅकेज उचलू शकले नाहीत कारण ते तुमचा पत्ता शोधण्यात अक्षम आहेत. <br /><br /> कृपया खाली कोणत्याही खुणांसह तुमचा पूर्ण पत्ता द्या.`;
        case 14:
          return `आमच्या वाहक भागीदाराने आम्हाला कळवले की ते तुमच्या पिकअप विनंतीवर प्रक्रिया करू शकत नाहीत कारण तुम्ही अनुपलब्ध होता. कृपया तुमची पसंतीची पिकअप तारीख निवडा.`;
        case 0:
        default:
          return `पिकअप झाले नाही: तुमच्या विनंतीमध्ये काय समस्या आहे हे आम्ही तपासत आहोत.कृपया तुम्हाला सोयीची पिकअपची तारीख सांगा.तुमची काही समस्या असल्यास ती खाली कॉमेंट्स सेक्शनमध्ये लिहा: `;
      }
    },
    nprFdForm: {
      address:
        'कुरियर पार्टनरने आम्हाला सांगितले की त्यांनी तुमच्या पत्त्यावर पोहोचण्याचा प्रयत्न केला पण त्यांना तुमचा पत्ता सापडला नाही. तुम्ही तुमचा पत्ता अधिक तपशिलात सांगू शकता का?',
      timing: {
        a:
          'आमचे कुरियर पार्टनर्स पिकअपसाठी ३ वेळा प्रयत्न करतात. पहिल्या वेळी पॅकेज पिकअप न झाल्यास, रविवार आणि सार्वजनिक सुट्ट्या सोडून पुढच्या दिवशी पिकअप करण्याचा पुन्हा प्रयत्न करतो',
        b:
          'पॅकेज स्वीकारण्यासाठी तुमच्या पिकअपसाठीच्या पत्त्यावर कोणीतरी उपस्थित असेल याची कृपया खात्री करून घ्या.',
        c: 'तुम्हाला विशिष्ट दिवशी पिकअप करून हवे आहे का?'
      },
      other:
        'ऑर्डर पिकअप करण्यासाठी मदत होईल असे काही आणखी तपशील असतील तर ते कृपया पुरवा, तसेच प्रॉडक्ट / संख्या यांची यात गफलत होऊ नये म्हणून तुमची रिटर्नसाठी केलेली विनंती पुन्हा एकदा पडताळून पाहा',
      phone: {
        a:
          'काही वेळा, कुरियर पार्टनर्स लॅण्डलाईनवरून कॉल करतात जे तुम्हाला स्पॅम वाटू शकते.आम्ही कुरियर पार्टनरला पिकअपचा पुन्हा प्रयत्न करण्यास सांगू.',
        b:
          'तुम्ही ऑर्डर करताना जो संपर्क क्रमांक दिला होता त्याच क्रमांकावर कुरियर पार्टनरने कॉल करावा का?'
      },
      reject:
        'तुम्हाला तुमच्या शिपमेंटचे पिकअप रद्द करायचे असल्यास कृपया आम्हाला तसे निश्चित सांगा: '
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `या ऑर्डरच्या पेमेंटसाठी वापरलेले संपूर्ण ${creditValue} क्रेडिट्स या ऑर्डरच्या संदर्भात तुमच्या स्टोअर अकाउंटमध्ये पुन्हा जमा केले जाईल`,
      totalAsCredit: creditValue =>
        `तुम्ही भरलेली रक्कम तुम्हाला स्टोअर ${
          creditValue > 0 ? 'उर्वरित' : ''
        } स्वरूपात परत हवी आहे का?`,
      totalAsCreditNew: 'कृपया आपला परतावा देय देण्याचे प्राधान्य मोड निवडा',
      prepaid: calculatedAmt =>
        `तुमच्या ट्रांझॅक्शनच्या मूळच्या स्वरूपात तुम्हाला रु. ${calculatedAmt} परत मिळतील.`,
      cod: calculatedAmt =>
        `कृपया तुमच्या बँक अकाउंटचा तपशील भरा. तुम्ही कॅशच्या स्वरूपात जे पैसे भरलेत (रु. ${calculatedAmt}) ते तुम्हाला खाली दिलेल्या बँक अकाउंटमध्ये परत मिळतील.`,
      refundTotalAsCredit: totalAmount =>
        `स्टोअर क्रेडिटमध्ये रक्कम परत केली: ${totalAmount}`,
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: () =>
        'आपली परतावा रक्कम आपल्या ब्रँड क्रेडिटमध्ये परत जमा केली जाईल',
      defaultPrepaid: (totalAmount, currency) =>
        `तुमची ${currency ??
          ''} ${totalAmount} ची परतावा रक्कम परत स्त्रोताकडे जमा केली जाईल`
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'Shipped',
    readyForDispatch: 'Ready For Dispatch',
    trackOrder: 'Track Order',
    deliveryFeedback: 'डिलिव्हरीबद्दल प्रतिक्रिया',
    raiseConcern: 'समस्येबाबत सांगा',
    expired: 'Expired',
    downloadApp: 'Download the app',
    allRightsReserved: 'All rights reserved',
    emailUs: 'Email us: ',
    callUs: 'Call us: ',
    poweredBy: 'Powered by ',
    login: 'Login',
    username: 'Username',
    psswrd: 'Password',
    returnRequestReceived: 'Return request received',
    returnPlaceDate: 'Return placed date',
    reason: 'Reason',
    anyOtherComment: 'Comment',
    addImage: 'Add Image',
    quantity: 'Quantity',
    back: 'Back',
    edit: 'Edit',
    returnSummary: 'Return Summary',
    returnStatus: 'Return Status',
    pickup: 'Pickup',
    selectReversePickupAddress: 'Select Reverse Pickup Address',
    selectOrAddAddress: 'Select/Add Pickup Address',
    phoneFormat: format =>
      `Please enter a phone number in the format ${format} without the Phone Code`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Request Pickup',
    selfShip: 'Self Ship',
    review: 'Review',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Pickup Date',
    orderStatus: 'Order Status',
    expectedPickupDate: 'Expected Pickup Date',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Approved',
    goHome: 'Go Home',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Update Pickup Address',
    fullName: 'Full Name',
    emailId: 'Email ID',
    city: 'City',
    area: 'Area',
    state: 'State',
    country: 'Country',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    bankDetail: 'Bank Details For Refund',
    upiDetail: 'UPI Details',
    brandCredits: () => 'ब्रँड क्रेडिट',
    backToSource: 'स्त्रोताकडे परत',
    bankAccount: 'बँक खाते',
    accountHolderName: 'Account Holder Name',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Account Number',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Fetch',
    enterReversePickupAddress: 'Enter Reverse Pickup Address',
    pleaseUploadSupportingImage: imageQuantity =>
      `Please upload ${imageQuantity} supporting image`,
    processing: 'Processing',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} images required for this request`,
    returns: 'Returns',
    exchange: () => 'Return and Exchange',
    enterYourOrderInformation: 'Enter your order information to get started.',
    ourReturnExchangePolicy: () => 'Our Returns & Exchange Policy',
    refundPolicy: 'Refund Policy',
    termsConditions: 'Terms & Conditions',
    stepOf: currentStep => `Step ${currentStep} of 3`,
    allPriceShownPerItem: 'All prices shown are at item level',
    qtyPerOrderNote: 'Note: Qty shows original ordered item quantity',
    qty: 'Qty',
    returnedQty: 'Returned quantity',
    placeFreshPickupRequest: 'Pickup cancelled, place fresh pickup request.',
    remove: 'Remove',
    return: 'Return',
    startReturn: 'Start Return',
    whyAreYouReturning: 'Why are you returning the product?', // optional
    whyYouWantReturn: 'Why do you want to return?',
    selfShipMode:
      'As you selected Self Ship Mode. Please pack the items securely to prevent any loss or damage during transit.',
    requestPlacedDate: 'Request Placed Date',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'लवकरच शिपिंग',
    max200Chars: 'Max. 200 characters',
    select: 'Select',
    charsLeft: chars => `${chars} characters left`
  },
  bn: {
    actionInProgress: 'কাজ চলছে..',
    address: 'ঠিকানা',
    addressCharLimit: 'কমপক্ষে 10 টি অক্ষর লিখুন এবং 600 এর বেশি নয়।',
    addressErr:
      'ঠিকানা লেখার স্থানটি শূন্য বা 10 টির চেয়ে কম ক্যারেক্টরের হবে না।',
    agentDidntCall: 'ডেলিভারি এজেন্ট আমাকে ফোন করেনি',
    anyOtherDate: 'অন্য কোন তারিখ?',
    cancelled: 'বাতিল হয়েছে',
    checkCurrentStatus: 'আপনার অর্ডারের বর্তমান অবস্থা দেখে নিন।',
    trackYourOrder: 'Track Your Order',
    chooseOption: 'যে কোনও বিকল্প বেছে নিন।',
    commentErr: 'মন্তব্য ফাঁকা হতে পারে না।',
    comments: 'মন্তব্য',
    confirmed: 'নিশ্চিত করা হয়েছে',
    confirm: 'Confirm',
    requestReturn: 'রিটার্নের অনুরোধ করুন',
    contactNumber: 'যোগাযোগের নম্বর',
    courier: 'কুরিয়ার',
    damaged: 'ক্ষতিগ্রস্থ',
    dateErr: 'দয়া করে একটি বৈধ তারিখ লিখুন।',
    delivered: 'ডেলিভারি হয়ে গেছে',
    dispatched: 'পাঠানো হয়েছে',
    doNotWantProduct: 'পণ্যটি আর চাই না',
    emailidErr: 'দয়া করে একটি বৈধ ইমেল আইডি লিখুন।',
    invalidImageErr: 'ফাইল বিন্যাস সমর্থিত নয়',
    excellent: 'দুর্দান্ত',
    expectedDelivery: 'প্রত্যাশিত ডেলিভারি',
    failedDelivery: 'ডেলিভারি ব্যর্থ হয়েছে',
    feedbackToastr1:
      'আপনার প্রতিক্রিয়ার জন্য ধন্যবাদ! আমরা আবার আপনার সেবা করার সুযোগ পেলে খুশি হব।',
    feedbackToastr2:
      'সাহায্য করার জন্য ধন্যবাদ! আমরা আপনাকে পরের বার আরও ভাল অভিজ্ঞতা দেওয়ার চেষ্টা করব!',
    abfrlFeedbackToast:
      'প্রতিক্রিয়ার জন্য আপনাকে ধন্যবাদ, আপনাকে সমীক্ষায় পুনঃনির্দেশ করা হচ্ছে',
    inTransit: 'মাঝরাস্তায়',
    incorrectSearch:
      'আপনি যে অর্ডার / চালানটি ট্র্যাক করার চেষ্টা করছেন সেটি এখনও পাঠানো হয়নি বা অর্ডার নম্বরটি ভুল।',
    inputFeedback: 'আপনার মতামত শেয়ার করুন',
    landmark: 'পথচিহ্ন',
    landmarkErr:
      'ল্যান্ডমার্কটি ফাঁকা বা 5 টির চেয়ে কম ক্যারেক্টারের হতে পারে না।',
    langName: 'ইংরেজি',
    rateUs: 'How would you rate for:',
    lastUpdate: 'সর্বশেষ আপডেট',
    location: 'অবস্থান',
    lost: 'নিখোঁজ',
    myOrders: 'পদক্ষেপ গ্রহণ করুন',
    myOrdersTooltip: 'একটি সমস্যা জানাতে এখানে ক্লিক করুন',
    next: 'পরবর্তী',
    no: 'না',
    noAttemptToAddress: 'ডেলিভারির ঠিকানায় পৌঁছানোর কোনও চেষ্টা করা হয়নি',
    noDataFound:
      'আপনি যে অর্ডার / চালান ট্র্যাক করার চেষ্টা করছেন সেটি এখনও পাঠানো হয়নি বা অর্ডার নম্বরটি ভুল।',
    noScan1: 'পাঠানোর জন্য প্রস্তুত',
    noScan2: 'ট্র্যাকিং সম্পর্কে বিশদ তথ্য শীঘ্রই উপলব্ধ হবে।',
    ofd: 'ডেলিভারির জন্য বেরিয়ে গেছে',
    ofp: 'পিকআপের জন্য বেরিয়ে গেছে',
    ops404: 'হায়! এটি 404',
    on: 'on',
    orderDetails: 'অর্ডারের বিস্তারিত তথ্য',
    orderId: 'অর্ডার আইডি',
    orderPlaced: 'অর্ডার করা হয়ে গেছে',
    orderPlacedOn: 'অর্ডারের দিন',
    orderType: 'অর্ডার টাইপ',
    riderInfo: 'রাইডার তথ্য',
    pageNotFound: 'পৃষ্ঠাটি খুঁজে পাওয়া যায়নি',
    phoneErr: 'দয়া করে একটি 10 ​​সংখ্যার বৈধ ফোন নম্বর লিখুন।',
    pickedup: 'পিকআপ হয়ে গেছে',
    pickupCancelled: 'পিকআপ বাতিল হয়েছে',
    pickupFailed: 'পিকআপ ব্যর্থ',
    pickupRequested: 'পিকআপ অনুরোধ করা হয়েছে',
    updatedOn: 'আপডেট হয়েছে',
    returnInstructions: 'পুনরায় চেষ্টা নির্দেশাবলী',
    pincode: 'পিনকোড',
    pincodeErr: 'দয়া করে 6 ডিজিটের পিনকোড দিন',
    pincodeNotServiceable:
      'পিনকোড পরিষেবাযোগ্য নয়, দয়া করে আমাদের কাছে অর্ডারটি শিপ করুন।',
    pleaseTellUs: 'দয়া করে বলুন কি হয়েছে?',
    preferredDate: 'পছন্দসই ডেলিভারির তারিখ',
    pressNextBtn: 'নির্বাচনের পরে নীচে "পরবর্তী" বাটন টিপুন।',
    provideAltNo: 'দয়া করে একটি বিকল্প নম্বর সরবরাহ দিন',
    provideLandmark:
      'আপনার ঠিকানা সনাক্ত করতে কুরিয়ার পার্টনারকে একটি পথচিহ্ন দিন।',
    ratingErr: 'রেটিং খালি থাকতে পারে না',
    apiErr: 'আমরা অনুরোধ প্রক্রিয়া করতে অক্ষম, পরে আবার চেষ্টা করুন',
    feedbackMatters: 'আপনার প্রতিক্রিয়া গুরুত্বপূর্ণ!',
    ratingFeedback: 'আপনার মূল্যবান মতামতের জন্য আপনাকে ধন্যবাদ!',
    youRatedUs: 'আপনি আমাদের রেট',
    youCommented: 'আপনি মন্তব্য করেছেন',
    reachedW: 'গুদামে পৌঁছে গেছে',
    reverseReturned: 'ফিরে এসেছে',
    readyToShip: 'পাঠানোোর জন্য প্রস্তুত',
    remark: 'মন্তব্য',
    shippingLabel: 'Shipping Label',
    shippingLabelInProgress:
      'Fetching the label from the carrier, it will be available for download in a few minutes',
    shippingLabelReady:
      'Your shipping label has been generated, now you can download it',
    requestCancelled: 'অনুরোধ বাতিল হয়েছে',
    requestRejected: 'Request Rejected',
    requestPlaced: 'অনুরোধ করা হয়েছে',
    requestAccepted: 'Request Accepted',
    requestAcceptedWithDate: date => `Your return has been accepted on ${date}`,
    returned: 'ফিরে এসেছে',
    searchBy: 'অনুসন্ধান করুন',
    searchByTrackingId: 'ট্র্যাকিং আইডি / এডাব্লুবি',
    searchByOrderId: 'অর্ডার আইডি / নম্বর',
    share: 'শেয়ার করুন',
    status: 'স্থিতি',
    submit: 'জমা দিন',
    takeAction: 'পদক্ষেপ নিন',
    time: 'সময়',
    trackingId: 'ট্র্যাকিং আইডি',
    updateContact: 'যোগাযোগের তথ্য আপডেট করুন',
    updateContactNumber: 'যোগাযোগের নম্বর আপডেট করুন',
    updateContactNumberToastr:
      'এটি আমাদের সাথে যোগাযোগ করতে এবং চালানের বিষয়ে গুরুত্বপূর্ণ আপডেটগুলি প্রেরণ করতে দেয়।',
    updateContactToastr:
      'আপডেটের জন্য ধন্যবাদ! আমরা আপডেট হওয়া যোগাযোগের নম্বরে নোটিফিকেশন পাঠানো শুরু করব।',
    updateContactTooltip: 'যোগাযোগের নম্বর পরিবর্তন করতে এখানে ক্লিক করুন',
    veryPoor: 'খুব খারাপ',
    veryPoorAbfrl: 'প্রস্তাব সম্ভবত',
    excellentAbfrl: 'সম্ভবত সুপারিশ',
    veryUnlikely: 'Very Unlikely',
    veryLikely: 'Very Likely',
    viewDetail: 'বিস্তারিত দেখুন',
    viewSummary: 'সংক্ষিপ্তসার দেখুন',
    viewTracking: 'ট্র্যাকিং দেখুন',
    wasNotAvailable:
      'যখন ডেলিভারির চেষ্টা করা হয়েছিল তখন আমি উপস্থিত ছিলাম না',
    yes: 'হ্যাঁ',
    youFacedAnyIssue: 'আপনি কি অন্য কোনও সমস্যার মুখোমুখি হয়েছিলেন?',
    youFoundIncorrectLink: 'আপনি একটি ভুল লিঙ্ক পেয়েছেন বলে মনে হচ্ছে।',
    orderPlaceOnDateFn: date => `${date} -এ অর্ডার করা হয়েছে`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    submittedConcern: courier =>
      `আমরা আপনার অনুরোধটি কার্যকরী করতে আমরা ${courier} এর সঙ্গে ফলোআপ করব।`,
    askRatingFeedback: courier =>
      `${courier} সঙ্গে আপনার ডেলিভারির অভিজ্ঞতা কেমন ছিল ? `,
    askRatingFeedbackArvind: 'NNNOW এর সাথে আপনার কেনাকাটার অভিজ্ঞতা কেমন ছিল?',
    askRatingFeedbackAbfrl: brandName =>
      `এই বিতরণের অভিজ্ঞতার ভিত্তিতে 0-10 স্কেলের উপর নির্ভর করে আপনি কতটা সুপারিশ করবেন ${brandName} একজন বন্ধুর প্রতি?    `,
    askRatingFeedbackTrell:
      'Based on your recent delivery experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience: 'আপনার সামগ্রিক রিটার্ন অভিজ্ঞতা কেমন ছিল?',
    askNonPickedExperience:
      'আমরা লক্ষ্য করেছি যে আপনার চালান তোলা যায়নি। আপনার সামগ্রিক রিটার্ন অভিজ্ঞতা কেমন ছিল?',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (title, websiteUrl) =>
      `সঠিক ট্র্যাকিং লিঙ্ক এবং আপনার অর্ডার সম্পর্কে তথ্যের জন্য দয়া করে  ${websiteUrl} -এ  ${title} -এ যোগাযোগ করুন।`,
    statusOnDateFn: (status, date) => `${date} এ ${status} `,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'কি অসুবিধা হয়েছে দয়া করে আমাদের বলুন!';
        case 6:
        case 7:
        case 8:
          return 'আমরা কিভাবে পরিষেবা উন্নত করতে পারি?';
        default:
          return 'প্রশংসা করুন!';
      }
    },
    edd: (expression, message) => {
      switch (expression) {
        case 1:
          return message;
        case 2:
          return 'শীঘ্রই পৌঁছাচ্ছে:  আপনার কাছাকাছি পৌঁছে গেছে';
        case 3:
          return 'চালান পৌঁছাতে দেরি:  কুরিয়ার পার্টনার জানিয়েছেন যে শিপমেন্টটি শীঘ্রই আপনাকে ডেলিভার করা হবে';
        case 4:
          return 'প্রত্যাশা অনুযায়ী চলছে';
        case 5:
          return 'চালান পৌঁছাতে দেরি:  লজিস্টিক টিমকে জানানো হয়েছে';
        case 7:
          return 'আপনার পছন্দের ভিত্তিতে পরবর্তী 24-48 ঘন্টায় পুনরায় চেষ্টা করা হবে';
        case 8:
          return message;
        case 9:
          return 'চালান একই দিন একবার প্রেরণ করা হবে';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `অর্ডার ডেলিভার হয়নি: আমাদের কুরিয়ার পার্টনার আপনার সঙ্গে যোগাযোগ করতে না পারায় তারা আপনার অর্ডার ডেলিভার করতে পারেননি। পরবর্তী ডেলিভারির জন্য দয়া করে আপনার পছন্দসই তারিখ দিন: `;
        case 2:
        case 9:
        case 14:
          return `অর্ডার ডেলিভার হয়নি: আমাদের কুরিয়ার পার্টনার আমাদের জানিয়েছেন যে ডেলিভারি প্রত্যাখ্যান করার অনুরোধ অনুযায়ী আপনার প্যাকেজটি ডেলিভার করা যাবে না।
        
        অর্ডারটির ডেলিভারি পেতে 'হ্যাঁ' এ ক্লিক করুন,
          অর্ডার বাতিল করার জন্য 'না' - এ।`;
        case 3:
          return `অর্ডার ডেলিভার হয়নি: আপনি সময় পুনঃনির্ধারণের অনুরোধ করার কারণে আমাদের কুরিয়ার পার্টনার আপনার অর্ডার ডেলিভার করতে পারছেন না। আমরা পুনরায় এসে ডেলিভার করে দেব! আপনি যদি আমাদের আপনার পছন্দসই ডেলিভারির তাারিখ জানাতে চান তবে নীচে তথ্যটি জানান: `;
        case 4:
          return `অর্ডার ডেলিভার হয়নি: আমাদের কুরিয়ার পার্টনার আজ ডেলিভারির চেষ্টা করতে পারেননি।
        
        ডেলিভারির জন্য আপনার পছন্দসই তারিখটি জানান: `;
        case 5:
          return `Order Undelivered: Our courier partner was unable to deliver your order since we could not get in touch with you.Please provide your alternate contact so that our courier partner can reach you.
        
        অর্ডার দেওয়ার সময় যে নম্বরটি দিয়েছিলেন,
          আপনি কি চান সেই একই নম্বরে আমাদের কুরিয়ার পার্টনার আপনাকে কল করুন ? `;
        case 6:
          return `অর্ডার ডেলিভার হয়নি: আপনার ঠিকানা সনাক্ত করতে না পারায় আমাদের কুরিয়ার পার্টনার অর্ডার ডেলিভার করতে পারেননি। নীচে ল্যান্ডমার্ক সহ আপনার সম্পূর্ণ ঠিকানা প্রদান করুন: `;
        case 7:
          return `Order Undelivered: Our courier partner was unable to deliver your order since the payment was not ready at the time of delivery. Don't worry, we will ask them to make another attempt at your preferred date for delivery.In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `অর্ডার ডেলিভার হয়নি: আপনার ঠিকানা সনাক্ত করতে না পারায় আপনার অর্ডারটি আমাদের ডেলিভারি পার্টনাররা পৌঁছাতে পারেননি। দয়া করে আপনার সম্পূর্ণ ঠিকানাটি পথচিহ্ন সহ নিচে দিন`;
        case 10:
          return `অর্ডার ডেলিভার হয়নি: আমাদের কুরিয়ার পার্টনার আমাদের জানিয়েছেন যে, আপনি নিজে তাঁদের কাছ থেকে শিপমেন্টটি সংগ্রহ করে নেবেন। যদি এই তথ্য সঠিক না হয়, তাহলে দয়া করে আপনার পছন্দসই ডেলিভারির তারিখ জানান। যদি আপনার কোনও সমস্যা হয়ে থাকে, দয়া করে কমেন্ট সেকশনে মন্তব্য করে আমাদের জানান।`;
        case 11:
          return `ডেলিভারির সময় আপনি কি কোনও সমস্যার মুখোমুখি হয়েছিলেন ? দয়া করে নীচে আপনার প্রতিক্রিয়া দিন: `;
        case 12:
          return `অর্ডার ডেলিভার হয়নি: আমাদের কুরিয়ার পার্টনাার আমাদের জানিয়েছেন যে,
              আপনি ওপেন ডেলিভারির জন্য অনুরোধ করেছেন। দয়া করে আপনার পছন্দসই ডেলিভারির তারিখ জানান। আপনার কোনও অসুবিধা থাকলে  নিচে কমেন্ট সেকশনে মন্তব্য করে আমাদের জানান। আপনার মন্তব্যটি আমারা সঠিক জায়গায় পৌঁছে দেব।`;
        case 13:
          return `অর্ডার ডেলিভার হয়নি: দুর্ভাগ্যবশত আমাদের কুরিয়ার পার্টনার ভুল লোকেশনে আপনার শিপমেন্টটি নিয়ে চলে গেছেন। আমরা সর্বোতভাবে চেষ্টা করছি যাতে যত দ্রুত সম্ভব আপনার কাছে এটি পৌঁছানো যায়। দয়া করে আপনি আপনার পছন্দসই তারিখটি জানান। আপনি কোনও মন্তব্য করতে চাইলে নিচে কমেন্ট সেকশনে লিখে জানান: `;
        case 0:
        default:
          return `অর্ডার ডেলিভার হয়নি: আপনার শিপমেন্টটির ক্ষেত্রে একটি অসুবিধা দেখা গিয়েছে। দয়া করে আপনার পছন্দসই ডেলিভারির তারিখ জানান। যদি আপনার কোনও অসুবিধা হয়ে থাকে,
                তাহলে সেটি নিচে কমেন্ট সেকশনে মন্তব্য করে জানান: : `;
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'আমাদের কুরিয়ার পার্টনার জানিয়েছেন যে, তাঁরা আপনার ঠিকানায় পৌঁছানোর চেষ্টা করেছিলেন, কিন্তু খুঁজে পেতে ব্যর্থ হয়েছেন। আপনি কি আপনার ঠিকানা সম্পর্কে আরও বিশদ তথ্য দিতে পারেন?',
      timing: {
        a:
          'আমাদের কুরিয়ার পার্টনাররা ৩ বার ডেলিভারির চেষ্টা করেন। এক্ষেত্রে, যদি প্রথমবার প্যাকেজ ডেলিভার না করা যায়, তাহলে রবিবার এবং অন্যান্য ছুটির দিন ছেড়ে পরবর্তী কাজের দিনে পুনরায় ডেলিভারির চেষ্টা করা হবে।',
        b:
          'দয়া করে নিশ্চিত করুন যাতে প্যাকেজ ডেলিভারির সময় ঠিকানায় কেউ না কেউ উপস্থিত থাকেন',
        c: 'আপনি কি নির্দিষ্ট কোনও দিনে ডেলিভারি চান?'
      },
      other: () =>
        'যদি এমন কোনও তথ্য থাকে যা আমাদের ডেলিভার করতে সুবিধা করবে, তাহলে দয়া করে তা জানান।',
      phone: {
        a:
          '.কয়েকটি ক্ষেত্রে, কুরিয়ার পার্টনাররা ল্যান্ডলাইন থেকে কল করেন, যেটি দেখে স্প্যাম মনে হতে পারে।  আমরা কুরিয়ার পার্টনারকে অনুরোধ করব যাতে তাঁরা পুনরায় ডেলিভারির চেষ্টা করেন।',
        b: () =>
          'অর্ডার দেওয়ার সময় যে নম্বরটি দিয়েছিলেন, আপনি কি চান সেই একই নম্বরে আমাদের কুরিয়ার পার্টনার আপনাকে কল করুন?'
      },
      reject:
        'আপনি যদি চান আপনার শিপমেন্টটি পুনরায় ডেলিভারির চেষ্টা করা হোক তাহলে নিশ্চিত করুন'
    },
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `পিকআপ ব্যর্থ হয়েছে: আপনার সঙ্গে যোগাযোগ করতে না পারায় আমাদের কুরিয়ার পার্টনার পিকআপ করতে ব্যর্থ হয়েছেন। দয়া করে আপনার যোগাযোগের নম্বর বা ঠিকানা জানান।`;
        case 2:
          return `পিকআপ ব্যর্থ হয়েছে: আমাদের কুরিয়ার পার্টনার জানিয়েছেন যে,
          আপনার পিকআপ বাতিলের অনুরোধের ভিত্তিতে আপনার প্যাকেজটি পিকআপ করা যায়নি।
        
        পিকআপ নিশ্চিত করতে 'হ্যাঁ' - তে ক্লিক করুন,
          বাতিল করার জন্য 'না'`;
        case 3:
        case '3a':
          return `সামগ্রীটি নষ্ট বা ব্যবহৃত ছিল`;
        case 4:
          return `সামগ্রীর রঙ বা ব্র্যান্ড ভিন্ন ছিল`;
        case 5:
        case '5a':
          return `সামগ্রীর সংখ্যা অনুরোধের তুলনায় ভিন্ন ছিল`;
        case 6:
          return `পিকআপ ব্যর্থ হয়েছে: আপনার পছন্দসই পিকআপের তারিখটি জানান`;
        case 7:
          return `পিকআপ ব্যর্থ হয়েছে: আপনার ঠিকানাটি আমাদের কুরিয়ার পরিষেবার বাইরে থাকায়  আমাদের কুরিয়ার সংস্থা পিকআপ করতে ব্যর্থ হয়েছে`;
        case 8:
        case '8a':
          return 'আমাদের কুরিয়ার পার্টনার জানিয়েছেন যে একাধিকবার একই প্যাকেজের ক্ষেত্রে চেষ্টা করা সত্ত্বেও পিকআপটি সম্পন্ন করা যায়নি, তাই দয়া করে পিকআপের জন্ য আপনার পছন্ দসই তারিখ জানান য়খন আপনি উপস্ থিত থাকতে পারবেন। যদি আপনার কো নও অসুবিধা হয়ে থাকে, তাহলে সেটি নিচে কমেন্ ট সেকশনে মন্ তব্ য করে জানান:';
        case 9:
          return 'কুরিয়ার আপনার সামগ্ রীটি(সামগ্ রীগুলি) পিকআপ করতে পারেনি, দয়া করে সামগ্ রীটি(সামগ্ রীগুলি) প্ রস্ তুত রাখুন কারন পরবর্ তী কাজের দিনে সেটি পিকআপ করার চেষ্ টা করা হবে। যদি আপনি কো নও মন্ তব্ য করতে চান, তাহলে দয়া করে কমেন্ ট সেকশনে সেটি লিখুন';
        case 10:
          return 'আমাদের কুরিয়ার পার্ টনার জানিয়েছেন যে আপনি পিকআপের অনুরো ধ অস্ বীকার করেছেন। যদি এই তথ্ য সঠিক না নয়, তাহলে দয়া করে আপনার পছন্ দ মতো পরবর্ তী পিকআপের তারিখটি জানান। যদি আপনার কো নও অসুবিধা হয়ে থাকে, তাহলে সেটি নিচে কমেন্ ট সেকশনে মন্ তব্ য করে জানান:';
        case 11:
          return 'আমাদের কুরিয়ার পার্ টনার জানিয়েছেন যে অর্ ডার করা বস্ তুটি পিকআপের জন্ য প্ রস্ তুতি ছিল না। যদি এই তথ্ য সঠিক না নয়, তাহলে দয়া করে আপনার পছন্ দ মতো পরবর্ তী পিকআপের তারিখটি জানান। যদি আপনার কো নও অসুবিধা হয়ে থাকে, তাহলে সেটি নিচে কমেন্ ট সেকশনে মন্ তব্ য করে জানান:';
        case 13:
          return `আমাদের কুরিয়ার পার্টনার আমাদের জানান যে তারা আপনার প্যাকেজ নিতে পারেনি কারণ তারা আপনার ঠিকানা খুঁজে পেতে পারেনি। <br /><br /> নিচে যেকোন ল্যান্ডমার্ক সহ আপনার সম্পূর্ণ ঠিকানা দিন।`;
        case 14:
          return `আমাদের ক্যারিয়ার পার্টনার আমাদের জানিয়েছেন যে আপনি অনুপলব্ধ থাকায় তারা আপনার পিকআপের অনুরোধ প্রক্রিয়া করতে পারেনি। আপনার পছন্দের পিক আপ তারিখ নির্বাচন করুন.`;
        case 0:
        default:
          return `পিকআপ ব্যর্থ হয়েছে: আমরা আপনার অনুরোধ অনুযায়ী আপনার সমস্যাটি সনাক্ত করছি। দয়া করে আপনার পছন্দসই পিকআপের তারিখ জানান। যদি আপনার কোনও অসুবিধা হয়ে থাকে,
          তাহলে সেটি নিচে কমেন্ট সেকশনে মন্তব্য করে জানান: `;
      }
    },
    nprFdForm: {
      address:
        'আমাদের কুরিয়ার পার্টনার জানিয়েছেন যে, তাঁরা আপনার ঠিকানায় পৌঁছানোর চেষ্টা করেছিলেন, কিন্তু খুঁজে পেতে ব্যর্থ হয়েছেন। আপনি কি আপনার ঠিকানা সম্পর্কে আরও বিশদ তথ্য দিতে পারেন?',
      timing: {
        a:
          'আমাদের কুরিয়ার পার্টনাররা পিকআপের জন্য ৩ বার চেষ্টা করে থাকেন। যদি এমন হয়, যে সংশ্লিষ্ট প্যাকেজটি প্রথমবার পিকআপ করতে তাঁরা ব্যর্থ হন, তাহলে পরের দিন যদি রবিবার বা অন্যান্য ছুটির দিন না হয়, তাহলে পুনরায় চেষ্টা করেন',
        b:
          'দয়া করে নিশ্চিত করুন যে প্যাকেজ হস্তান্তরের সময় পিকআপের ঠিকানায় কেউ না কেউ উপস্থিত থাকবেন',
        c: 'আপনি কি কোনও বিশেষ তারিখে পিকআপ চান?'
      },
      other:
        'আপনার অর্ডার পিকআপ করার সুবিধার জন্য দয়া করে কোনও অতিরিক্ত তথ্য থাকলে দিন। পাশাপাশি, আপনার ফেরতের অনুরোধও খতিয়ে দেখে নিন, কোনও জিনিস/ পরিমাণের পার্থক্য রয়েছে কি না',
      phone: {
        a:
          'কিছু কিছু ক্ষেত্রে , কুরিয়ার পার্টনাররা ল্যান্ডলাইন থেকে কল করে থাকেন, যেগুলি আপনার কাছে স্প্যাম বলে মনে হতে পারে। আমরা আমাদের কুরিয়ার পার্টনারকে পুনরায় পিকআপ করার চেষ্টা করতে অনুরোধ করব।',
        b:
          'আপনি কি চান আমাদের কুরিয়ার পার্টনার ডেলিভারির সময় এই একই নম্বরে আপনাকে কল করবেন?'
      },
      reject:
        'আপনি যদি চান আমরা আপনার শিপমেন্টের পিকআপ বাতিল করি তাহলে দয়া করে নিশ্চিত করুন: '
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `এই অর্ডারের জন্য প্রদান করা অর্থের জন্য ক্রেডিট করা মোট ${creditValue}  পুনরায় আপনার স্টোর অ্যামাউন্টে ক্রেডিট করা হবে এই অর্ডারের নিরিখে`,
      totalAsCredit: creditValue =>
        `আপনি স্টোর ক্রেডিটে প্রদান করা ${
          creditValue > 0 ? 'বাকি' : ''
        } এর জন্য অর্থ ফেরত দিতে চান?`,
      totalAsCreditNew: 'আপনার পছন্দের ফেরত প্রদানের মোডটি বেছে নিন',
      prepaid: calculatedAmt =>
        `আপনার যথার্থ অনলাইন ট্রানজাকশন মোডে আপনি Rs. ${calculatedAmt} পাবেন`,
      cod: calculatedAmt =>
        `দয়া করে আপনার ব্যাঙ্ক অ্যাকাউন্ট সংক্রান্ত তথ্য দিন। আপনি প্রদান করা অর্থ(Rs. ${calculatedAmt})  নিচের ব্যাঙ্ক অ্যাকাউন্টে পাবেন`,
      refundTotalAsCredit: totalAmount => `স্টোর ক্রেডিটে ফেরত: ${totalAmount}`,
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: () =>
        'আপনার অর্থ ফেরতের পরিমাণ আপনার ব্র্যান্ড ক্রেডিট ফিরে জমা করা হবে',
      defaultPrepaid: (totalAmount, currency) =>
        `আপনার ${currency ?? ''} ${totalAmount} ফেরতের পরিমাণ উৎসে ফেরত জমা হবে`
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'Shipped',
    readyForDispatch: 'Ready For Dispatch',
    trackOrder: 'Track Order',
    deliveryFeedback: 'ডেলিভারি ফিডব্যাক',
    raiseConcern: 'অসুবিধা জানান',
    expired: 'Expired',
    downloadApp: 'Download the app',
    allRightsReserved: 'All rights reserved',
    emailUs: 'Email us: ',
    callUs: 'Call us: ',
    poweredBy: 'Powered by ',
    login: 'Login',
    username: 'Username',
    psswrd: 'Password',
    returnRequestReceived: 'Return request received',
    returnPlaceDate: 'Return placed date',
    reason: 'Reason',
    anyOtherComment: 'Comment',
    addImage: 'Add Image',
    quantity: 'Quantity',
    back: 'Back',
    edit: 'Edit',
    returnSummary: 'Return Summary',
    returnStatus: 'Return Status',
    pickup: 'Pickup',
    selectReversePickupAddress: 'Select Reverse Pickup Address',
    selectOrAddAddress: 'Select/Add Pickup Address',
    phoneFormat: format =>
      `Please enter a phone number in the format ${format} without the Phone Code`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Request Pickup',
    selfShip: 'Self Ship',
    review: 'Review',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Pickup Date',
    orderStatus: 'Order Status',
    expectedPickupDate: 'Expected Pickup Date',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Approved',
    goHome: 'Go Home',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Update Pickup Address',
    fullName: 'Full Name',
    emailId: 'Email ID',
    city: 'City',
    area: 'Area',
    state: 'State',
    country: 'Country',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    bankDetail: 'Bank Details For Refund',
    upiDetail: 'UPI Details',
    brandCredits: () => 'ব্র্যান্ড ক্রেডিট',
    backToSource: 'উত্স ফিরে',
    bankAccount: 'ব্যাংক হিসাব',
    accountHolderName: 'Account Holder Name',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Account Number',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Fetch',
    enterReversePickupAddress: 'Enter Reverse Pickup Address',
    pleaseUploadSupportingImage: imageQuantity =>
      `Please upload ${imageQuantity} supporting image`,
    processing: 'Processing',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} images required for this request`,
    returns: 'Returns',
    exchange: () => 'Return and Exchange',
    enterYourOrderInformation: 'Enter your order information to get started.',
    ourReturnExchangePolicy: () => 'Our Returns & Exchange Policy',
    refundPolicy: 'Refund Policy',
    termsConditions: 'Terms & Conditions',
    stepOf: currentStep => `Step ${currentStep}  of 3`,
    allPriceShownPerItem: 'All prices shown are at item level',
    qtyPerOrderNote: 'Note: Qty shows original ordered item quantity',
    qty: 'Qty',
    returnedQty: 'Returned quantity',
    placeFreshPickupRequest: 'Pickup cancelled, place fresh pickup request.',
    remove: 'Remove',
    return: 'Return',
    startReturn: 'Start Return',
    whyAreYouReturning: 'Why are you returning the product?', // optional
    whyYouWantReturn: 'Why do you want to return?',
    selfShipMode:
      'As you selected Self Ship Mode. Please pack the items securely to prevent any loss or damage during transit.',
    requestPlacedDate: 'Request Placed Date',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'শীঘ্রই জাহাজীকরণ',
    max200Chars: 'Max. 200 characters',
    select: 'Select',
    charsLeft: chars => `${chars} characters left`
  },
  gu: {
    actionInProgress: 'કાર્યવાહી ચાલુ છે..',
    address: 'સરનામું',
    addressCharLimit:
      'કૃપા કરીને ઓછામાં ઓછા 10 અક્ષરો દાખલ કરો પરંતુ 600 કરતા વધુ નહીં.',
    addressErr: 'સરનામું ૧૦ અક્ષરથી નાનું અથવા ખાલી ન હોઈ શકે',
    agentDidntCall: 'ડિલિવરી એજન્ટે મને સંપર્ક ન કર્યો',
    anyOtherDate: 'બીજી કોઈ તારીખ?',
    cancelled: 'રદ કર્યું',
    checkCurrentStatus: 'તમારા શિપમેન્ટની વર્તમાન સ્થિતિ તપાસો.',
    trackYourOrder: 'Track Your Order',
    chooseOption: 'કૃપા કરીને કોઈપણ વિકલ્પ પસંદ કરો.',
    commentErr: 'ટિપ્પણી ખાલી હોઈ શકતી નથી.',
    comments: 'ટિપ્પણીઓ',
    confirmed: 'પુષ્ટિ કરી',
    confirm: 'Confirm',
    requestReturn: 'પરત કરવાની વિનંતી કરો',
    contactNumber: 'સંપર્ક નંબર',
    courier: 'કુરિયર',
    damaged: 'નુકસાન',
    dateErr: 'કૃપા કરીને કોઈ પ્રમાણિત તારીખ દાખલ કરો.',
    delivered: 'ડિલિવરી થઇ ચુકી છે',
    dispatched: 'રવાના થઇ ગયું છે',
    doNotWantProduct: 'સામાન નથી જોઈતો',
    emailidErr: 'કૃપા કરી માન્ય ઇમેઇલ આઈડી દાખલ કરો.',
    invalidImageErr: 'ફાઇલ ફોર્મેટ સપોર્ટેડ નથી',
    excellent: 'ઉત્તમ',
    expectedDelivery: 'અપેક્ષિત ડિલિવરી',
    failedDelivery: 'ડિલિવરી નિષ્ફળ રહી',
    feedbackToastr1:
      'તમારા પ્રતિસાદ બદલ આભાર! તમને ફરીથી સેવા આપવા માટે અમે તત્પર રહીશું..',
    feedbackToastr2:
      'પ્રતિસાદ બદલ આભાર! અમે તમને ભવિષ્યમાં વધુ સારો અનુભવ પ્રદાન કરવા પર કામ કરીશું!',
    abfrlFeedbackToast: 'તમને સર્વેક્ષણ પર રીડાયરેક્ટ કરીને પ્રતિસાદ બદલ આભાર',
    inTransit: 'પરિવહનમાં છે',
    incorrectSearch:
      'તમે જે ઓર્ડર / શિપમેન્ટને ટ્રેક કરવાનો પ્રયાસ કરી રહ્યાં છો તે હજી સુધી શિપ થયું નથી અથવા દાખલ કરેલો ઓર્ડર નંબર ખોટો છે.',
    inputFeedback: 'કૃપા કરીને તમારો પ્રતિસાદ શેર કરો',
    landmark: 'લેન્ડમાર્ક',
    landmarkErr: 'લેન્ડમાર્ક ૫ અક્ષરથી નાનું અથવા ખાલી ન હોઈ શકે.',
    langName: 'અંગ્રેજી',
    rateUs: 'How would you rate for:',
    lastUpdate: 'અંતિમ અપડેટ',
    location: 'સ્થાન',
    lost: 'ખોવાઈ ગયું',
    myOrders: 'પગલાં લો',
    myOrdersTooltip: 'કોઈ મુદ્દો ઉઠાવવા માટે અહીં ક્લિક કરો',
    next: 'આગળ',
    no: 'ના',
    noAttemptToAddress: 'સરનામાં પર કોઈ ડિલિવરી થઇ નથી',
    noDataFound:
      'તમે જે ઓર્ડર / શિપમેન્ટને ટ્રેક કરવાનો પ્રયાસ કરી રહ્યાં છો તે હજી સુધી શિપ નથી થયું અથવા દાખલ કરેલો ઓર્ડર નંબર ખોટો છે.',
    noScan1: 'શિપ કરવા માટે તૈયાર!',
    noScan2: 'ટ્રેકિંગની વિગતો ટૂંક સમયમાં ઉપલબ્ધ થશે.',
    ofd: 'ડિલિવરી માટે નીકળી ચૂક્યું છે',
    ofp: 'પિકઅપ માટે નીકળી ચુક્યા છે',
    ops404: 'અરેરે, તે 404 છે.',
    on: 'on',
    orderDetails: 'ઓર્ડરની વિગતો',
    orderId: 'ઓર્ડર ID',
    orderPlaced: 'ઓર્ડર થઇ ચુક્યો છે',
    orderPlacedOn: 'આ દિવસે ઓર્ડર કરવામાં આવ્યો',
    orderType: 'ઓર્ડર પ્રકાર',
    riderInfo: 'રાઇડર માહિતી',
    pageNotFound: 'પેજ નથી મળ્યું',
    phoneErr: 'કૃપા કરી 10 અંકનો માન્ય ફોન નંબર દાખલ કરો.',
    pickedup: 'પિકઅપ કર્યું',
    pickupCancelled: 'પિકઅપ રદ કર્યું',
    pickupFailed: 'પિકઅપ નિષ્ફળ રહ્યું',
    pickupRequested: 'પિકઅપની વિનંતી કરી',
    updatedOn: 'પર અપડેટ કર્યું',
    returnInstructions: 'ફરીથી સૂચના સૂચનો',
    pincode: 'પીન કોડ',
    pincodeErr: 'કૃપા કરીને 6 અંકનો પિનકોડ દાખલ કરો.',
    pincodeNotServiceable: 'પિનકોડ સેવાયોગ્ય નથી, કૃપા કરીને અમને ઓર્ડર મોકલો.',
    pleaseTellUs: 'કૃપા કરીને અમને જણાવો કે શું થયું?',
    preferredDate: 'ડિલિવરીની ઇચ્છિત તારીખ',
    pressNextBtn: 'પસંદગી પછી નીચે આપેલ નેક્સ્ટ બટન દબાવો.',
    provideAltNo: 'કૃપા કરીને વૈકલ્પિક નંબર પ્રદાન કરો',
    provideLandmark:
      'કૃપા કરીને તમારા સરનામાંને સરળતાથી શોધવા માટે કુરિયર પાર્ટનર માટે કોઈ લેન્ડમાર્ક પ્રદાન કરો.',
    ratingErr: 'રેટિંગ ખાલી હોઈ શકતી નથી',
    apiErr:
      'અમે વિનંતી પર પ્રક્રિયા કરવામાં અસમર્થ છીએ, કૃપા કરીને પછીથી ફરી પ્રયાસ કરો',
    feedbackMatters: 'તમારો પ્રતિસાદ મહત્વપૂર્ણ છે!',
    ratingFeedback: 'તમારા મૂલ્યવાન પ્રતિસાદ બદલ આભાર!',
    youRatedUs: 'તમે અમને રેટ કર્યા છે',
    youCommented: 'તમે ટિપ્પણી કરી',
    reachedW: 'વેરહાઉસ પહોંચ્યું',
    reverseReturned: 'પરત કર્યું',
    readyToShip: 'શિપ કરવા માટે તૈયાર',
    remark: 'ટિપ્પણી',
    requestCancelled: 'વિનંતી રદ કરી',
    requestRejected: 'Request Rejected',
    requestPlaced: 'વિનંતી કરી',
    requestAccepted: 'Request Accepted',
    requestAcceptedWithDate: date => `Your return has been accepted on ${date}`,
    returned: 'પરત કર્યું',
    searchBy: 'દ્વારા શોધો',
    searchByTrackingId: 'ટ્રેકિંગ ID/AWB',
    searchByOrderId: 'ઓર્ડર ID/No',
    share: 'શેર કરો',
    status: 'સ્થિતિ',
    submit: 'સબમિટ કરો',
    takeAction: 'એક્શન લો',
    time: 'સમય',
    trackingId: 'ટ્રેકિંગ ID',
    updateContact: 'સંપર્ક અપડેટ કરો',
    updateContactNumber: 'ફોન નંબર અપડેટ કરો',
    updateContactNumberToastr:
      'આ અમને તમારો સંપર્ક કરવા અને શિપમેન્ટ વિશેના મહત્વપૂર્ણ અપડેટ્સ મોકલવાની મંજૂરી આપે છે.',
    updateContactToastr:
      'અપડેટ બદલ આભાર, અમે અપડેટ કરેલા સંપર્ક નંબર પર સૂચનાઓ મોકલવાનું શરૂ કરીશું.',
    updateContactTooltip: 'ફોન નંબર બદલવા માટે અહીં ક્લિક કરો',
    veryPoor: 'ખૂબ જ ખરાબ',
    veryPoorAbfrl: 'ભલામણ ઓછી શક્યતા',
    excellentAbfrl: 'ભલામણ ખૂબ શક્યતા',
    veryUnlikely: 'Very Unlikely',
    veryLikely: 'Very Likely',
    viewDetail: 'વિગત જુઓ',
    viewSummary: 'સારાંશ જુઓ',
    viewTracking: 'ટ્રેકિંગ જુઓ',
    wasNotAvailable: 'ડિલિવરીનો પ્રયાસ કરવામાં આવ્યો ત્યારે હું હાજર ન હતો',
    yes: 'હા',
    youFacedAnyIssue: 'શું તમને અન્ય કોઈ સમસ્યાનો સામનો કરવો પડ્યો?',
    youFoundIncorrectLink: 'તમને ખોટી લિંક મળી હોય તેવું લાગે છે.',
    orderPlaceOnDateFn: date => `${date} પર કરાયેલો ઓર્ડર`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    submittedConcern: courier =>
      `તમારી વિનંતી પર કાર્યવાહી કરવા માટે અમે ${courier} સાથે ફોલોઅપ કરીશું.`,
    askRatingFeedback: courier =>
      `${courier} સાથેનો તમારો ડિલિવરીનો અનુભવ કેવો રહ્યો ?`,
    askRatingFeedbackArvind: 'NNNOW સાથેનો તમારો ખરીદીનો અનુભવ કેવો હતો?',
    askRatingFeedbackAbfrl: brandName =>
      `આ ડિલિવરી અનુભવના આધારે, 0-10 ના સ્કેલ પર, તમે કેવી રીતે ભલામણ કરી શકો છો ${brandName} મિત્રને?`,
    askRatingFeedbackTrell:
      'Based on your recent delivery experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience: 'તમારો એકંદરે વળતરનો અનુભવ કેવો રહ્યો?',
    askNonPickedExperience:
      'અમે નોંધ્યું છે કે તમારું શિપમેન્ટ ઉપાડી શકાયું નથી. તમારો એકંદરે વળતરનો અનુભવ કેવો રહ્યો?',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (
      title,
      websiteUrl
    ) => `તમારા ઓર્ડરની સાચી ટ્રેકિંગ લિંક અને માહિતી માટે કૃપા કરી ${title},
${websiteUrl} પર સંપર્ક કરો.`,
    statusOnDateFn: (status, date) => `${status} ${date} પર`,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'કૃપા કરીને અમને જણાવો કે શું ખોટું થયું છે!';
        case 6:
        case 7:
        case 8:
          return 'અમે કઈ રીતે વધુ ઉત્તમ સેવા આપી શકીએ?';
        default:
          return 'કોમ્પ્લિમેન્ટ આપો!';
      }
    },
    edd: (expression, message) => {
      switch (expression) {
        case 1:
          return message;
        case 2:
          return 'ટૂંક સમયમાં આવી રહ્યું છે:  તમારી નજીકના સ્થળે પહોંચ્યું છે';
        case 3:
          return 'શિપમેન્ટમાં વિલંબ થયો:  કુરિયર પાર્ટનરને શિપમેન્ટ વહેલી તકે તમને પહોંચાડવા માટે સૂચિત કરવામાં આવ્યું છે';
        case 4:
          return 'અપેક્ષા મુજબ પહોંચી રહ્યું છે';
        case 5:
          return 'શિપમેન્ટમાં વિલંબ થયો:  લોજિસ્ટિક્સ ટીમને સૂચિત કરવામાં આવી છે';
        case 7:
          return 'તમારી ડિલિવરી પસંદગીના આધારે આગામી 24-48 કલાકમાં ફરીથી પ્રયાસ કરવામાં આવશે';
        case 8:
          return message;
        case 9:
          return 'એકવાર રવાના થયા પછી તે જ દિવસે શિપમેન્ટ વિતરિત કરવામાં આવશે';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `ઓર્ડર ડિલિવર થયો નથી: તમારી સાથે સંપર્ક ન સાધી શકવાને કારણે અમારા કુરિયર પાર્ટનર તમારા ઓર્ડરને તમારા સુધી પહોંચાડવામાં અસમર્થ રહ્યા હતા.કૃપા કરીને ડિલિવરી માટે તમારી પસંદીદા તારીખ પ્રદાન કરો: `;
        case 2:
        case 9:
        case 14:
          return `ઓર્ડર ડિલિવર થયો નથી: અમારા કુરિયર પાર્ટનરના જણવ્યા અનુસાર તમારી ડિલિવરી ન સ્વીકારવાની વિનંતીના કારણે તમારું પેકેજ ડિલિવર ન થઇ શક્યું. ઓર્ડર ડિલિવર કરવા માટે 'હા' પર ક્લિક કરો, ઓર્ડર રદ કરવા માટે 'ના'.`;
        case 3:
          return `ઓર્ડર ડિલિવર થયો નથી: તમે રી - શેડ્યૂલ કરવા માટે વિનંતી કરી હોવાને કારણે અમારા ડિલિવરી પાર્ટનર તમારો ઓર્ડર ડિલિવર ન કરી શક્યા.અમે ફરીથી આવીને ડિલિવરી કરીશું! જો તમે અમને તમારી પસંદગીની ડિલિવરી જણાવવા માંગતા હોવ, તો નીચે આપેલી માહિતી દાખલ કરો: `;
        case 4:
          return `ઓર્ડર ડિલિવર થયો નથી: અમારા કુરિયર પાર્ટનર આજે ડિલિવરીનો પ્રયાસ કરવામાં અસમર્થ રહ્યા છે. કૃપા કરીને ડિલિવરી માટે તમારી પસંદગીની તારીખ પ્રદાન કરો: `;
        case 5:
          return `Order Undelivered: Our courier partner was unable to deliver your order since we could not get in touch with you.Please provide your alternate contact so that our courier partner can reach you. શું તમે ઇચ્છો છો કે કુરિયર પાર્ટનર તમને ઓર્ડર દરમિયાન આપવામાં આવેલા નંબર પર સંપર્ક કરે ? `;
        case 6:
          return `ઓર્ડર ડિલિવર થયો નથી: તમારું સરનામું ન શોધી શકવાના કારણે અમારા કુરિયર પાર્ટનર તમારા ઓર્ડરને ડિલિવર કરવામાં અસમર્થ રહ્યા હતા.કૃપા કરી નીચે આપેલા સરનામાંને સંપૂર્ણ લેન્ડમાર્ક સાથે પ્રદાન કરો: `;
        case 7:
          return `Order Undelivered: Our courier partner was unable to deliver your order since the payment was not ready at the time of delivery. Don't worry, we will ask them to make another attempt at your preferred date for delivery.In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `ઓર્ડર ડિલિવર થયો નથી: તમારું સરનામું ન શોધી શકવાના કારણે અમારા કુરિયર પાર્ટનર તમારા ઓર્ડરને ડિલિવર કરવામાં અસમર્થ રહ્યા હતા.કૃપા કરી નીચે આપેલા સરનામાંને સંપૂર્ણ લેન્ડમાર્ક સાથે પ્રદાન કરો.`;
        case 10:
          return `ઓર્ડર ડિલિવર થયો નથી: અમારા કુરિયર પાર્ટનરે અમને જાણ કરી છે કે તમે તેમના સ્થાન પરથી શિપમેન્ટ એકત્રિત કરશો. જો આમ ન હોય, તો કૃપા કરીને તમારી પસંદગીની ડિલિવરી તારીખ પ્રદાન કરો.જો તમને કોઈ મૂંઝવણ હોય, તો નીચે આપેલ કમેન્ટ વિભાગમાં અમને જણાવો: `;
        case 11:
          return `શું ડિલિવરી દરમિયાન તમને કોઈ સમસ્યાનો સામનો કરવો પડ્યો ? કૃપા કરીને નીચે તમારો પ્રતિસાદ આપો: `;
        case 12:
          return `ઓર્ડર ડિલિવર થયો નથી: અમારા કુરિયર પાર્ટનરે અમને જાણ કરી કે તમે ઓપન ડિલિવરી માટે વિનંતી કરી છે.કૃપા કરીને તમારી પસંદગીની ડિલિવરી તારીખ પ્રદાન કરો.જો તમને કોઈ સમસ્યા હોય તો, નીચે આપેલા વિભાગમાં કમેન્ટ વિભાગમાં અમને જણાવો.અમે તમારો પ્રતિસાદ અમારી ટીમને મોકલીશું.`;
        case 13:
          return `ઓર્ડર ડિલિવર થયો નથી: અમારા કુરિયર સાથીએ કમનસીબે તમારા શિપમેન્ટની ખોટા સ્થાને ડિલિવરી કરી છે.અમે તમને ઝડપી ડિલિવરી સુનિશ્ચિત કરવા માટે બધા પ્રયત્નો કરી રહ્યા છીએ.કૃપા કરીને તમારી પસંદગીની ડિલિવરી તારીખ પ્રદાન કરો.જો તમે કોઈ પ્રતિક્રિયા શેર કરવા માંગતા હોવ, તો કૃપા કરીને નીચે આપેલા કમેન્ટ વિભાગમાં અમને જણાવો: `;
        case 0:
        default:
          return `ઓર્ડર ડિલિવર થયો નથી: અમે તમારા શિપમેન્ટને લગતી સમસ્યાનો ઉકેલ શોધી રહ્યા છીએ.કૃપા કરીને તમારી પસંદગીની ડિલિવરી તારીખ પ્રદાન કરો.જો તમને કોઈ મૂંઝવણ હોય, તો નીચે આપેલ કમેન્ટ વિભાગમાં અમને જણાવો: `;
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'કુરિયર પાર્ટનરે અમને જાણ કરી છે કે તેઓએ તમારા સરનામાં સુધી પહોંચવાનો પ્રયત્ન કર્યો પણ તમારું સરનામું શોધી ન શક્યા. શું તમે અમને તમારા સરનામાં વિશે વધુ વિગતો આપી શકશો?',
      timing: {
        a:
          'અમારા કુરિયર પાર્ટનર ડિલિવરી માટે 3 વાર પ્રયાસ કરે છે. જો પ્રથમ પ્રયાસમાં પેકેજ ડિલિવર ન થાય તો બીજા દિવસે (રવિવાર અને જાહેર રજાઓ સિવાયના) તેને ડિલિવર કરવાનો ફરીથી પ્રયાસ કરવામાં આવે છે.',
        b:
          'કૃપા કરીને ખાતરી કરો પેકેજ પ્રાપ્ત કરવા માટે તમારા ડિલિવરીના સરનામાં પર કોઈ ઉપલબ્ધ છે.',
        c: 'શું તમે વિશેષ દિવસોમાં ડિલિવરી મેળવવા માંગો છો?'
      },
      other: () =>
        'કૃપા કરીને અમને કોઈપણ વધારાની વિગતો શેર કરો કે જે અમને ઓર્ડર પહોંચાડવામાં મદદ કરી શકે.',
      phone: {
        a:
          'કેટલીક વાર, કુરિયર પાર્ટનર તમને લેન્ડલાઇનથી કોલ કરે છે જે તમને સ્પામ લાગી શકે છે.અમે કુરિયર પાર્ટનરને ઓર્ડરની ફરીથી ડિલિવરી કરવા કહીશું.',
        b: () =>
          'શું તમે ઇચ્છો છો કે કુરિયર પાર્ટનર તમને ઓર્ડર દરમિયાન આપવામાં આવેલા નંબર પર સંપર્ક કરે?'
      },
      reject:
        'જો તમે અમને તમારા શિપમેન્ટની ડિલિવરી માટે કોઈ અન્ય પ્રયાસ કરાવવા માંગતા હોવ તો કૃપા કરીને અમને જણાવો.'
    },
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `પિકઅપ નિષ્ફળ રહ્યું: અમારો કુરિયર પાર્ટનર તમારી સાથે સંપર્ક ન સાધી શકવાને કારણે પિકઅપ ન કરી શક્યો.કૃપા કરીને તમારો ફોન નંબર અથવા સરનામું પ્રદાન કરો`;
        case 2:
          return `પિકઅપ નિષ્ફળ રહ્યું: અમારા કુરિયર પાર્ટનરે અમને જણાવ્યું છે કે તમારી પિકઅપ રદ કરવાની વિનંતીને કારણે પેકેજ પિકઅપ ન થઇ શક્યું. પિકઅપ કરવા માટે 'હા' પર ક્લિક કરો, પિકઅપને રદ કરવા માટે 'ના' પર ક્લિક કરો.`;
        case 3:
        case '3a':
          return `પ્રોડક્ટમાં ક્ષતિ હતી અથવા પહેલાથી જ વપરાયેલ હતું`;
        case 4:
          return `પ્રોડક્ટનો રંગ અથવા બ્રાન્ડ અલગ છે`;
        case 5:
        case '5a':
          return `વિનંતી કરતા કુલ વસ્તુઓની સંખ્યા અલગ હતી`;
        case 6:
          return `પિકઅપ નિષ્ફળ રહ્યું: કૃપા કરીને તમારી પસંદગીની તારીખ પ્રદાન કરો.`;
        case 7:
          return `પિકઅપ નિષ્ફળ રહ્યું: તમારું સરનામું અમારા સેવાક્ષેત્રના બહાર હોવાને કારણે અમારી કુરિયર કંપની પિકઅપ ન કરી શકી.`;
        case 8:
        case '8a':
          return 'અમારા કુરિયર પાર્ટનરે અમને જણાવ્યું છે કે પિકઅપ માટે ઘણા પ્રયત્નો કરવામાં આવ્યા હતા પરંતુ પિકઅપ ન થઇ શક્યું. તેથી, કૃપા કરીને તમારી ઉપલબ્ધતાના આધારે પિકઅપ માટે પસંદગીની તારીખ પ્રદાન કરો.જો તમને કોઈ મૂંઝવણ હોય, તો નીચે કમેન્ટ વિભાગમાં અમને જણાવો: ';
        case 9:
          return 'કુરિયર તમારી વસ્તુ(ઓ) પિકઅપ ન કરી શક્યું.આગળના(રજા સિવાયના) દિવસે પિકઅપ કરવાનો પ્રયાસ કરવામાં આવશે માટે કૃપા કરીને તમારી વસ્તુને તૈયાર રાખવા વિનંતી.જો તમને કોઈ મૂંઝવણ હોય, તો નીચે કમેન્ટ વિભાગમાં અમને જણાવો: ';
        case 10:
          return 'અમારા કુરિયર પાર્ટનરે જણાવ્યું છે કે તમે પિકઅપની વિનંતીનો અસ્વીકાર કર્યો છે.જો આમ ન હોય તો કૃપા કરીને પિકઅપ માટે તમને અનુકૂળ તારીખ જણાવો.જો તમને કોઈ મૂંઝવણ હોય, તો નીચે કમેન્ટ વિભાગમાં અમને જણાવો: ';
        case 11:
          return 'અમારા કુરિયર પાર્ટનરે જણાવ્યું છે કે પેકેજ પિકઅપ માટે તૈયાર નથી, જો આમ ન હોય તો કૃપા કરીને પિકઅપ માટે તમને અનુકૂળ તારીખ જણાવો.જો તમને કોઈ મૂંઝવણ હોય, તો નીચે કમેન્ટ વિભાગમાં અમને જણાવો: ';
        case 13:
          return `અમારા કુરિયર ભાગીદારે અમને જાણ કરી કે તેઓ તમારું પૅકેજ ઉપાડી શક્યા નથી કારણ કે તેઓ તમારું સરનામું શોધી શક્યા નથી. <br /><br /> કૃપા કરીને તમારું સંપૂર્ણ સરનામું, કોઈપણ સીમાચિહ્નો સહિત, નીચે આપો.`;
        case 14:
          return `અમારા વાહક ભાગીદારે અમને જાણ કરી કે તેઓ તમારી પિકઅપ વિનંતી પર પ્રક્રિયા કરી શકતા નથી કારણ કે તમે અનુપલબ્ધ હતા. કૃપા કરીને તમારી પસંદગીની પિકઅપ તારીખ પસંદ કરો.`;
        case 0:
        default:
          return `પિકઅપ નિષ્ફળ રહ્યું: અમે તમારી વિનંતી અનુસાર આ સમસ્યાને જાણવાનો પ્રયત્ન કરી રહ્યા છીએ.કૃપા કરીને પિકઅપ માટે તમને અનુકૂળ તારીખ જણાવો.જો તમને કોઈ મૂંઝવણ હોય, તો નીચે કમેન્ટ વિભાગમાં અમને જણાવો: `;
      }
    },
    nprFdForm: {
      address:
        'કુરિયર પાર્ટનરે અમને જણાવ્યું છે કે તેઓએ તમારા સરનામાં સુધી પહોંચવાનો પ્રયત્ન કર્યો પણ તમારો સરનામું શોધી ન શક્યા. શું તમે અમને સરનામાં વિશે વધુ વિગતો આપી શકશો?',
      timing: {
        a:
          'અમારા કુરિયર પાર્ટનર્સ પિકઅપ માટે 3 વાર પ્રયાસ કરે છે. જો ક્યારેક પેકેજ પ્રથમ પ્રયાસમાં પિકઅપ ન થઇ શકે તો અમે બીજા દિવસે (રવિવાર અને જાહેર રજાના દિવસો સિવાય) ફરીથી પ્રયત્ન કરીએ છીએ.',
        b:
          'કૃપા કરીને ખાતરી કરો કે પેકેજને સ્વીકારવા માટે તમારા પિકઅપ સરનામાં પર કોઈ ઉપલબ્ધ છે.',
        c: 'શું તમે કોઈ ચોક્કસ દિવસો પર જ પિકઅપ કરાવવા માંગો છો?'
      },
      other:
        'કૃપા કરીને અમારી સાથે કોઈપણ વધારાની માહિતી શેર કરો કે જે અમને ઓર્ડર પિકઅપ કરવામાં મદદ કરી શકે. ઉપરાંત, જો ઉત્પાદન/ગુણવત્તા મેળ ન ખાતી હોય, તો તમારી રિફંડ એપ્લિકેશનને બે વાર તપાસો.',
      phone: {
        a:
          'કેટલાક કિસ્સાઓમાં, કુરિયર પાર્ટનર તમને લેન્ડલાઇનથી સંપર્ક કરે છે જે તમને સ્પામ લાગી શકે છે. અમે કુરિયર પાર્ટનરને ફરીથી પિકઅપ કરવા માટે કહીશું.',
        b:
          'શું તમે ઇચ્છો છો કે કુરિયર પાર્ટનર ઓર્ડર દરમિયાન તમારા દ્વારા આપવામાં આવેલા નંબર પર સંપર્ક કરે ?'
      },
      reject:
        'જો તમે તમારા શિપમેન્ટ માટેના પિકઅપને રદ કરવા માંગતા હોવ તો કૃપા કરીને તેની પુષ્ટિ કરો: '
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `આ ઓર્ડરની ચુકવણી માટે ઉપયોગમાં લેવાયેલા કુલ ${creditValue} ક્રેડિટ્સ તમારા સ્ટોર એકાઉન્ટમાં પાછા જમા થઇ થશે.`,
      totalAsCredit: creditValue =>
        `શું તમને સ્ટોર ક્રેડિટ તરીકે ચૂકવેલી ${
          creditValue > 0 ? 'બાકી ના' : ''
        } રકમનું રિફંડ જોઈએ છે?`,
      totalAsCreditNew:
        'કૃપા કરીને રિફંડ ચુકવણીનું તમારું પસંદીદા મોડ પસંદ કરો',
      prepaid: calculatedAmt =>
        `તમને તમારા જે-તે ઓનલાઇન મોડ ઓફ ટ્રાંઝેક્શનમાં ${calculatedAmt} રૂપિયા પરત મળી જશે.`,
      cod: calculatedAmt =>
        `કૃપા કરીને તમારા બેંક ખાતાની વિગતો દાખલ કરો. તમને નીચે આપેલા બેંક ખાતામાં (Rs. ${calculatedAmt}) રકમ મળી જશે.`,
      refundTotalAsCredit: totalAmount =>
        `સ્ટોર ક્રેડિટ પર રિફંડ: ${totalAmount}`,
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: () =>
        'તમારી રિફંડ રકમ તમારા બ્રાન્ડ ક્રેડિટ્સમાં પાછા જમા કરવામાં આવશે',
      defaultPrepaid: (totalAmount, currency) =>
        `તમારી ${currency ??
          ''} ${totalAmount} ની રિફંડ રકમ સ્ત્રોતમાં પાછી જમા કરવામાં આવશે`
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'Shipped',
    readyForDispatch: 'Ready For Dispatch',
    trackOrder: 'Track Order',
    deliveryFeedback: 'ડિલિવરીનો પ્રતિસાદ',
    raiseConcern: 'સમસ્યા જણાવો',
    expired: 'Expired',
    downloadApp: 'Download the app',
    allRightsReserved: 'All rights reserved',
    emailUs: 'Email us: ',
    callUs: 'Call us: ',
    poweredBy: 'Powered by ',
    login: 'Login',
    username: 'Username',
    psswrd: 'Password',
    returnRequestReceived: 'Return request received',
    returnPlaceDate: 'Return placed date',
    reason: 'Reason',
    anyOtherComment: 'Comment',
    addImage: 'Add Image',
    quantity: 'Quantity',
    back: 'Back',
    edit: 'Edit',
    returnSummary: 'Return Summary',
    returnStatus: 'Return Status',
    pickup: 'Pickup',
    selectReversePickupAddress: 'Select Reverse Pickup Address',
    selectOrAddAddress: 'Select/Add Pickup Address',
    phoneFormat: format =>
      `Please enter a phone number in the format ${format} without the Phone Code`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Request Pickup',
    selfShip: 'Self Ship',
    review: 'Review',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Pickup Date',
    orderStatus: 'Order Status',
    expectedPickupDate: 'Expected Pickup Date',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Approved',
    goHome: 'Go Home',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Update Pickup Address',
    fullName: 'Full Name',
    emailId: 'Email ID',
    city: 'City',
    area: 'Area',
    state: 'State',
    country: 'Country',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    bankDetail: 'Bank Details For Refund',
    upiDetail: 'UPI Details',
    brandCredits: () => 'બ્રાન્ડ ક્રેડિટ્સ',
    backToSource: 'સોર્સ પર પાછા',
    bankAccount: 'બેંક એકાઉન્ટ',
    accountHolderName: 'Account Holder Name',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Account Number',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Fetch',
    enterReversePickupAddress: 'Enter Reverse Pickup Address',
    pleaseUploadSupportingImage: imageQuantity =>
      `Please upload ${imageQuantity} supporting image`,
    processing: 'Processing',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} images required for this request`,
    returns: 'Returns',
    exchange: () => 'Return and Exchange',
    enterYourOrderInformation: 'Enter your order information to get started.',
    ourReturnExchangePolicy: () => 'Our Returns & Exchange Policy',
    refundPolicy: 'Refund Policy',
    termsConditions: 'Terms & Conditions',
    stepOf: currentStep => `Step ${currentStep} of 3`,
    allPriceShownPerItem: 'All prices shown are at item level',
    qtyPerOrderNote: 'Note: Qty shows original ordered item quantity',
    qty: 'Qty',
    returnedQty: 'Returned quantity',
    placeFreshPickupRequest: 'Pickup cancelled, place fresh pickup request.',
    remove: 'Remove',
    return: 'Return',
    startReturn: 'Start Return',
    whyAreYouReturning: 'Why are you returning the product?', // optional
    whyYouWantReturn: 'Why do you want to return?',
    selfShipMode:
      'As you selected Self Ship Mode. Please pack the items securely to prevent any loss or damage during transit.',
    requestPlacedDate: 'Request Placed Date',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'ટૂંક સમયમાં જ શીપીંગ',
    max200Chars: 'Max. 200 characters',
    select: 'Select',
    charsLeft: chars => `${chars} characters left`
  },
  te: {
    actionInProgress: 'చర్య పురోగతిలో ఉంది.',
    address: 'చిరునామా',
    addressCharLimit:
      'దయచేసి కనీసం 10 క్యారెక్టర్లు మరియు 600 కంటే తక్కువ నమోదు చేయండి.',
    addressErr:
      'చిరునామా ఖాళీగా ఉండరాదు లేదా 10 క్యారెక్టర్ల కంటే తక్కువ ఉండరాదు.',
    agentDidntCall: 'డెలివరీ ఏజెంట్ నాకు కాల్ చేయలేదు',
    anyOtherDate: 'ఏదైనా ఇతర తేదీ?',
    cancelled: 'క్యాన్సిల్ అయ్యింది/చేయబడింది',
    checkCurrentStatus: 'మీ షిప్‌మెంట్ యొక్క ప్రస్తుత స్థితిని చెక్ చేయండి.',
    trackYourOrder: 'Track Your Order',
    chooseOption: 'దయచేసి ఏదైనా ఆప్షన్ ఎంచుకోండి.',
    commentErr: 'కామెంట్ ఖాళీగా ఉంచరాదు',
    comments: 'కామెంట్లు',
    confirmed: 'ధృవీకరించబడింది',
    confirm: 'Confirm',
    requestReturn: 'రిక్వెస్ట్ రిటర్న్',
    contactNumber: 'కాంటాక్ట్ నెంబర్',
    courier: 'కొరియర్',
    damaged: 'పాడైపోయింది',
    dateErr: 'దయచేసి సరైన తేదీని నమోదు చేయండి',
    delivered: 'డెలివరీ చేయబడింది',
    dispatched: 'పంపిణీ చేయబడింది',
    doNotWantProduct: 'ఇక పై ఈ ప్రోడక్ట్ అవసరం లేదు',
    emailidErr: 'దయచేసి చెల్లుబాటు అయ్యే ఇమెయిల్ ఐడీ ని నమోదు చేయండి.',
    invalidImageErr: 'ఫైల్ ఆకృతికి మద్దతు లేదు',
    excellent: 'చాలా బాగుంది',
    expectedDelivery: 'ఆశిస్తున్న డెలివరీ',
    failedDelivery: 'డెలివరీ ఫెయిల్ అయ్యింది',
    feedbackToastr1:
      'మీ ఫీడ్ బ్యాక్ కు ధన్యవాదాలు! మీకు మళ్లీ సేవ అందించడానికి ఎదురు చూస్తాము.',
    feedbackToastr2:
      'ఫీడ్ బ్యాక్‌కు ధన్యవాదాలు! ఈసారి మీకు మరింత మెరుగైన అనుభవాన్ని అందించడం కొరకు మేం పనిచేస్తాం.',
    abfrlFeedbackToast:
      'అభిప్రాయానికి ధన్యవాదాలు, మిమ్మల్ని సర్వేకు మళ్లిస్తున్నాను',

    inTransit: 'రవాణాలో ఉంది',
    incorrectSearch:
      'మీరు ట్రాక్ చేయడానికి ప్రయత్నిస్తున్న ఆర్డర్/షిప్ మెంట్ ఇంకా షిప్పింగ్ చేయబడలేదు లేదా నమోదు చేయబడ్డ ఆర్డర్ నెంబరు సరైనది కాదు.',
    inputFeedback: 'మీ ఫీడ్‌బ్యాక్‌ను షేర్ చేయండి',
    landmark: 'ల్యాండ్ మార్క్',
    landmarkErr:
      'ల్యాండ్‌మార్క్ ఖాళీగా ఉండరాదు లేదా 5 క్యారెక్టర్ల కంటే తక్కువ ఉండరాదు.',
    langName: 'ఇంగ్లీష్',
    rateUs: 'How would you rate for:',
    lastUpdate: 'చివరి అప్ డేట్',
    location: 'ప్రదేశం',
    lost: 'పోగొట్టుకుపోయింది',
    myOrders: 'చర్య తీసుకో౦డి',
    myOrdersTooltip: 'ఒక సమస్యను తెలపడానికి ఇక్కడ క్లిక్ చేయండి',
    next: 'తరువాత',
    no: 'కాదు',
    noAttemptToAddress: 'చిరునామాకు డెలివరీ చేయడానికి ఎలాంటి ప్రయత్నం చేయలేదు',
    noDataFound:
      'మీరు ట్రాక్ చేయడానికి ప్రయత్నిస్తున్న ఆర్డర్/షిప్ మెంట్ ఇంకా షిప్పింగ్ చేయబడలేదు లేదా నమోదు చేయబడ్డ ఆర్డర్ నెంబరు సరైనది కాదు.',
    noScan1: 'షిప్పింగ్ చేయడానికి సిద్ధం!',
    noScan2: 'ట్రాకింగ్ వివరాలు త్వరలో అందుబాటులోకి వస్తాయి.',
    ofd: 'అవుట్ డెలివరీ',
    ofp: 'పికప్ కు సిద్ధం',
    ops404: 'అరెరే!.. అది 404',
    on: 'on',
    orderDetails: 'ఆర్డర్ వివరాలు',
    orderId: 'ఆర్డర్ ఐడీ',
    orderPlaced: 'ఆర్డర్ ప్లేస్ అయ్యింది',
    orderPlacedOn: 'వద్ద ఆర్డర్ ప్లేస్ అయ్యింది',
    orderType: 'ఆర్డర్ టైప్',
    riderInfo: 'రైడర్ సమాచారం',
    pageNotFound: 'పేజీ కనుగొనబడలేదు',
    phoneErr: 'దయచేసి 10 అంకెల చెల్లుబాటు అయ్యే ఫోన్ నెంబరు నమోదు చేయండి.',
    pickedup: 'పిక్ చేసుకోవడమైంది',
    pickupCancelled: 'పికప్ క్యాన్సిల్ అయ్యింది',
    pickupFailed: 'పికప్ విఫలమైంది',
    pickupRequested: 'పికప్ కోరడమైంది',
    updatedOn: 'నవీకరించబడింది',
    returnInstructions: 'సూచనలను తిరిగి ప్రయత్నించండి',
    pincode: 'పిన్ కోడ్',
    pincodeErr: 'దయచేసి 6 అంకెల పిన్‌కోడ్‌ను నమోదు చేయండి.',
    pincodeNotServiceable:
      'పిన్‌కోడ్ సర్వీస్ చేయబడలేదు, దయచేసి ఆర్డర్‌ని మాకు షిప్పింగ్ చేయండి.',
    pleaseTellUs: 'ఏమి జరిగిందో దయచేసి మాకు చెప్పండి?',
    preferredDate: 'అనుకూలమైన డెలివరీ తేదీ',
    pressNextBtn: 'ఎంపిక తరువాత దిగువన నెక్ట్స్ బటన్ నొక్కండి.',
    provideAltNo: 'దయచేసి మరొక నెంబర్‌ను ఇవ్వండి',
    provideLandmark:
      'మీ చిరునామాను కొరియర్ పార్ట్‌నర్ లొకేట్ చేయడం కొరకు దయచేసి ఒక ల్యాండ్‌మార్క్‌ని అందించండి.',
    ratingErr: 'రేటింగ్ ఖాళీగా ఉంచరాదు',
    apiErr:
      'మేము అభ్యర్థనను ప్రాసెస్ చేయలేకపోతున్నాము, దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి',
    feedbackMatters: 'మీ అభిప్రాయం ముఖ్యం!',
    ratingFeedback: 'మీ విలువైన ఫీడ్‌బ్యాక్‌కు ధన్యావాదాలు!',
    youRatedUs: 'మీరు మాకు రేట్ చేసారు',
    youCommented: 'మీరు వ్యాఖ్యానించారు',
    reachedW: 'గోదాముకు చేరుకుంది',
    reverseReturned: 'తిరిగి ఇవ్వడమైంది',
    readyToShip: 'తరలించడానికి సిద్ధంగా ఉంది',
    remark: 'వ్యాఖ్య',
    shippingLabel: 'Shipping Label',
    shippingLabelInProgress:
      'Fetching the label from the carrier, it will be available for download in a few minutes',
    shippingLabelReady:
      'Your shipping label has been generated, now you can download it',
    requestCancelled: 'అభ్యర్థన రద్దు చేయబడింది',
    requestRejected: 'Request Rejected',
    requestPlaced: 'అభ్యర్థన స్వీకరించడమైంది',
    requestAccepted: 'Request Accepted',
    requestAcceptedWithDate: date => `Your return has been accepted on ${date}`,
    returned: 'తిరిగి ఇవ్వడమైంది',
    searchBy: 'ఈ ప్రకారం శోధించు',
    searchByTrackingId: 'ట్రాకింగ్ ఐడీ/ఎడబ్లుబి',
    searchByOrderId: 'ఆర్డర్ ఐడీ / నంబర్',
    share: 'షేర్',
    status: 'స్థితి',
    submit: 'సమర్పించు',
    takeAction: 'చర్య తీసుకో౦డి',
    time: 'సమయం',
    trackingId: 'ట్రాకింగ్ ఐడీ',
    updateContact: 'కాంటాక్ట్‌ను అప్‌డేట్ చేయండి',
    updateContactNumber: 'అప్ డేట్ కాంటాక్ట్ నెంబర్',
    updateContactNumberToastr:
      'షిప్ మెంట్ గురించి మిమ్మల్ని సంప్రదించడానికి మరియు ముఖ్యమైన అప్‌డేట్‌లను పంపడానికి ఇది మమ్మల్ని అనుమతిస్తుంది.',
    updateContactToastr:
      'అప్‌డేట్ కొరకు ధన్యవాదాలు!, అప్‌డేట్ చేయబడ్డ కాంటాక్ట్ నెంబరుపై మేం నోటిఫికేషన్‌లను పంపడం ప్రారంభిస్తాం.',
    updateContactTooltip: 'కాంటాక్ట్ నెంబర్‌ను మార్చడానికి ఇక్కడ క్లిక్ చేయండి',
    veryPoor: 'అస్సలు బాగాలేదు',
    veryPoorAbfrl: 'సిఫారసు చేయడానికి కనీసం అవకాశం ఉంది',
    excellentAbfrl: 'సిఫారసు చేసే అవకాశం ఉంది',
    veryUnlikely: 'Very Unlikely',
    veryLikely: 'Very Likely',
    viewDetail: 'వివరాలను వీక్షించు',
    viewSummary: 'సారాంశాన్ని వీక్షించు',
    viewTracking: 'ట్రాకింగ్ ను వీక్షించు',
    wasNotAvailable: 'డెలివరీ ప్రయత్నం చేసినప్పుడు నేను అందుబాటులో లేను',
    yes: 'అవును',
    youFacedAnyIssue: 'మీరు ఏదైనా ఇతర సమస్యను ఎదుర్కొన్నారా?',
    youFoundIncorrectLink:
      'మీరు ఒక తప్పు లింక్‌కి వెళ్ళినట్లుుగా కనిపిస్తోంది.',
    orderPlaceOnDateFn: date => `${date} న ఆర్డర్ ప్లేస్ అయ్యింది`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    submittedConcern: courier =>
      `మీ అభ్యర్థన ప్రాసెస్ చేయడం కొరకు మేం ${courier} ఫాలో అప్ చేస్తాం.`,
    askRatingFeedback: courier => `${courier} తో మీ డెలివరీ అనుభవం ఎలా ఉంది?`,
    askRatingFeedbackArvind: 'NNNOW తో మీ షాపింగ్ అనుభవం ఎలా ఉంది?',
    askRatingFeedbackAbfrl: brandName =>
      `ఈ డెలివరీ అనుభవం ఆధారంగా, 0-10 స్కేల్‌పై మీరు ఎంతవరకు సిఫారసు చేస్తారు ${brandName} స్నేహితుడికి?    `,
    askRatingFeedbackTrell:
      'Based on your recent delivery experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience: 'మీ మొత్తం రిటర్న్స్ అనుభవం ఎలా ఉంది?',
    askNonPickedExperience:
      'మీ షిప్‌మెంట్ తీసుకోబడలేదని మేము గమనించాము. మీ మొత్తం రిటర్న్స్ అనుభవం ఎలా ఉంది?',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (title, websiteUrl) =>
      `సరైన ట్రాకింగ్ లింక్ కొరకు మరియు మీ ఆర్డర్ గురించి సమాచారం కొరకు దయచేసి ${title} వద్ద ${websiteUrl} సంప్రదించండి.`,
    statusOnDateFn: (status, date) => `${date} నాడు ${status} `,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'దయచేసి జరిగిన పొరబాటేమిటో తెలియజేయండి!';
        case 6:
        case 7:
        case 8:
          return 'మేము ఎలా మరింత మెరుగుపర్చుకోగల౦?';
        default:
          return 'ఒక అభినందన ఇవ్వండి!';
      }
    },
    edd: (expression, message) => {
      switch (expression) {
        case 1:
          return message;
        case 2:
          return 'త్వరలో చేరుతుంది:  మీకు దగ్గర్లో ఉన్న ప్రదేశానికి చేరుకుంది';
        case 3:
          return 'షిప్ మెంట్ ఆలస్యమైంది:  మీ షిప్‌మెంట్‌ను సాధ్యమైనంత త్వరగా డెలివరీ చేయడం కోసం కొరియర్ పార్టనర్‌కు తెలియచేయబడింది.';
        case 4:
          return 'ఊహించిన విధంగా కదులుతోంది';
        case 5:
          return 'షిప్ మెంట్ ఆలస్యమైంది:  లాజిస్టిక్స్ టీంచేత తెలియచేయబడింది';
        case 7:
          return 'మీ డెలివరీ ప్రాధాన్యత ఆధారంగా, రా ను న్న 24 - 48 గంటల్లో తి రి గి ప్రయత్నిం చబడు తుం ది.';
        case 8:
          return message;
        case 9:
          return 'పంపిన రోజే రవాణా పంపబడుతుంది';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `ఆర్డర్ డెలివరీ కాలేదు: మా కొరియర్ పార్ట్‌నర్ మీ ఆర్డర్‌ని డెలివరీ చేయలేకపోయారు,
    ఎందుకంటే వారు మిమ్మల్ని సంప్రదించలేకపోయారు.డెలివరీ కొరకు మీరు ఎంచుకున్న తేదీని దయచేసి తెలపండి.`;
        case 2:
        case 9:
        case 14:
          return `ఆర్డర్ డెలివరీ చేయబడలేదు: డెలివరీని తిరస్కరించడం కొరకు మీ అభ్యర్థన మేరకు మీ ప్యాకేజీ డెలివరీ చేయబడలేదని మా కొరియర్ పార్ట్‌నర్ మాకు తెలియజేశారు.

  ఆర్డర్ డెలివరీ చేయడం కొరకు ‘YES’ మీద క్లిక్ చేయండి,
    ఆర్డర్ క్యాన్సిల్ చేయడం కొరకు 'NO'.`;
        case 3:
          return `ఆర్డర్ డెలివరీ చేయబడలేదు: డెలివరీ కోసం మీరు అభ్యర్థించినప్పటి నుండి మా కొరియర్ పార్ట్ నర్ మీ ఆర్డర్ ను డెలివరీ చేయలేకపోయారు.మేము తిరిగి మీకు డెలివరీ చేయడానికి ప్రయత్నిస్తాము.మీకు అనుకూలమైన డెలివరీ కోసం కింది సమాచారాన్ని అందించండి.`;
        case 4:
          return `ఆర్డర్ డెలివరీ చేయబడలేదు: మా కొరియర్ పార్ట్ నర్ ఇవాళ డెలివరీ ప్రయత్నం చేయలేకపోయాడు.

  డెలివరీ కొరకు దయచేసి మీకు ఇష్టమైన తేదీని ఇవ్వండి: `;
        case 5:
          return `Order Undelivered: Our courier partner was unable to deliver your order since we could not get in touch with you.Please provide your alternate contact so that our courier partner can reach you.

  ఆర్డర్ చేసే సమయంలో ఇవ్వబడ్డ అదే నెంబరుపై కొరియర్ పార్ట్ నర్ మీకు కాల్ చేయాలని మీరు కోరుకుంటున్నారా ? `;
        case 6:
          return `ఆర్డర్ డెలివరీ చేయబడలేదు: మీరు అందించిన అడ్రస్ ను మా కొరియర్ పార్ట్ నర్ గుర్తించలేదు.దయచేసి మీ పూర్తి అడ్రస్ ను ల్యాండ్ మార్కుతో పాటుగా కింద నమోదు చేయండి.`;
        case 7:
          return `Order Undelivered: Our courier partner was unable to deliver your order since the payment was not ready at the time of delivery.

    Don't worry,
  we will ask them to make another attempt at your preferred date for delivery.In case you faced a different issue,
    please provide your feedback below.`;
        case 8:
          return `ఆర్డర్ డెలివరీ చేయబడలేదు: చిరునామాను వారు లొకేట్ చేయలేకపోవడం వల్ల మా కొరియర్ పార్ట్ నర్ మీ ఆర్డర్ ని డెలివరీ చేయలేకపోయారు.దయచేసి దిగువ ల్యాండ్ మార్క్ తో మీ పూర్తి చిరునామాను ఇవ్వండి.`;
        case 10:
          return `ఆర్డర్ డెలివరీ చేయబడలేదు: వారి లొకేషన్ నుంచి మీరు షిప్ మెంట్ ని కలెక్ట్ చేస్తున్నట్లుగా మా కొరియర్ పార్ట్ నర్ మాకు తెలియజేశారు.

  ఒకవేళ ఇది కానట్లయితే,
    దయచేసి మీకు నచ్చిన డెలివరీ తేదీని ఇవ్వండి.మీకు ఏదైనా సమస్య తలెత్తితే కామెంట్ రూపంలో రాయడానికి ఎలాంటి మొహమాటం పడకండి: `;
        case 11:
          return `డెలివరీ సమయంలో మీరు ఏదైనా సమస్యను ఎదుర్కొన్నారా ? దయచేసి దిగువ మీ ఫీడ్ బ్యాక్ ని అందించండి: `;
        case 12:
          return `ఆర్డర్ డెలివరీ చేయబడలేదు: ఓపెన్ డెలివరీ కొరకు మీరు అభ్యర్థించినట్లుగా మా కొరియర్ పార్ట్ నర్ మాకు సమాచారం అందించాడు.దయచేసి మీకు ఇష్టమైన డెలివరీ తేదీని అందించండి.ఒకవేళ మీకు సమస్య ఎదురవుతున్నట్లయితే నిర్మొహమాటంగా మాకు తెలియజేయండి.మరియు మేం ఫీడ్‌బ్యాక్‌ని మా టీమ్‌కు చేరవేస్తాం.`;
        case 13:
          return `ఆర్డర్ డెలివరీ చేయబడలేదు: మా కొరియర్ పార్ట్‌నర్ దురదృష్టవశాత్తు మీ షిప్‌మెంట్‌‌ని తప్పు లొకేషన్‌కు మార్చారు.మీకు వేగంగా డెలివరీ చేయడం కొరకు మేం అన్ని రకాలుగా ప్రయత్నిస్తున్నాము.దయచేసి మీకు ఇష్టమైన డెలివరీ తేదీని అందించండి.ఒకవేళ మీరు ఏదైనా ఫీడ్‌బ్యాక్‌ని పంచుకోవాలని అనుకున్నట్లయితే,
    దయచేసి దిగువ కామెంట్ సెక్షన్ లో మాకు రాయండి: `;
        case 0:
        default:
          return `ఆర్డర్ డెలివరీ చేయబడలేదు: మీ షిప్‌మెంట్‌లో సమస్యను మేం గుర్తిస్తున్నాం.దయచేసి మీకు ఇష్టమైన డెలివరీ తేదీని అందించండి.ఒకవేళ మీకు ఇబ్బంది ఉన్నట్లయితే,
    దిగువ కామెంట్ సెక్షన్ లో మాకు రాయండి.`;
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'కొరియర్ పార్ట్ నర్ మీ చిరునామాను చేరుకోవడానికి ప్రయత్నించినప్పటికీ, మీ చి రు నా మా కను గొనలేకపోయి నట్లు సమా చా రం అందిం చా డు.చి రు నా మా గు రిం చి మీ రు మా కు మరి న్ని వి వరా లను అందిం చగలరా ? ',
      timing: {
        a:
          'డెలివరీ కొరకు మా కొరియర్ భాగస్వాములు 3 సార్లు ప్రయత్నిస్తారు. ఈ సందర్భంలో, ప్యా కేజీ ని మొదటి సా రి డెలి వరీ చేయలేకపోయి నట్లయి తే, ఆది వా రా లు మరి యు పబ్లి క్ హా లి డేలను మి నహా యిం చి,తరు వా త రోజు మళ్లీ మేం మళ్లీ ప్రయత్ని స్తాం.',
        b:
          'ప్యాకేజీని అందుకోవడం కొరకు మీ డెలివరీ చిరునామా వద్ద ఎవరైనా అందుబాటులో ఉన్నారని దయచేసి ధృవీకరించుకోండి.',
        c:
          'నిర్ధిష్ట రోజుల్లో డెలివరీ ప్రయత్నాన్ని మీరు పొందాలని అనుకుంటున్నారా?'
      },
      other: () =>
        'ఆర్డర్ డెలివరీ చేయడంలో మాకు సహాయపడే ఏవైనా అదనపు వివరాలను దయచేసి షేర్ చేయండి.',
      phone: {
        a:
          'కొన్ని సందర్భాల్లో, కొరి యర్ భా గస్వా ము లు ల్యాం డ్ లైన్ నుం చి కా ల్ చేస్తా రు,ఇది మీ రు స్పా మ్ గా భా విం చవచ్చు.మరోసా రి డెలి వరీ కి ప్రయత్నిం చమని మేం కొరి యర్ పా ర్ట్ నర్ని కోరతాం.',
        b: () =>
          'Do you want the courier partner to call you on the same number as given during order placement?'
      },
      reject:
        'మీ షిప్ మెంట్ కొరకు మరో డెలివరీ ప్రయత్నం చేయాలని మీరు అనుకుంటున్నారా అని దయచేసి ధృవీకరించండి: '
    },
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `పికప్ విఫలమైంది: మా కొరియర్ పార్ట్ నర్ మిమ్మల్ని సంప్రదించ లేకపోవడం వల్ల ఆర్డర్ ని పికప్ చేసుకోలేకపోయాడు.దయచేసి మీ కాంటాక్ట్ నెంబరు లేదా చిరునామా ఇవ్వండి.`;
        case 2:
          return `పికప్ విఫలమైంది: పికప్ అభ్యర్థనను క్యాన్సిల్ చేయడం కొరకు మీ అభ్యర్థనకు అనుగుణంగా మీ ప్యాకేజీ పికప్ చేసుకోలేమని మా కొరియర్ పార్ట్‌నర్ మాకు తెలియజేశారు.

  పికప్ చేసుకోవడం కొరకు అవును మీద క్లిక్ చేయండి,
    పికప్ క్యాన్సిల్ చేయడానికి 'లేదు' మీద క్లిక్ చేయండి.`;
        case 3:
        case '3a':
          return `వస్తువు పాడైపోయింది లేదా ఉపయోగించబడింది`;
        case 4:
          return `లేదా వస్తువు రంగు లేదా బ్రాండ్ భిన్నంగా ఉంది`;
        case 5:
        case '5a':
          return `మొత్తం వస్తువుల సంఖ్య కోరిన దాని కంటే వేరుగా ఉంది.`;
        case 6:
          return `పికప్ విఫలమైంది: దయచేసి మీకు ఇష్టమైన పికప్ తేదీని తెలియజేయండి.`;
        case 7:
          return `పికప్ విఫలమైంది: మా కొరియర్ కంపెనీ సర్వీస్ బయట మీ చిరునామా ఉండటం వల్ల పికప్ చేసుకోలేదు.`;
        case 8:
        case '8a':
          return 'డెలివరీ కొరకు మా కొరియర్ పార్ట్‌నర్లు 3 సార్లు ప్రయత్నిస్తారు. ఈ సందర్భంలో, ప్యా కేజీ ని మొదటి సా రి డెలి వరీ చేయలేకపోయి నట్లయి తే, ఆది వా రా లు మరి యు పబ్లి క్ హా లి డేలను మి నహా యిం చి, తరు వా త రోజు మేం మళ్లీ ప్రయత్ని స్తాం. ';
        case 9:
          return 'కొరియర్ మీ వస్తువు / (లు) పికప్‌ని ప్రయత్నించలేదు, తరు వా త బి జి నెస్ రోజు న పి కప్ చేయడా ని కి ప్రయత్నిం చబడు తుం ది.కను క, దయచేసి వస్తు వు / (లు) ని ఉంచండి, ఒకవేళ మీ రు ఏదైనా ఫీ డ్‌బ్యా క్‌ని పంచు కోవా లని అను కు న్నట్లయి తే,దయచేసి దా ని ని కా మెంట్స్ సెక్షన్‌లో రా యండి.';
        case 10:
          return ' పి కప్ అభ్యర్థనను మీ రు ఆమోదిం చడా ని కి ని రా కరిం చి నట్లు గా మా కొరి యర్ పా ర్ట్‌నర్‌కి తెలి యజేయబడిం ది.ఒకవేళ ఇది సందర్భం కా నట్లయి తే, తదు పరి పి కప్ తేదీ ప్రా ధా న్యతను దయచేసి అందిం చండి.ఒకవేళ మీ కు సమస్య తలెత్తి తే, ది గు వ కా మెంట్ సెక్షన్‌లో మా కు రా యండి.';
        case 11:
          return 'ప్రొడక్ట్ పి కప్ కొరకు సి ద్ధంగా లేదని మా కొరి యర్ పా ర్ట్ నర్ నోటి ఫై చేశా రు, ఒకవేళ ఇది కా నట్లయి తే, దయచేసి తదు పరి మీ పి కప్ తేదీ ప్రా ధా న్యతను తెలపండి.ఒకవేళ మీ కు సమస్య తలెత్తి తే, ది గు వ కా మెంట్ సెక్షన్ లో మా కు రా యండి.';
        case 13:
          return `మా కొరియర్ భాగస్వామి మీ చిరునామాను గుర్తించలేకపోయినందున వారు మీ ప్యాకేజీని తీసుకోలేకపోయారని మాకు తెలియజేశారు. <br /><br /> దయచేసి దిగువన ఏవైనా ల్యాండ్‌మార్క్‌లతో సహా మీ పూర్తి చిరునామాను అందించండి.`;
        case 14:
          return `మీరు అందుబాటులో లేనందున వారు మీ పికప్ అభ్యర్థనను ప్రాసెస్ చేయలేకపోయారని మా క్యారియర్ భాగస్వామి మాకు తెలియజేశారు. దయచేసి మీరు ఇష్టపడే పికప్ తేదీని ఎంచుకోండి.`;
        case 0:
        default:
          return `పికప్ విఫలమైంది: మీ అభ్యర్థనలో సమస్యను మేం గుర్తిస్తున్నాం.దయచేసి మీకు ఇష్టమైన పికప్ తేదీని అందించండి.ఒకవేళ మీకు ఇబ్బంది ఉన్నట్లయితే,
    దిగువ కామెంట్ సెక్షన్ లో మాకు రాయండి.`;
      }
    },
    nprFdForm: {
      address:
        'కొరియర్ పార్ట్ నర్ మీ చిరునామాను చేరుకోవడానికి ప్రయత్నించినప్పటికీ, మీ చిరునామా కనుగొనలేకపోయినట్లు సమాచారం అందించాడు. చిరునామా గురించి మీరు మాకు మరిన్ని వివరాలను అందించగలరా?',
      timing: {
        a:
          'మా కొరియర్ భాగస్వాములు పికప్ కొరకు 3 సార్లు ప్రయత్నిస్తారు. ఈ సందర్భంలో, ప్యాకేజీని మొదటిసారి పికప్ చేసుకోలేని పరిస్థితుల్లో, ఆదివారాలు మరియు పబ్లిక్ హాలిడేలను మినహాయించి, మరుసటి రోజు మళ్లీ మేం మళ్లీ ప్రయత్నిస్తాం.',
        b:
          'ప్యాకేజీని హ్యాండోవర్ చేయడం కొరకు మీ పికప్ చిరునామా వద్ద ఎవరైనా అందుబాటులో ఉన్నారని దయచేసి ధృవీకరించుకోండి.',
        c: 'నిర్దిష్ట రోజుల్లో పికప్ కోసం ప్రయత్నం జరగాలని మీరు అనుకుంటున్నారా?'
      },
      other:
        'ఆర్డర్ పికప్ చేసుకోవడానికి మాకు సహాయపడే ఏవైనా అదనపు వివరాలను దయచేసి పంచుకోండి, ఏదైనా ప్రొడక్ట్/పరిమాణం సరిపోలనట్లయితే మీ రిటర్న్ అభ్యర్థనను క్రాస్ వెరిఫై చేయండి.',
      phone: {
        a:
          'కొన్ని సందర్భాల్లో, కొరియర్ పార్ట్ నర్లు ల్యాండ్ లైన్ నుంచి కాల్ చేస్తారు, ఇది మీకు స్పామ్ గా అనిపించవచ్చు. మరోసారి పికప్ కోసం ప్రయత్నించమని మేం కొరియర్ పార్ట్ నర్ ని అడుగుతాము.',
        b:
          'ఆర్డర్ చేసే సమయంలో ఇవ్వబడ్డ అదే నెంబరుపై కొరియర్ పార్ట్ నర్ మీకు కాల్ చేయాలని మీరు కోరుకుంటున్నారా?'
      },
      reject:
        'మీ షిప్ మెంట్ కొరకు పికప్ ప్రయత్నాన్ని మీరు రద్దు చేయాలని అనుకుంటున్నారా అనేది దయచేసి ధృవీకరించండి: '
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `ఈ ఆర్డర్ పేమెంట్ కొరకు ఉపయోగించబడ్డ మొత్తం ${creditValue} క్రెడిట్ లు ఈ ఆర్డర్ కు విరుద్ధంగా ఉన్నాయి.ఆ క్రెడిట్స్‌ తిరిగి మీ స్టోరు అకౌంట్‌కు క్రెడిట్ చేయబడతాయి.`,
      totalAsCredit: creditValue =>
        `కొరకు మీరు రీఫండ్ కోరుతున్ననారా ${
          creditValue > 0 ? 'మిగిలిన' : ''
        } ఆ మొత్తం స్టోరు క్రెడిట్ గా పొందాలనుకుంటున్నారా ?`,
      totalAsCreditNew: 'దయచేసి మీకు ఇష్టమైన వాపసు చెల్లింపు మోడ్‌ను ఎంచుకోండి',
      prepaid: calculatedAmt =>
        `మీ ఒరిజినల్ ఆన్ లైన్ లావాదేవీ విధానంలో మీరు రూ.${calculatedAmt} వెనక్కి పొందుతారు.`,
      cod: calculatedAmt =>
        `దయచేసి మీ బ్యాంకు ఖాతా వివరాలను నమోదు చేయండి.దిగువ ఇవ్వబడ్డ బ్యాంకు అకౌంట్ లో మీరు చెల్లించిన క్యాష్(రూ.${calculatedAmt}) అందుకుంటారు.`,
      refundTotalAsCredit: totalAmount =>
        `స్టోరు క్రెడిట్ కు రీఫండ్: ${totalAmount} `,
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: () =>
        'మీ వాపసు మొత్తం మీ బ్రాండ్ క్రెడిట్‌లకు తిరిగి జమ అవుతుంది',
      defaultPrepaid: (totalAmount, currency) =>
        `మీ రీఫండ్ మొత్తం ${currency ??
          ''} ${totalAmount} మూలానికి తిరిగి క్రెడిట్ చేయబడుతుంది`
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'Shipped',
    readyForDispatch: 'Ready For Dispatch',
    trackOrder: 'Track Order',
    deliveryFeedback: 'డెలివరీ ఫీడ్ బ్యాక్',
    raiseConcern: 'సమస్యను తెలియపర్చండి',
    expired: 'Expired',
    downloadApp: 'Download the app',
    allRightsReserved: 'All rights reserved',
    emailUs: 'Email us: ',
    callUs: 'Call us: ',
    poweredBy: 'Powered by ',
    login: 'Login',
    username: 'Username',
    psswrd: 'Password',
    returnRequestReceived: 'Return request received',
    returnPlaceDate: 'Return placed date',
    reason: 'Reason',
    anyOtherComment: 'Comment',
    addImage: 'Add Image',
    quantity: 'Quantity',
    back: 'Back',
    edit: 'Edit',
    returnSummary: 'Return Summary',
    returnStatus: 'Return Status',
    pickup: 'Pickup',
    selectReversePickupAddress: 'Select Reverse Pickup Address',
    selectOrAddAddress: 'Select/Add Pickup Address',
    phoneFormat: format =>
      `Please enter a phone number in the format ${format} without the Phone Code`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Request Pickup',
    selfShip: 'Self Ship',
    review: 'Review',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Pickup Date',
    orderStatus: 'Order Status',
    expectedPickupDate: 'Expected Pickup Date',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Approved',
    goHome: 'Go Home',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Update Pickup Address',
    fullName: 'Full Name',
    emailId: 'Email ID',
    city: 'City',
    area: 'Area',
    state: 'State',
    country: 'Country',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    bankDetail: 'Bank Details For Refund',
    upiDetail: 'UPI Details',
    brandCredits: () => 'బ్రాండ్ క్రెడిట్స్',
    backToSource: 'మూలానికి తిరిగి వెళ్ళు',
    bankAccount: 'బ్యాంకు ఖాతా',
    accountHolderName: 'Account Holder Name',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Account Number',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Fetch',
    enterReversePickupAddress: 'Enter Reverse Pickup Address',
    pleaseUploadSupportingImage: imageQuantity =>
      `Please upload ${imageQuantity} supporting image`,
    processing: 'Processing',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} images required for this request`,
    returns: 'Returns',
    exchange: () => 'Return and Exchange',
    enterYourOrderInformation: 'Enter your order information to get started.',
    ourReturnExchangePolicy: () => 'Our Returns & Exchange Policy',
    refundPolicy: 'Refund Policy',
    termsConditions: 'Terms & Conditions',
    stepOf: currentStep => `Step ${currentStep} of 3`,
    allPriceShownPerItem: 'All prices shown are at item level',
    qtyPerOrderNote: 'Note: Qty shows original ordered item quantity',
    qty: 'Qty',
    returnedQty: 'Returned quantity',
    placeFreshPickupRequest: 'Pickup cancelled, place fresh pickup request.',
    remove: 'Remove',
    return: 'Return',
    startReturn: 'Start Return',
    whyAreYouReturning: 'Why are you returning the product?', // optional
    whyYouWantReturn: 'Why do you want to return?',
    selfShipMode:
      'As you selected Self Ship Mode. Please pack the items securely to prevent any loss or damage during transit.',
    requestPlacedDate: 'Request Placed Date',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'త్వరలో షిప్పింగ్',
    max200Chars: 'Max. 200 characters',
    select: 'Select',
    charsLeft: chars => `${chars} characters left`
  },
  ta: {
    actionInProgress: 'நடவடிக்கை மேற்கொள்ளப்பட்டு வருகிறது..',
    address: 'முகவரி',
    addressCharLimit:
      'குறைந்தது 10 எழுத்துக்களை உள்ளிடவும், 600 எழுத்துக்களுக்கு மேல் உள்ளிடக்கூடாது.',
    addressErr:
      'முகவரி உள்ளிடும் இடம் காலியாக இருக்க முடியாது அல்லது முகவரி 10 எழுத்துக்களுக்கு குறைவாக இருக்க முடியாது.',
    agentDidntCall: 'டெலிவரி முகவர் என்னை அழைக்கவில்லை',
    anyOtherDate: 'வேறு ஏதாவது தேதி?',
    cancelled: 'ரத்து செய்யப்பட்டது',
    checkCurrentStatus:
      'உங்கள் ஷிப்மென்ட்டின் தற்போதைய ஸ்டேட்டஸை சரிபார்க்கவும்.',
    trackYourOrder: 'Track Your Order',
    chooseOption: 'எந்த ஆப்ஷனை வேண்டுமானாலும் தேர்வு செய்யுங்கள்.',
    commentErr: 'கருத்து அளிக்கும் இடம் காலியாக இருக்க முடியாது.',
    comments: 'கருத்துக்கள்',
    confirmed: 'உறுதி செய்யப்பட்டது',
    confirm: 'Confirm',
    requestReturn: 'திரும்பக் கோரவும்',
    contactNumber: 'தொடர்பு எண்',
    courier: 'கூரியர்',
    damaged: 'சேதமடைந்துவிட்டது',
    dateErr: 'சரியான தேதியை உள்ளிடவும்.',
    delivered: 'டெலிவரி செய்யப்பட்டது',
    dispatched: 'அனுப்பப்பட்டது',
    doNotWantProduct: 'இனிமேல் இத்தயாரிப்பு வேண்டியதில்லை',
    emailidErr: 'சரியான மின்னஞ்சல் ஐடியை உள்ளிடவும்.',
    invalidImageErr: 'கோப்பு வடிவம் ஆதரிக்கப்படவில்லை',
    excellent: 'அருமை',
    expectedDelivery: 'எதிர்பார்க்கப்படும் டெலிவரி',
    failedDelivery: 'டெலிவரி தோல்வியுற்றது',
    feedbackToastr1:
      'உங்கள் கருத்துகளுக்கு நன்றி! மீண்டும் உங்களுக்கு சேவை செய்ய ஆவலுடன் காத்திருக்கிறோம்.',
    feedbackToastr2:
      'தங்கள் கருத்துகளுக்கு நன்றி! அடுத்த முறை உங்களுக்கு சிறந்த அனுபவத்தை வழங்க நாங்கள் பாடுபடுவோம்!',
    abfrlFeedbackToast:
      'கருத்துக்கு நன்றி, உங்களை ஆய்வுக்கு திருப்பி விடுகிறேன்',
    inTransit: 'போக்குவரத்து வழியின் நடுவில் கூரியர் உள்ளது',
    incorrectSearch:
      'நீங்கள் ட்ராக் செய்ய முயலும் ஆர்டர்/ஷிப்மென்ட் இன்னும் அனுப்பப்படவில்லை அல்லது நீங்கள் உள்ளிட்ட ஆர்டர் எண் தவறானது.',
    inputFeedback: 'உங்கள் கருத்தைப் பகிர்ந்து கொள்ளவும்',
    landmark: 'டெலிவரி முகவரிக்கு அருகிலுள்ள அடையாளம்',
    landmarkErr:
      'அடையாளம் உள்ளிடும் இடம் காலியாக இருக்க முடியாது அல்லது அடையாள பெயர் 5 எழுத்துக்களுக்கு குறைவாக இருக்க முடியாது.',
    langName: 'ஆங்கிலம்',
    rateUs: 'How would you rate for:',
    lastUpdate: 'கடைசி அப்டேட்',
    location: 'இடம்',
    lost: 'தொலைந்துவிட்டது',
    myOrders: 'நடவடிக்கை எடுக்கவும்',
    myOrdersTooltip: 'புகார் அளிக்க இங்கே கிளிக் செய்யவும்',
    next: 'அடுத்தது',
    no: 'இல்லை',
    noAttemptToAddress:
      'டெலிவரி செய்ய வழங்கப்பட்ட முகவரிக்கு டெலிவர் செய்வதற்கு எந்த முயற்சியும் எடுக்கப்படவில்லை',
    noDataFound:
      'நீங்கள் ட்ராக் செய்ய முயலும் ஆர்டர்/ஷிப்மென்ட் இன்னும் அனுப்பப்படவில்லை அல்லது நீங்கள் உள்ளிட்ட ஆர்டர் எண் தவறானது.',
    noScan1: 'அனுப்ப தயாராக உள்ளது!',
    noScan2: 'ட்ராக்கிங் விவரங்கள் சற்று நேரத்தில் கிடைக்கும்.',
    ofd: 'டெலிவர் செய்ய அனுப்பப்பட்டது',
    ofp: 'பிக்-அப் செய்ய தயாராக உள்ளது',
    ops404: 'அச்சச்சோ! இது 404.',
    on: 'on',
    orderDetails: 'ஆர்டரின் விவரங்கள்',
    orderId: 'ஆர்டர் ஐடி',
    orderPlaced: 'ஆர்டர் செய்யப்பட்டது',
    orderPlacedOn: 'Date அன்று ஆர்டர் செய்யப்பட்டது',
    orderType: 'ஆர்டர் வகை',
    riderInfo: 'ரைடர் தகவல்',
    pageNotFound: 'பக்கம் வரவில்லை',
    phoneErr: '10 இலக்க சரியான மொபைல் எண்ணை உள்ளிடவும்.',
    pickedup: 'பிக்-அப் செய்யப்பட்டது',
    pickupCancelled: 'பிக்-அப் ரத்து செய்யப்பட்டது',
    pickupFailed: 'பிக்-அப் செய்வது தோல்வியுற்றது',
    pickupRequested: 'பிக்-அப் கோரப்பட்டது',
    updatedOn: 'புதுப்பிக்கப்பட்டது',
    returnInstructions: 'மறுபரிசீலனை வழிமுறைகள்',
    pincode: 'பின்கோடு',
    pincodeErr: 'தயவுசெய்து 6 இலக்க பின்கோடை உள்ளிடவும்.',
    pincodeNotServiceable:
      'இந்த பின்கோடுக்கு டெலிவரி சேவை கிடையாது, தயவுசெய்து எங்களுக்கு ஆர்டரை அனுப்புங்கள்.',
    pleaseTellUs: 'தயவுசெய்து என்ன நடந்தது என்று எங்களிடம் கூறுங்கள்?',
    preferredDate: 'டெலிவரி பெற விரும்பும் தேதி',
    pressNextBtn: 'தேர்வு செய்தப்பிறகு கீழே உள்ள நெக்ஸ்ட் பட்டனை அழுத்தவும்.',
    provideAltNo: 'மாற்று எண்ணை வழங்கவும்',
    provideLandmark:
      'உங்கள் முகவரியைக் கண்டுபிடிக்க கூரியர் பார்ட்னருக்கு முகவரிக்கு அருகிலுள்ள அடையாளத்தை வழங்கவும்.',
    ratingErr: 'மதிப்பீடு வழங்கும் இடம் காலியாக இருக்க முடியாது',
    apiErr: 'கோரிக்கையை எங்களால் செயல்படுத்த முடியவில்லை, பிறகு முயற்சிக்கவும்',
    feedbackMatters: 'உங்கள் கருத்து முக்கியமானது!',
    ratingFeedback: 'உங்கள் கருத்தை வழங்கியமைக்கு நன்றி!',
    youRatedUs: 'நீங்கள் எங்களை மதிப்பிட்டீர்கள்',
    youCommented: 'நீங்கள் கருத்து தெரிவித்தீர்கள்',
    reachedW: 'கிடங்கை சென்றடைந்தது',
    reverseReturned: 'ரிட்டர்ன் செய்யப்பட்டது',
    readyToShip: 'அனுப்ப தயாராக உள்ளது',
    remark: 'கருத்து',
    shippingLabel: 'Shipping Label',
    shippingLabelInProgress:
      'Fetching the label from the carrier, it will be available for download in a few minutes',
    shippingLabelReady:
      'Your shipping label has been generated, now you can download it',
    requestCancelled: 'கோரிக்கை ரத்து செய்யப்பட்டது',
    requestRejected: 'Request Rejected',
    requestPlaced: 'கோரிக்கை செய்யப்பட்டுள்ளது',
    requestAccepted: 'Request Accepted',
    requestAcceptedWithDate: date => `Your return has been accepted on ${date}`,
    returned: 'ரிட்டர்ன் செய்யப்பட்டது',
    searchBy: 'மூலம் தேடுங்கள்',
    searchByTrackingId: 'ட்ராக்கிங் ஐடி/AWB',
    searchByOrderId: 'ஆர்டர் ஐடி / எண்',
    share: 'பகிரவும்',
    status: 'ஸ்டேட்டஸ்',
    submit: 'சமர்ப்பிக்கவும்',
    takeAction: 'நடவடிக்கை எடுக்கவும்',
    time: 'நேரம்',
    trackingId: 'ட்ராக்கிங் ஐடி',
    updateContact: 'தொடர்பு எண்ணை அப்டேட் செய்யவும்',
    updateContactNumber: 'தொடர்பு எண்ணை அப்டேட் செய்யவும்',
    updateContactNumberToastr:
      'இது நாங்கள் உங்களை தொடர்பு கொள்ளவும், ஷிப்மென்ட் குறித்த முக்கிய அப்டேட்களை உங்களுக்கு அனுப்பவும் அனுமதிக்கிறது.',
    updateContactToastr:
      'அப்டேட் செய்ததற்கு நன்றி !, அப்டேட் செய்யப்பட்ட தொடர்பு எண்ணுக்கு அறிவிப்புகளை அனுப்பத் தொடங்குவோம்.',
    updateContactTooltip: 'தொடர்பு எண்ணை மாற்ற இங்கே கிளிக் செய்யவும்',
    veryPoor: 'மிகவும் மோசம்',
    viewDetail: 'விவரங்களை காணவும்',
    veryPoorAbfrl: 'பரிந்துரைக்க வாய்ப்பு குறைந்தது',
    excellentAbfrl: 'பரிந்துரைக்க அதிக வாய்ப்பு',
    veryUnlikely: 'Very Unlikely',
    veryLikely: 'Very Likely',
    viewSummary: 'சுருக்கம் காணவும்',
    viewTracking: 'ட்ராக்கிங்-ஐ பார்க்கவும்',
    wasNotAvailable: 'டெலிவர் செய்ய முயன்றபோது நான் அங்கு இல்லை',
    yes: 'ஆம்',
    youFacedAnyIssue: 'வேறு ஏதேனும் சிக்கலை எதிர்கொண்டீர்களா?',
    youFoundIncorrectLink: 'நீங்கள் தவறான இணைப்பில் உள்ளதாகத் தெரிகிறது.',
    orderPlaceOnDateFn: date => `${date} அன்று ஆர்டர் செய்யப்பட்டது`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    submittedConcern: courier =>
      `உங்கள் கோரிக்கையைச் செயல்படுத்த ${courier} ஐ நாங்கள் தொடர்ந்து பின்தொடர்கிறோம்.`,
    askRatingFeedback: courier =>
      `${courier} செய்தது பற்றிய உங்களது டெலிவரி அனுபவம் எவ்வாறு இருந்தது ?`,
    askRatingFeedbackArvind:
      'NNNOW உடன் உங்கள் ஷாப்பிங் அனுபவம் எப்படி இருந்தது?',
    askRatingFeedbackAbfrl: brandName =>
      `இந்த விநியோக அனுபவத்தின் அடிப்படையில், 0-10 அளவில் நீங்கள் பரிந்துரைக்க எவ்வளவு சாத்தியம் ${brandName} நண்பருக்கு?`,
    askRatingFeedbackTrell:
      'Based on your recent delivery experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience: 'உங்கள் ஒட்டுமொத்த ரிட்டர்ன் அனுபவம் எப்படி இருந்தது?',
    askNonPickedExperience:
      'உங்கள் ஷிப்மென்ட்டை எடுக்க முடியவில்லை என்பதை நாங்கள் கவனித்தோம். உங்கள் ஒட்டுமொத்த ரிட்டர்ன் அனுபவம் எப்படி இருந்தது?',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (title, websiteUrl) =>
      `சரியான ட்ராக்கிங் லிங்க் மற்றும் உங்கள் ஆர்டரைப் பற்றிய தகவலை பெறுவதற்கு ${websiteUrl} இல் உள்ள ${title} ஐத் தொடர்பு கொள்ளவும்.`,
    statusOnDateFn: (status, date) => `${date} அன்று ${status} `,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'என்ன தவறாக நடந்தது என்பதை தயவுசெய்து எங்களிடம் கூறுங்கள்!';
        case 6:
        case 7:
        case 8:
          return 'நாங்கள் எவ்வாறு எங்கள் சேவையை மேம்படுத்த முடியும்?';
        default:
          return 'பாராட்டுங்கள்!';
      }
    },
    edd: (expression, message) => {
      switch (expression) {
        case 1:
          return message;
        case 2:
          return 'விரைவில் டெலிவரி செய்யப்படும்:  உங்களுக்கு அருகிலுள்ள ஒரு இடத்தை வந்தடைந்தது';
        case 3:
          return 'ஷிப்மென்ட் தாமதமானது:  ஷிப்மென்ட்டை உங்களுக்கு விரைவில் வழங்குமாறு கூரியர் பார்ட்னருக்கு அறிவிப்பு வழங்கப்பட்டது.';
        case 4:
          return 'எதிர்பார்த்தபடி போக்குவரத்தில் நகர்ந்து கொண்டிருக்கிறது';
        case 5:
          return 'ஷிப்மென்ட் தாமதமானது:  தளவாடங்கள் குழுவிற்கு அறிவிப்பு வழங்கப்பட்டது.';
        case 7:
          return 'உங்கள் டெலிவரி விருப்பத்தின் அடிப்படையில் அடுத்த 24-48 மணி நேரத்தில் மீண்டும் டெலிவரி வழங்க முயற்சிக்கப்படும்.';
        case 8:
          return message;
        case 9:
          return 'அனுப்பப்பட்ட அதே நாளில் ஏற்றுமதி வழங்கப்படும்';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `ஆர்டர் டெலிவரி செய்யப்படவில்லை: எங்கள் கூரியர் பார்ட்னரால் உங்களை தொடர்பு கொள்ள முடியாததால் உங்கள் ஆர்டரை வழங்க முடியவில்லை.டெலிவரியை பெற உங்களுக்கு ஏதுவான தேதியை தெரிவிக்கவும்.`;
        case 2:
        case 9:
        case 14:
          return `ஆர்டர் டெலிவரி செய்யப்படவில்லை: டெலிவரியை நிராகரிப்பதாக நீங்கள் அளித்த கோரிக்கையின் படி,
      உங்கள் பேக்கஜ்ஜை டெலிவரி செய்ய முடியவில்லை என்று எங்கள் கூரியர் பார்ட்னர் எங்களிடம் தெரிவித்தார்.
    
    ஆர்டரை டெலிவரி பெற 'ஆம்' என்பதை கிளிக் செய்யவும்,
      ஆர்டரை ரத்து செய்ய 'இல்லை' என்பதை கிளிக் செய்யவும்.`;
        case 3:
          return `ஆர்டர் டெலிவரி செய்யப்படவில்லை: நீங்கள் டெலிவரி தேதியை மாற்றுமாறு நீங்கள் கோரியதால்,
        எங்கள் கூரியர் பார்ட்னரால் உங்கள் ஆர்டரை வழங்க முடியவில்லை.நாங்கள் மீண்டும் வந்து உங்களுக்கு டெலிவரி செய்வோம்! டெலிவரியை ஏற்க உங்களுக்கு ஏதுவான தேதியை எங்களுக்குத் தெரிவிக்க விரும்பினால்,
          அது பற்றியத் தகவலை கீழே குறிப்பிடவும்: `;
        case 4:
          return `ஆர்டர் டெலிவரி செய்யப்படவில்லை: எங்கள் கூரியர் பார்ட்னரால் இன்று உங்களது ஆர்டரை டெலிவரி செய்ய முடியவில்லை.
    
    ஆர்டரை டெலிவரி பெறுவதற்கு நீங்கள் விரும்பும் தேதியை குறிப்பிடவும்: `;
        case 5:
          return `Order Undelivered: Our courier partner was unable to deliver your order since we could not get in touch with you.Please provide your alternate contact so that our courier partner can reach you.
    
    நீங்கள் ஆர்டர் செய்யும்போது குறிப்பிட்ட அதே எண்ணில்,
      டெலிவரியின்போது கூரியர் பார்ட்னர் அழைக்க விரும்புகிறீர்களா ? `;
        case 6:
          return `ஆர்டர் டெலிவரி செய்யப்படவில்லை: எங்கள் கூரியர் பார்ட்னரால் உங்களது முகவரியைக் கண்டுபிடிக்க இயலாததால் உங்கள் ஆர்டரை டெலிவரி செய்ய முடியவில்லை.உங்கள் முழுமையான முகவரியை சரியான லேண்ட்மார்க்(அடையாளம்) உடன் கீழே குறிப்பிடவும்.`;
        case 7:
          return `Order Undelivered: Our courier partner was unable to deliver your order since the payment was not ready at the time of delivery.
    
        Don't worry,
    we will ask them to make another attempt at your preferred date for delivery.In case you faced a different issue,
      please provide your feedback below.`;
        case 8:
          return `ஆர்டர் டெலிவரி செய்யப்படவில்லை: எங்கள் கூரியர் பார்ட்னரால் உங்களது முகவரியைக் கண்டுபிடிக்க முடியாததால் ஆர்டரை டெலிவரி செய்ய இயலவில்லை.உங்கள் முழுமையான முகவரியை சரியான லேண்ட்மார்க் உடன் கீழே குறிப்பிடவும்.`;
        case 10:
          return `ஆர்டர் டெலிவரி செய்யப்படவில்லை: எங்கள் கூரியர் பார்ட்னர் நீங்கள் அவர்களது இடத்திற்கு வந்து உங்களது ஷிப்மெண்ட்டை பெறவிருப்பதாக எங்களுக்குத் தெரிவித்தார்.
    
    அவ்வாறு இல்லையென்றால்,
      நீங்கள் டெலிவரி பெற விரும்பும் தேதியை தெரிவிக்கவும்.இதுகுறித்த உங்களது கருத்தை,
        தயக்கமின்றி கீழே உள்ள கமெண்ட் பகுதியில் எங்களுக்கு எழுதுங்கள்: `;
        case 11:
          return `ஆர்டர் டெலிவரியின்போது ஏதேனும் சிக்கலை எதிர்கொண்டீர்களா ? உங்களது கருத்தை கீழே உள்ள கருத்துரை பகுதியில் தெரிவிக்கவும்: `;
        case 12:
          return `ஆர்டர் டெலிவரி செய்யப்படவில்லை: நீங்கள் ஓப்பன் டெலிவரி கோரியதாக எங்கள் கூரியர் பார்ட்னர் தெரிவித்தார்.நீங்கள் டெலிவரி பெற விரும்பும் தேதியை தெரிவிக்கவும்.உங்களது கருத்துக்களை,
          சிறிதும் தயக்கமின்றி கீழே உள்ள கமெண்ட் பகுதியில் குறிப்பிடுங்கள்.உங்களது கருத்துக்களை நாங்கள் எங்கள் குழுவுக்கு அனுப்புவோம்.`;
        case 13:
          return `ஆர்டர் டெலிவரி செய்யப்படவில்லை: எங்கள் கூரியர் பார்ட்னர் துரதிர்ஷ்டவசமாக உங்களது ஷிப்மெண்ட்டை தவறுதலாக வேறொரு இடத்திற்கு அனுப்பிவிட்டார்.உங்களது ஆர்டர் விரைவாக டெலிவரி செய்யப்படுவதை உறுதிசெய்வதற்குத் தேவையான அனைத்தையும் நாங்கள் செய்கிறோம்.நீங்கள் டெலிவரி பெற விரும்பும் தேதியைக் குறிப்பிடவும்.உங்களது கருத்தைப் பகிர்ந்து கொள்ள விரும்பினால்,
            தயவுசெய்து கீழே உள்ள கமெண்ட் பகுதியில் எங்களுக்கு எழுதுங்கள்: `;
        case 0:
        default:
          return `ஆர்டர் டெலிவரி செய்யப்படவில்லை: உங்களது ஷிப்மெண்ட்டில் உள்ள சிக்கலை நாங்கள் ஆராய்ந்து வருகிறோம்.நீங்கள் டெலிவரி பெற விரும்பும் தேதியை தெரிவிக்கவும்.உங்களது கருத்துக்களை,
              தயக்கம் ஏதுமின்றி கீழே உள்ள கமெண்ட் பகுதியில் எங்களுக்கு தெரிவியுங்கள்: `;
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'கடுமையாக முயற்சித்தும், நீங்கள் குறிப்பிட்ட முகவரியைக் கண்டுபிடிக்க முடியவில்லை என்று கூரியர் பார்ட்னர் எங்களுக்குத் தெரிவிக்கிறார். முகவரி பற்றிய கூடுதல் விவரங்களை எங்களுக்கு அளிக்க முடியுமா?',
      timing: {
        a:
          'எங்கள் கூரியர் பார்ட்னர்கள் ஒவ்வொரு டெலிவரிக்கும் 3 முறை வரை முயற்சி செய்கிறார்கள். முதல் முயற்சியில் டெலிவரி செய்ய முடியாத நிலையில், ஞாயிற்றுக்கிழமை மற்றும் பொது விடுமுறை நாட்களைத் தவிர்த்து மறுநாள் மீண்டும் முயற்சிக்கிறோம்.',
        b:
          'ஆர்டர் டெலிவரி பெறுவதற்கு உங்கள் முகவரியில் யாராவது இருக்கிறார்களா என்பதை தயவுசெய்து உறுதி செய்து கொள்ளுங்கள்.',
        c: 'குறிப்பிட்ட நாட்களில் நீங்கள் டெலிவரி பெற விரும்புகிறீர்களா?'
      },
      other: () =>
        'ஆர்டரை உங்களுக்கு டெலிவரி செய்வதற்கு எங்களுக்கு உதவக்கூடிய கூடுதல் விவரங்கள் இருந்தால் தயவுசெய்து பகிர்ந்து கொள்ளுங்கள்.',
      phone: {
        a:
          'சில நேரங்களில், எங்களது கூரியர் பார்ட்னர்கள் லேண்ட்லைன் தொலைபேசியில் இருந்து அழைக்கிறார்கள், அவற்றை நீங்கள் ஸ்பேம் (Spam) எனக் கருதக்கூடும். இதனால், மீண்டும் முயற்சிக்குமாறு கூரியர் பார்ட்னரிடம் அறிவுறுத்துவோம்.',
        b: () =>
          'Do you want the courier partner to call you on the same number as given during order placement?'
      },
      reject:
        'உங்களது ஷிப்மெண்ட்டிற்கு நாங்கள் மற்றொரு டெலிவரி முயற்சியை மேற்கொள்ள வேண்டும் என நீங்கள் விரும்பினால் தயவுசெய்து உறுதிப்படுத்தவும்: '
    },
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `பிக் - அப் செய்ய இயலவில்லை: எங்களது கூரியர் பார்ட்னரால் உங்களுடன் தொடர்பு கொள்ள முடியாததால் உங்கள் ஆர்டரை பிக் - அப் செய்ய முடியவில்லை.உங்களது தொடர்பு எண் அல்லது முகவரியை வழங்கவும்.`;
        case 2:
          return `பிக் - அப் செய்ய இயலவில்லை: பிக் - அப் கோரிக்கையை ரத்து செய்வதற்கான உங்கள் வேண்டுகோளின் படி,
      உங்களது ஷிப்மெண்ட்டை பிக் - அப் செய்ய இயலவில்லை என்று எங்கள் கூரியர் பார்ட்னர் தெரிவித்தார்.
    
        பிக் - அப் செய்வதற்கு 'ஆம்' என்பதை க்ளிக் செய்க,
          பிக் - அப்பை ரத்து செய்ய 'இல்லை' என்பதை கிளிக் செய்க.`;
        case 3:
        case '3a':
          return `பொருள் சேதமடைந்துள்ளது அல்லது பயன்படுத்தப்பட்டுள்ளது.`;
        case 4:
          return `பொருளின்(Product) நிறம் அல்லது பிராண்ட் வேறாக உள்ளது.`;
        case 5:
        case '5a':
          return `மொத்த பொருட்களின் எண்ணிக்கையானது,
            கோரிய அளவைக் காட்டிலும் மாறுபட்டுள்ளது.`;
        case 6:
          return `பிக் - அப் செய்ய இயலவில்லை: தயவுசெய்து,
              நீங்கள் விரும்பும் பிக் - அப் தேதியை தெரிவிக்கவும்.`;
        case 7:
          return `பிக் - அப் செய்ய இயலவில்லை:  : எங்கள் கூரியர் நிறுவனத்திற்கு உங்களது முகவரியில் தற்போது சேவை இல்லாததால் எங்களால் பிக் - அப் செய்ய முடியவில்லை.`;
        case 8:
        case '8a':
          return 'பிக்-அப் செய்வதற்காக பல முறை முயற்சித்தும், பிக் - அப் செய்ய முடியவில்லை என எங்கள் கூரியர் தரப்பில் தெரிவிக்கப்பட்டது.எனவே தயவுசெய்து, உங்கள் வசதிக்கேற்ற தேதியையும், பிக் - அப் செய்வதற்கான இடத்தையும் குறிப்பிடவும்.இதுதொடர்பான உங்களது கருத்தை, தயக்கமின்றி கமெண்ட் பகுதியில் எழுதுங்கள்.';
        case 9:
          return 'கூரியர் நிறுவனத்தால் உங்களது பொருள் / (பொருட்களை) பிக் - அப் செய்ய முடியவில்லை.தயவுசெய்து, அடுத்த வேலை நாளில் பிக் - அப் செய்வதற்கான பொருள் / (பொருட்களை) எளிதாக பிக் - அப் செய்யும் வகையில் கையோடு வைத்திருங்கள்.நீங்கள் எந்தவொரு கருத்தையும் பகிர்ந்து கொள்ள விரும்பினால், தயவுசெய்து கமெண்ட் பகுதியில் தெரிவியுங்கள்.';
        case 10:
          return 'பிக் - அப் கோரிக்கையை ஏற்க நீங்கள் மறுத்துவிட்டதாக எங்கள் கூரியர் பார்ட்னர் குறிப்பிட்டார், அவ்வாறு இல்லை என்றால் தயவுசெய்து அடுத்தமுறை பிக் - அப் செய்வதற்காக உங்களது விருப்பமான தேதியை தெரிவிக்கவும்.இது குறித்து கருத்துத் தெரிவிக்க விரும்பினால், தயக்கமின்றி கீழே உள்ள கமெண்ட் பகுதியில் எங்களுக்கு தெரிவியுங்கள்.';
        case 11:
          return 'எங்கள் கூரியர் பார்ட்னர், பொருள் பிக் - அப் செய்வதற்குத் தயாராக இல்லை என்று குறிப்பிட்டார், அவ்வாறு இல்லை எனில் தயவுசெய்து அடுத்த முறை பிக் - அப் செய்வதற்கான உங்களது விருப்பத் தேதியை தெரிவிக்கவும்.இதுகுறித்த கருத்துக்களை, தயக்கமின்றி கீழே உள்ள கமெண்ட் பகுதியில் எங்களுக்கு தெரிவியுங்கள்.';
        case 13:
          return `உங்கள் முகவரியைக் கண்டுபிடிக்க முடியாததால், உங்கள் பேக்கேஜை அவர்களால் எடுக்க முடியவில்லை என்று எங்கள் கூரியர் கூட்டாளர் எங்களுக்குத் தெரிவித்தார். <br /><br /> ஏதேனும் அடையாளங்கள் உட்பட, உங்கள் முழு முகவரியையும் கீழே வழங்கவும்.`;
        case 14:
          return `நீங்கள் கிடைக்காததால், உங்கள் பிக்-அப் கோரிக்கையைச் செயல்படுத்த முடியவில்லை என்று எங்கள் கேரியர் பார்ட்னர் எங்களுக்குத் தெரிவித்தார். நீங்கள் விரும்பும் பிக்அப் தேதியைத் தேர்ந்தெடுக்கவும்.`;
        case 0:
        default:
          return `பிக் - அப் செய்ய இயலவில்லை: உங்கள் கோரிக்கை தொடர்பான சிக்கலை நாங்கள் ஆராய்ந்து வருகிறோம்.தயவுசெய்து,
        பிக் - அப் செய்வதற்காக நீங்கள் விரும்பும் தேதியை தெரிவிக்கவும்.உங்களது கருத்துக்களை தயக்கமின்றி,
          கீழே உள்ள கமெண்ட் பகுதியில் எங்களுக்கு தெரிவியுங்கள்: `;
      }
    },
    nprFdForm: {
      address:
        'கடுமையாக முயற்சித்தும், நீங்கள் குறிப்பிட்ட முகவரியைக் கண்டுபிடிக்க முடியவில்லை என்று கூரியர் பார்ட்னர் எங்களுக்குத் தெரிவிக்கிறார். முகவரி பற்றிய கூடுதல் விவரங்களை எங்களுக்கு அளிக்க முடியுமா?',
      timing: {
        a:
          'எங்கள் கூரியர் பார்ட்னர்கள் ஒவ்வொரு பிக்-அப்பிற்கும் 3 முறை முயற்சி செய்கிறார்கள். முதல் முயற்சியில் பார்சலை பிக்-அப் செய்ய முடியாத நிலையில், ஞாயிற்றுக்கிழமை மற்றும் பொது விடுமுறை நாட்களைத் தவிர்த்து மறுநாள் மீண்டும் முயற்சிக்கிறோம்.',
        b:
          'பிக்-அப் செய்ய வரும்போது பொருளை ஒப்படைப்பதற்கு உங்கள் முகவரியில் யாராவது இருக்கிறார்களா என்பதை தயவுசெய்து உறுதி செய்து கொள்ளுங்கள்.',
        c:
          'குறிப்பிட்ட நாட்களில் நாங்கள் பிக்-அப் செய்ய வரவேண்டும் என விரும்புகிறீர்களா?'
      },
      other:
        'ஆர்டரை பிக்-அப் செய்வதற்கு எங்களுக்கு உதவக்கூடிய கூடுதல் விவரங்கள் இருந்தால் தயவுசெய்து பகிர்ந்து கொள்ளுங்கள். அதேநேரத்தில், பொருள்/ அளவு பொருந்தாதது குறித்த உங்களது கோரிக்கையை மீண்டும் சரிபார்த்துக் கொள்ளவும்.',
      phone: {
        a:
          'சில நேரங்களில், எங்களது கூரியர் பார்ட்னர்கள் லேண்ட்லைன் தொலைபேசியில் இருந்து அழைக்கிறார்கள், அவற்றை நீங்கள் ஸ்பேம் (Spam) எனக் கருதக்கூடும். இதனால், மீண்டும் பிக்-அப்பிற்கு முயற்சிக்குமாறு கூரியர் பார்ட்னரிடம் நாங்கள் அறிவுறுத்துவோம்.',
        b:
          'நீங்கள் ஆர்டர் செய்யும்போது குறிப்பிட்ட அதே எண்ணில், டெலிவரியின்போது கூரியர் பார்ட்னர் அழைக்க விரும்புகிறீர்களா?'
      },
      reject:
        'உங்களது ஷிப்மெண்ட்டிற்கான பிக்-அப்  முயற்சியை நாங்கள் ரத்து செய்ய வேண்டும் என நீங்கள் விரும்பினால் தயவுசெய்து உறுதிப்படுத்தவும்: '
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `இந்த ஆர்டரை மேற்கொள்வதற்குப் பயன்படுத்தப்படும் மொத்த தொகை ${creditValue} இந்த ஆர்டருக்கான உங்கள் ஸ்டோர் கணக்கில் மீண்டும் வரவு வைக்கப்படும்.`,
      totalAsCredit: creditValue =>
        `ஸ்டோர் கணக்கில் வரவு வைக்கப்பட்ட தொகையில் மீதமுள்ள தொகை ${
          creditValue > 0 ? 'மீதமுள்ள' : ''
        } -ஐ திரும்பப் பெற விரும்புகிறீர்களா? `,
      totalAsCreditNew:
        'தயவுசெய்து நீங்கள் விரும்பும் பணத்தைத் திருப்பிச் செலுத்தும் முறையைத் தேர்வுசெய்க',
      prepaid: calculatedAmt =>
        `உங்கள் அசல் ஆன்லைன் பரிவர்த்தனையில் ரூ.  ${calculatedAmt} -ஐ திரும்பப் பெறுவீர்கள்.`,
      cod: calculatedAmt =>
        `உங்கள் வங்கிக் கணக்கு விபரங்களை அளிக்கவும். நீங்கள் குறிப்பிடும் உங்களது வங்கிக் கணக்கில் (ரூ. ${calculatedAmt}) -ஐ பெறுவீர்கள்.`,
      refundTotalAsCredit: totalAmount =>
        `ஸ்டோர் கிரெடிட்டுக்கு ரீஃபண்ட் செய்யவும்: ${totalAmount}`,
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: () =>
        'உங்கள் பணத்தைத் திரும்பப்பெறும் தொகை உங்கள் பிராண்ட் வரவுகளுக்கு மீண்டும் வரவு வைக்கப்படும்',
      defaultPrepaid: (totalAmount, currency) =>
        `உங்கள் ரீஃபண்ட் தொகையான ${currency ??
          ''} ${totalAmount} ஆதாரத்தில் மீண்டும் வரவு வைக்கப்படும்`
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'Shipped',
    readyForDispatch: 'Ready For Dispatch',
    trackOrder: 'Track Order',
    deliveryFeedback: 'டெலிவரியை பற்றிய கருத்து',
    raiseConcern: 'புகார் அளிக்கவும்',
    expired: 'Expired',
    downloadApp: 'Download the app',
    allRightsReserved: 'All rights reserved',
    emailUs: 'Email us: ',
    callUs: 'Call us: ',
    poweredBy: 'Powered by ',
    login: 'Login',
    username: 'Username',
    psswrd: 'Password',
    returnRequestReceived: 'Return request received',
    returnPlaceDate: 'Return placed date',
    reason: 'Reason',
    anyOtherComment: 'Comment',
    addImage: 'Add Image',
    quantity: 'Quantity',
    back: 'Back',
    edit: 'Edit',
    returnSummary: 'Return Summary',
    returnStatus: 'Return Status',
    pickup: 'Pickup',
    selectReversePickupAddress: 'Select Reverse Pickup Address',
    selectOrAddAddress: 'Select/Add Pickup Address',
    phoneFormat: format =>
      `Please enter a phone number in the format ${format} without the Phone Code`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Request Pickup',
    selfShip: 'Self Ship',
    review: 'Review',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Pickup Date',
    orderStatus: 'Order Status',
    expectedPickupDate: 'Expected Pickup Date',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Approved',
    goHome: 'Go Home',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Update Pickup Address',
    fullName: 'Full Name',
    emailId: 'Email ID',
    city: 'City',
    area: 'منطقة',
    state: 'State',
    country: 'Country',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    bankDetail: 'Bank Details For Refund',
    upiDetail: 'UPI Details',
    brandCredits: () => 'பிராண்ட் வரவு',
    backToSource: 'மூலத்திற்குத் திரும்பு',
    bankAccount: 'வங்கி கணக்கு',
    accountHolderName: 'Account Holder Name',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Account Number',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Fetch',
    enterReversePickupAddress: 'Enter Reverse Pickup Address',
    pleaseUploadSupportingImage: imageQuantity =>
      `Please upload ${imageQuantity} supporting image`,
    processing: 'Processing',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} images required for this request`,
    returns: 'Returns',
    exchange: () => 'Return and Exchange',
    enterYourOrderInformation: 'Enter your order information to get started.',
    ourReturnExchangePolicy: () => 'Our Returns & Exchange Policy',
    refundPolicy: 'Refund Policy',
    termsConditions: 'Terms & Conditions',
    stepOf: currentStep => `Step ${currentStep} of 3`,
    allPriceShownPerItem: 'All prices shown are at item level',
    qtyPerOrderNote: 'Note: Qty shows original ordered item quantity',
    qty: 'Qty',
    returnedQty: 'Returned quantity',
    placeFreshPickupRequest: 'Pickup cancelled, place fresh pickup request.',
    remove: 'Remove',
    return: 'Return',
    startReturn: 'Start Return',
    whyAreYouReturning: 'Why are you returning the product?', // optional
    whyYouWantReturn: 'Why do you want to return?',
    selfShipMode:
      'As you selected Self Ship Mode. Please pack the items securely to prevent any loss or damage during transit.',
    requestPlacedDate: 'Request Placed Date',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'விரைவில் அனுப்பப்படும்',
    max200Chars: 'Max. 200 characters',
    select: 'Select',
    charsLeft: chars => `${chars} characters left`
  },
  id: {
    courier: 'Kurir',
    trackingId: 'Nomor AWB',
    orderId: 'Nomor order',
    lastUpdate: 'Update Terakhir',
    orderPlacedOn: 'Tanggal Order Diproses',
    orderType: 'Jenis pesanan',
    riderInfo: 'Info Pengendara',
    orderPlaced: 'Pesanan ditempatkan',
    dispatched: 'Telah Dikirim',
    inTransit: 'Sedang Transit',
    failedDelivery: 'Pengiriman Gagal',
    ofd: 'Dalam Pengantaran',
    delivered: 'Tiba Di Tujuan',
    pickupRequested: 'Permintaan Pengambilan',
    updatedOn: 'Diperbarui Aktif',
    returnInstructions: 'Instruksi Percobaan Ulang',
    returnPlaced: 'Kembali Ditempatkan',
    pickupCancelled: 'Pengiriman Dibatalkan',
    ofp: 'Dalam Pengantaran',
    pickedup: 'Diambil',
    returned: 'Kembali',
    reverseReturned: 'Kembali',
    readyToShip: 'Order siang untuk dikirim',
    lost: 'Hilang',
    damaged: 'Rusak',
    cancelled: 'Dibatalkan',
    requestPlaced: 'Permintaan diterima',
    confirmed: 'Dikonfirmasi',
    requestAccepted: 'Permintaan diterima',
    requestAcceptedWithDate: date =>
      `Pengembalian Anda telah diterima pada ${date}`,
    confirm: 'Mengonfirmasi',
    requestReturn: 'Permintaan Pengembalian',
    pickupFailed: 'Pengambilan gagal',
    reachedW: 'Sampai ke Gudang',
    requestCancelled: 'Permintaan dibatalkan',
    requestRejected: 'Permintaan Ditolak',
    location: 'Lokasi',
    time: 'Waktu',
    remark: 'Keterangan',
    shippingLabel: 'Label pengiriman',
    shippingLabelInProgress:
      'Mengambil label dari operator, label tersebut akan tersedia untuk diunduh dalam beberapa menit',
    shippingLabelReady:
      'Label pengiriman Anda telah dibuat, sekarang Anda dapat mendownloadnya',
    pleaseTellUs: 'Beri tahu kami apa yang terjadi?',
    pressNextBtn: 'Tekan tombol selanjutnya diakhir pilihan',
    agentDidntCall: 'Agen Kurir tidak menghubungi saya',
    noAttemptToAddress: 'Tidak ada percobaan pengantaran ke alamat pengantaran',
    wasNotAvailable: 'Penerima tidak ditempat ketika pengiriman',
    nprAgentDidntCall: 'Agen Penjemputan tidak menelepon saya',
    nprNoAttemptToAddress:
      'Tidak ada upaya yang dilakukan ke alamat penjemputan',
    nprWasNotAvailable:
      'Saya tidak bisa dihubungi saat upaya penjemputan dilakukan',
    doNotWantProduct: 'Produk sudah tidak diinginkan',
    doNotWantReturnProduct: 'Tidak ingin mengembalikan produk lagi',
    others: 'Yang lain',
    yes: 'Ya',
    no: 'Tidak',
    preferredDate: 'Tanggal pengiriman pilihan',
    nprPreferredDate: 'Tanggal pengambilan yang diinginkan',
    landmark: 'Bangunan',
    address: 'Alamat',
    pincode: 'Kode Pin',
    provideAltNo: 'Mohon cantumkan nomor alternatif',
    comments: 'Komentar',
    searchBy: 'Pencarian',
    checkCurrentStatus: 'Cek status pengiriman anda',
    trackYourOrder: 'Lacak Pesanan Anda',
    incorrectSearch:
      'Pesanan/Paket yang anda lacak belum siap dikirim atau nomor yang anda masukkan salah.',
    viewTracking: 'Lacak Pesanan',
    on: 'on',
    status: 'Status',
    orderDetails: 'Rincian Pesanan',
    noScan1: 'Order siap untuk dikirim',
    noScan2: 'Detail pelacakan akan segera tersedia',
    addressErr: 'Alamat tidak boleh kosong atau kurang dari 10 karakter.',
    pincodeErr: 'Mohon masukkan 6 digit Kode pin',
    pincodeNotServiceable:
      'Kode Pin tidak tersedia, silahkan kirimkan pesanan anda pada kami',
    provideLandmark:
      'Mohon cantumkan nama bangunan untuk Kurir Partner melacak alamat anda.',
    landmarkErr:
      'Nama bangunan tidak boleh kosong atau kurang dari 5 karakter.',
    dateErr: 'Silahkan masukan tanggal',
    commentErr: 'Komentar tidak boleh kosong',
    addressCharLimit:
      'Silahkan masukkan minimal 10 karakter atau tidak lebih dari 600.',
    phoneErr: 'Silahkan masukkan 10 angka nomor handphone anda.',
    emailidErr: 'Silahkan masukan email anda.',
    invalidImageErr: 'Format file tidak didukung',
    chooseOption: 'Silahkan pilih opsi.',
    actionInProgress: 'Dalam Proses',
    feedbackToastr1:
      'Terimakasih atas masukan anda! Senang jika melayani anda kembali.',
    feedbackToastr2:
      'Terimakasih atas masukan anda! Kami akan memberikan pengalaman yang lebih baik selanjutnya!',
    abfrlFeedbackToast:
      'Thank you for the feedback, redirecting you to LimeSurvey',
    ratingErr: 'Penilaian tidak boleh kosong',
    apiErr:
      'Kami tidak dapat memproses permintaan tersebut, silakan coba lagi nanti',
    updateContactToastr:
      'Terimakasih atas pembaharuannya! kami akan mengirimkan notifikasi pada nomor kontak yang telah diperbaharui.',
    noDataFound:
      'Pesanan/Paket yang anda lacak belum siap dikirim atau nomor yang anda masukkan salah.',
    veryPoorAbfrl: 'least likely to recommend',
    veryPoor: 'Sangat Tidak Baik',
    excellentAbfrl: 'highly likely to recommend',
    excellent: 'Sangat Baik',
    veryUnlikely: 'Sangat Tidak Mungkin',
    veryLikely: 'Sangat mungkin',
    submit: 'Masukkan',
    youFacedAnyIssue: 'Apakah anda menghadapi masalah lain?',
    updateContact: 'Pembaharuan Kontak',
    updateContactTooltip: 'Klik disini untuk perubahan nomor kontak',
    myOrders: 'pesananku',
    myOrdersTooltip: 'Klik di sini untuk melihat pesanan Anda',
    takeAction: 'Ambil tindakan',
    takeActionTooltip: 'Klik disini untuk menyampaikan permasalahan',
    next: 'Selanjutnya',
    anyOtherDate: 'Ada tanggal lain?',
    share: 'Bagikan',
    expectedDelivery: 'Perkiraan waktu pengiriman',
    viewDetail: 'Lihat rincian',
    viewSummary: 'Lihat rangkuman',
    inputFeedback: 'Mohon bagikan masukan',
    feedbackMatters: 'Masukan Anda penting!',
    ratingFeedback: 'Terimakasih atas masukan berharga anda!',
    youRatedUs: 'Anda menilai kami',
    youCommented: 'Anda berkomentar',
    pageNotFound: 'Halaman tidak ditemukan',
    ops404: 'Ups, itu 404.',
    youFoundIncorrectLink: 'Sepertinya anda mendapatkan tautan yang salah',
    contactNumber: 'Nomor Kontak',
    updateContactNumber: 'Pembaharuan Nomor Kontak',
    updateContactNumberToastr:
      'Hal ini mengizinkan kami untuk menghubungi anda dan mengirimkan permberitahuan terkait pengiriman.',
    searchByTrackingId: 'Nomor AWB',
    searchByOrderId: 'Nomor Invoice',
    langName: 'English',
    rateUs: 'Berapa nilai yang ingin Anda berikan untuk:',
    orderPlaceOnDateFn: date => `Order diterima pada ${date}`,
    recommendToFriend: npsQuestion =>
      `${
        npsQuestion ===
        'How likely is it that you would recommend us to a friend or colleague'
          ? 'Seberapa besar Anda akan merekomendasikan kurir ini kepada teman atau kolega'
          : npsQuestion
      }`,
    submittedConcern: courier =>
      `Kami akan menindaklanjuti dengan ${courier} untuk memproses pesanan anda.`,
    askRatingFeedback: courier =>
      `Bagaimana pengalaman pengiriman anda dengan ${courier}?`,
    askRatingFeedbackTrell:
      'Based on your recent delivery experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvind: 'How was your shopping experience with NNNOW?',
    askRatingFeedbackAbfrl: brandName =>
      `Berdasarkan pengalaman pengiriman ini, pada skala 0-10, seberapa besar kemungkinan Anda merekomendasikan ${brandName} kepada teman?`,
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience:
      'Bagaimana pengalaman pengembalian Anda secara keseluruhan?',
    askNonPickedExperience:
      'Kami melihat kiriman Anda tidak dapat diambil. Bagaimana pengalaman pengembalian Anda secara keseluruhan?',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (title, websiteUrl) =>
      `Silahkan menghubungi ${title} pada ${websiteUrl} untuk resi yang benar dan informasi terkait pesanan anda.`,
    statusOnDateFn: (status, date) => `${status} pada ${date}`,
    npsQuestions: {
      'How satisfied are you with the timeliness of order delivery?':
        'Seberapa puaskah Anda dengan ketepatan waktu pengiriman pesanan?',
      'Rate the behavior of the order courier/shipper':
        'Nilai perilaku kurir / pengirim pesanan',
      'Rate the condition of the order box / box when receiving the package':
        'Nilai kondisi box / kotak pesanan saat menerima paket',
      'Rate overall delivery performance':
        'Nilai kinerja pengiriman secara keseluruhan'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'Tolong beri tahu kami apa yang salah!';
        case 6:
        case 7:
        case 8:
          return 'Bagaimana kita bisa meningkatkannya?';
        default:
          return 'Berikan pujian!';
      }
    },
    edd: (expression, message) => {
      let days = null;
      if (expression === 1) {
        const digit = message.match(/\d+/);
        days = digit[0];
      }
      switch (expression) {
        case 1:
          return `Pengiriman akan dilakukan dalam waktu ${days} hari setelah dikirim`;
        case 2:
          return 'Akan segera tiba: Telah mencapai lokasi disekitar anda.';
        case 3:
          return 'Pengiriman terlambat: Kurir kami akan memberitahukan pengiriman tercepat dalam waktu dekat.';
        case 4:
          return 'Bergerak sesuai jadwal.';
        case 5:
          return 'Pengiriman terlambat: Tim logistik telah mendapat pemberitahuan';
        case 7:
          return 'Akan dilakukan pengiriman ulang dalam 24-48 jam berdasarkan pilihan pengiriman anda.';
        case 8:
          return message;
        case 9:
          return 'Pengiriman akan dikirimkan pada hari yang sama setelah dikirim';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `Pengiriman Gagal: Kurir kami tidak mampu melakukan pengantaran paket anda dikarenakan anda tidak dapat dihubungi. Mohon mencantumkan tanggal pengiriman paket`;
        case 2:
        case 9:
        case 14:
          return `Pengiriman Gagal: Kurir kami memberitahukan bahwa paket anda tidak dapat dikirim dikarenakan paket telah anda tolak. Klik 'Ya' untuk mengirimkan paket anda, 'Tidak' untuk membatalkan pesanan.`;
        case 3:
          return `Pengiriman Gagal: Kurir kami tidak mampu melakukan pengantaran paket anda dikarenakan anda melakukan permintaan untuk penjadwalan ulang. Kami akan kembali lagi dan mengantarkan paket anda!`;
        case 4:
          return `Pengiriman Gagal: Kurir kami tidak mampu melakukan pengantaran paket hari ini. Mohon untuk mencantumkan permintaan waktu pengantaran:`;
        case 5:
          return `Pesanan tidak terkirim: Mitra kurir kami tidak dapat mengirimkan pesanan Anda karena kami tidak dapat menghubungi Anda. Harap berikan kontak alternatif Anda agar mitra kurir kami dapat menghubungi Anda. <br /><br />Apakah Anda ingin mitra kurir menghubungi Anda di nomor yang sama seperti yang diberikan saat pemesanan?`;
        case 6:
          return `Pengiriman Gagal: Kurir kami tidak mampu melakukan pengantaran paket anda dikarenakan lokasi anda tidak ditemukan. Mohon mencantumkan alamat lengkap dibawah:`;
        case 7:
          return `Pesanan tidak terkirim: Mitra kurir kami tidak dapat mengirimkan pesanan Anda karena pembayaran belum siap pada saat pengiriman. <br /><br />Jangan khawatir, kami akan meminta mereka untuk melakukan pengiriman lagi pada tanggal pengiriman yang Anda inginkan. Jika Anda menghadapi masalah lain, berikan tanggapan Anda di bawah.`;
        case 8:
          return `Pengiriman Gagal: Kurir kami tidak mampu melakukan pengantaran paket anda dikarenakan lokasi anda tidak ditemukan. Mohon mencantumkan alamat lengkap dibawah:`;
        case 10:
          return `Pesanan tidak terkirim: Mitra kurir kami memberi tahu kami bahwa Anda akan mengambil kiriman dari lokasi mereka. <br /><br /> Jika tidak demikian, harap berikan tanggal pengiriman pilihan Anda. Jika Anda memiliki kekhawatiran, silakan tulis kepada kami di bagian komentar di bawah:`;
        case 11:
          return `Apakah anda menghadapi masalah pada saat pengantaran? Mohon berikan masukan anda dibawah:`;
        case 12:
          return `Pesanan tidak terkirim: Mitra kurir kami memberi tahu kami bahwa Anda meminta pengiriman terbuka. Harap berikan tanggal pengiriman pilihan Anda. Jika Anda memiliki kekhawatiran, jangan ragu untuk menulis kepada kami di bagian komentar di bawah dan kami akan meneruskan masukan tersebut ke tim kami.`;
        case 13:
          return `Pesanan tidak terkirim: Sayangnya mitra Kurir kami mengarahkan kiriman Anda ke lokasi yang salah. Kami melakukan segalanya untuk memastikan pengiriman cepat kepada Anda. Harap berikan tanggal pengiriman pilihan Anda. Jika Anda ingin memberikan masukan, silakan tulis kepada kami di bagian komentar di bawah:`;
        case 0:
        default:
          return `Pengiriman Gagal: Kami menemukan masalah pada pengantaran anda. Silahkan memasukan tanggal pengantaran anda. Jika anda memiliki masukan, silahkan untuk menuliskan komentar dibawah:`;
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'Mitra kurir memberi tahu kami bahwa mereka mencoba menghubungi alamat Anda tetapi tidak dapat menemukan alamat Anda. Bisakah Anda memberi kami rincian lebih lanjut tentang alamatnya?',
      timing: {
        a:
          'Mitra kurir kami mencoba 3 kali untuk pengiriman. Dalam hal paket tidak dapat dikirimkan pada kali pertama, kami akan mencoba lagi keesokan harinya kecuali hari Minggu dan hari libur.',
        b:
          'Mohon pastikan ada seseorang yang tersedia di alamat pengiriman Anda untuk menerima paket.',
        c: 'Apakah Anda ingin mendapatkan upaya pengiriman pada hari tertentu?'
      },
      other: () =>
        'Silakan bagikan detail tambahan apa pun yang dapat membantu kami mengirimkan pesanan.',
      phone: {
        a:
          'Dalam beberapa kasus, mitra kurir menelepon dari telepon rumah yang mungkin terasa seperti spam bagi Anda. Kami akan meminta mitra kurir untuk mencoba kembali pengirimannya.',
        b: () =>
          'Apakah Anda ingin mitra kurir menghubungi Anda di nomor yang sama seperti yang diberikan saat pemesanan?'
      },
      reject:
        'Harap konfirmasi jika Anda ingin kami melakukan upaya pengiriman lain untuk kiriman Anda:'
    },
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `Pengambilan Gagal: Mitra kurir kami tidak dapat mengambil pesanan karena mereka tidak dapat menghubungi Anda. Harap berikan nomor kontak atau alamat Anda`;
        case 2:
          return `Pengambilan Gagal: Mitra kurir kami memberi tahu kami bahwa paket Anda tidak dapat diambil sesuai permintaan Anda untuk membatalkan permintaan pengambilan. <br /><br /> Klik 'Ya' untuk mengambil, 'Tidak' untuk membatalkan pengambilan.`;
        case 3:
        case '3a':
          return `Produk rusak atau digunakan`;
        case 4:
          return `Entah warna produk atau mereknya berbeda`;
        case 5:
        case '5a':
          return `Jumlah total item berbeda dari yang diminta`;
        case 6:
          return `Pengambilan Gagal: Harap berikan tanggal pengambilan pilihan Anda.`;
        case 7:
          return `Pengambilan Gagal: Perusahaan kurir kami tidak dapat mengambil karena alamat Anda tidak dapat dijangkau oleh kurir kami.`;
        case 8:
        case '8a':
          return 'Kurir kami memberi tahu bahwa beberapa upaya telah dilakukan untuk pengambilan tetapi hal yang sama tidak dapat diproses, oleh karena itu harap berikan tanggal pengambilan yang diinginkan berdasarkan ketersediaan Anda, jika Anda memiliki kekhawatiran, silakan tulis kepada kami di bagian komentar di bawah';
        case 9:
          return 'Kurir tidak dapat mencoba mengambil barang Anda, harap simpan barang tersebut karena pengambilan akan dilakukan pada hari kerja berikutnya, jika Anda ingin memberikan masukan, silakan tulis di komentar bagian';
        case 10:
          return 'Mitra Kurir kami memberi tahu bahwa Anda menolak untuk menerima permintaan Penjemputan, jika hal ini tidak terjadi maka silakan berikan preferensi berikutnya pada tanggal Penjemputan Anda. Jika Anda memiliki kekhawatiran, silakan tulis kepada kami di bagian komentar di bawah';
        case 11:
          return 'Mitra Kurir kami memberi tahu bahwa Produk belum siap untuk diambil, jika tidak, harap berikan pilihan berikutnya pada tanggal Pengambilan Anda. Jika Anda memiliki kekhawatiran, silakan tulis kepada kami di bagian komentar di bawah';
        case 13:
          return `Mitra kurir kami memberi tahu kami bahwa mereka tidak dapat mengambil paket Anda karena mereka tidak dapat menemukan alamat Anda. <br /><br /> Mohon berikan alamat lengkap Anda, termasuk landmarknya, di bawah ini.`;
        case 14:
          return `Mitra Kurir kami memberi tahu kami bahwa mereka tidak dapat memproses permintaan penjemputan Anda karena Anda tidak dapat hadir. Silakan pilih tanggal penjemputan yang Anda inginkan.`;
        case 0:
        default:
          return `Pengambilan Gagal: Kami mengidentifikasi masalah pada permintaan Anda. Harap berikan tanggal penjemputan pilihan Anda. Jika Anda memiliki kekhawatiran, silakan tulis kepada kami di bagian komentar di bawah:`;
      }
    },
    nprFdForm: {
      address:
        'Mitra kurir memberi tahu kami bahwa mereka mencoba menghubungi alamat Anda tetapi tidak dapat menemukan alamat Anda. Bisakah Anda memberi kami rincian lebih lanjut tentang alamatnya?',
      timing: {
        a:
          'Mitra kurir kami mencoba 3 kali untuk mengambil. Dalam hal paket tidak dapat diambil pertama kali, kami akan mencoba lagi keesokan harinya kecuali hari Minggu dan hari libur.',
        b:
          'Mohon pastikan ada seseorang di alamat penjemputan Anda untuk menyerahkan paket.',
        c: 'Apakah Anda ingin mencoba penjemputan pada hari tertentu?'
      },
      other:
        'Silakan bagikan detail tambahan apa pun yang dapat membantu kami mengambil pesanan, juga harap verifikasi silang permintaan pengembalian Anda jika ada ketidakcocokan produk/kuantitas',
      phone: {
        a:
          'Dalam beberapa kasus, mitra kurir menelepon dari telepon rumah yang mungkin terasa seperti spam bagi Anda. Kami akan meminta mitra kurir untuk mencoba mengambil kembali.',
        b:
          'Apakah Anda ingin mitra kurir menghubungi Anda di nomor yang sama seperti yang diberikan saat pemesanan?'
      },
      reject:
        'Please confirm if you want us to cancel the pickup attempt for your shipment:'
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `Total <strong>${creditValue}</strong> credits used for payment of this order will be credited back to your store account against this order`,
      totalAsCredit: creditValue =>
        `Would you like refund for ${
          creditValue > 0 ? 'rest of' : ''
        } the amount paid as store credit?`,
      totalAsCreditNew:
        'Silakan pilih mode pembayaran pengembalian dana pilihan Anda',
      prepaid: (calculatedAmt, currency) =>
        `Your refund amount of <strong>${currency} ${calculatedAmt}</strong> will be credited back to the source.`,
      cod: (calculatedAmt, brandName) =>
        ({
          rforrabbit:
            'Please enter your bank account details. You will receive cash paid in the bank account provided below.'
        }[brandName] ??
        `Please enter your bank account details. You will receive cash paid(Rs. <strong>${calculatedAmt}</strong>) in the bank account provided below.`),
      refundTotalAsCredit: (totalAmount, brandName) =>
        ({
          TGM:
            'You will receive your refund as a TGM store credit voucher. (Within 24 Hours)',
          Brand: `Refund to Wallet Credits: <strong>${totalAmount}</strong>`,
          rforrabbit: 'Refund to Wallet Credits'
        }[brandName] ??
        `Refund to ${brandName} Credits: <strong>${totalAmount}</strong>`),
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: brandName =>
        brandName === 'TGM' // Can be modified to switch case in future to cater to multiple brands
          ? 'You will receive your refund as a TGM store credit voucher. (Within 24 Hours)'
          : `Jumlah pengembalian dana Anda akan dikreditkan kembali ke Kredit ${brandName} Anda`,
      defaultPrepaid: (totalAmount, currency, brandName) =>
        ({
          Jolene: `Your refund amount of INR ${totalAmount} -(minus) the Shipping and Returns charges will be credited back to the source subject to Quality Checks approval on return.`,
          urturms: `Your refund amount of INR ${totalAmount} -(minus) the Shipping and Returns charges will be credited back to the source subject to Quality Checks approval on return.`
        }[brandName] ??
        `Your refund amount  <strong>${currency ? `of ${currency}` : ''} 
        ${totalAmount}</strong> will be credited back to the source`)
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'Dikirim',
    readyForDispatch: 'Siap Untuk Pengiriman',
    trackOrder: 'Lacak Pesanan',
    deliveryFeedback: 'Umpan Balik Pengiriman',
    raiseConcern: 'Menimbulkan kekhawatiran',
    expired: 'Kedaluwarsa',
    downloadApp: 'Unduh aplikasinya',
    allRightsReserved: 'All rights reserved',
    emailUs: 'Ada kendala pada Paketmu? Hubungi Kami: ',
    callUs: 'Hubungi kami: ',
    poweredBy: 'Powered by ',
    login: 'Gabung',
    username: 'Nama belakang',
    psswrd: 'Kata sandi',
    returnRequestReceived: 'Permintaan pengembalian diterima',
    returnPlaceDate: 'Tanggal penempatan kembali',
    reason: 'Alasan',
    anyOtherComment: 'Komentar',
    addImage: 'Menambahkan gambar',
    quantity: 'Kuantitas',
    back: 'Kembali',
    edit: 'Sunting',
    returnSummary: 'Ringkasan Pengembalian',
    returnStatus: 'Status Pengembalian',
    pickup: 'Menjemput',
    selectReversePickupAddress: 'Pilih Alamat Penjemputan Terbalik',
    selectOrAddAddress: 'Pilih/Tambahkan Alamat Penjemputan',
    phoneFormat: format =>
      `Silakan masukkan nomor telepon dengan format ${format} tanpa Kode Telepon`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Minta Penjemputan',
    selfShip: 'Kapal Mandiri',
    review: 'Tinjauan',
    drop: 'Jatuhkan Instruksi',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Tanggal Pengambilan',
    orderStatus: 'Status pemesanan',
    expectedPickupDate: 'Tanggal Pengambilan yang Diharapkan',
    estimatedReturnDate: 'Perkiraan Tanggal Pengembalian',
    expectedDropDate: 'Tanggal Penurunan yang Diharapkan',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Disetujui',
    goHome: 'Pulang ke rumah',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Perbarui Alamat Penjemputan',
    fullName: 'Full Name',
    emailId: 'ID email',
    city: 'Kota',
    area: 'Daerah',
    state: 'Negara',
    country: 'Negara',
    phoneNumber: 'Nomor telepon',
    phoneCode: 'Kode Telepon',
    bankDetail: 'Detail Bank Untuk Pengembalian Dana',
    upiDetails: 'UPI Details',
    brandCredits: (brandName, brandCreditText) =>
      `${brandName}  Credits ${brandCreditText}`,
    backToSource: 'Kembali ke Sumber',
    bankAccount: 'Akun bank',
    accountHolderName: 'nama pemilik akun',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Nomor akun',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Nama Bank',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Mengambil',
    enterReversePickupAddress: 'Masukkan Alamat Penjemputan Terbalik',
    pleaseUploadSupportingImage: (imageUploadNote, imageQuantity) =>
      `Catatan: Harap unggah ${imageQuantity} ${imageUploadNote}`,
    processing: 'Pengolahan',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} gambar diperlukan untuk permintaan ini`,
    returns: 'Kembali',
    exchange: (exchangeText, returnText) => `${returnText} and ${exchangeText}`,
    enterYourOrderInformation: 'Masukkan informasi pesanan Anda untuk memulai.',
    ourReturnExchangePolicy: (exchangeText, returnText) =>
      `Kebijakan ${returnText} & ${exchangeText} kami`,
    refundPolicy: 'Kebijakan pengembalian',
    termsConditions: 'Syarat & Ketentuan',
    stepOf: currentStep => `Langkah ${currentStep} dari 3`,
    allPriceShownPerItem: 'Semua harga yang ditampilkan berada pada level item',
    qtyPerOrderNote: 'Catatan: Qty menunjukkan kuantitas barang pesanan asli',
    qty: 'Qty',
    returnedQty: 'Kuantitas yang dikembalikan',
    placeFreshPickupRequest:
      'Penjemputan dibatalkan, ajukan permintaan penjemputan baru.',
    remove: 'Menghapus',
    return: 'Kembali',
    startReturn: 'Mulai Kembali',
    whyAreYouReturning: 'Mengapa Anda mengembalikan produk?', // optional
    whyYouWantReturn: 'Mengapa Anda ingin kembali?',
    selfShipMode:
      'Saat Anda memilih Mode Pengiriman Mandiri. Harap kemas barang dengan aman untuk mencegah kehilangan atau kerusakan selama transit.',
    requestPlacedDate: 'Tanggal Permintaan Ditempatkan',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'Pengiriman Segera',
    dropInstruction:
      'Meminta Anda untuk menyerahkan kiriman ke pusat pengiriman terdekat dari mitra pengangkut yang ditugaskan.',
    max200Chars: 'Maks. 200 karakter',
    select: 'Pilih',
    charsLeft: chars => `${chars} karakter tersisa`
  },
  kn: {
    courier: 'Courier',
    trackingId: 'Tracking ID',
    orderId: 'Order ID',
    lastUpdate: 'Last Update',
    orderPlacedOn: 'Order Placed On',
    orderType: 'Order Type',
    riderInfo: 'Rider Info',
    orderPlaced: 'Order Placed',
    dispatched: 'Dispatched',
    inTransit: 'In Transit',
    failedDelivery: 'Failed Delivery',
    ofd: 'Out For Delivery',
    delivered: 'Delivered',
    pickupRequested: 'Pickup Requested',
    updatedOn: 'Updated On',
    returnInstructions: 'Reattempt Instructions',
    returnPlaced: 'Return Placed',
    pickupCancelled: 'Pickup Cancelled',
    ofp: 'Out For Pickup',
    pickedup: 'Pickedup',
    returned: 'Returned',
    reverseReturned: 'Return Received',
    readyToShip: 'Ready To Ship',
    lost: 'Lost',
    damaged: 'Damaged',
    cancelled: 'Cancelled',
    requestPlaced: 'Request Placed',
    confirmed: 'Confirmed',
    requestAccepted: 'Request Accepted',
    requestAcceptedWithDate: date => `Your return has been accepted on ${date}`,
    confirm: 'Confirm',
    requestReturn: 'Request Return',
    pickupFailed: 'Pickup Failed',
    reachedW: 'Reached Warehouse',
    requestCancelled: 'Request Cancelled',
    requestRejected: 'Request Rejected',
    location: 'Location',
    time: 'Time',
    remark: 'Remark',
    shippingLabel: 'Shipping Label',
    shippingLabelInProgress:
      'Fetching the label from the carrier, it will be available for download in a few minutes',
    shippingLabelReady:
      'Your shipping label has been generated, now you can download it',
    pleaseTellUs: 'Please tell us what happened?',
    pressNextBtn: 'Press Next button at bottom after selection.',
    agentDidntCall: 'Delivery Agent did not call me',
    noAttemptToAddress: 'No attempt was made to the delivery address',
    wasNotAvailable: 'I was not available when delivery attempt was made',
    nprAgentDidntCall: 'Pickup Agent did not call me',
    nprNoAttemptToAddress: 'No attempt was made to the pickup address',
    nprWasNotAvailable: 'I was not available when pickup attempt was made',
    doNotWantProduct: 'Do not want the product anymore',
    doNotWantReturnProduct: 'Do not want to return the product any more.',
    others: 'Others',
    yes: 'Yes',
    no: 'No',
    preferredDate: 'Preferred delivery date',
    nprPreferredDate: 'Preferred pickup date',
    landmark: 'Landmark',
    address: 'Address',
    pincode: 'Pincode',
    provideAltNo: 'Please provide an Alternate Number',
    comments: 'Comments',
    searchBy: 'Search By',
    checkCurrentStatus: 'Check current status of your shipment.',
    trackYourOrder: 'Track Your Order',
    incorrectSearch:
      "The order/shipment you're trying to track has not been shipped yet or the order number entered is incorrect.",
    viewTracking: 'View Tracking',
    on: 'on',
    status: 'Status',
    orderDetails: 'Order Details',
    noScan1: 'Ready to Ship!',
    noScan2: 'Tracking details will be available shortly.',
    addressErr: 'Address cannot be blank or less than 10 character.',
    pincodeErr: 'Please enter 6 digit pincode.',
    pincodeNotServiceable:
      'Pincode is not serviceable, please ship order to us.',
    provideLandmark:
      'Please provide a landmark for courier partner to locate your address.',
    landmarkErr: 'Landmark cannot be blank or less than 5 character.',
    dateErr: 'Please enter a valid date.',
    commentErr: 'Comment cannot be blank.',
    addressCharLimit:
      'Please enter at least 10 characters and no more than 600.',
    phoneErr: 'Please enter a 10 digit valid phone number.',
    emailidErr: 'Please enter a valid email Id.',
    invalidImageErr: 'The file format is not supported',
    chooseOption: 'Please choose any option.',
    actionInProgress: 'Action in progress..',
    feedbackToastr1:
      'Thanks for your feedback! Look forward to serving you again.',
    feedbackToastr2:
      'Thanks for the feedback! We will work on providing you a better experience next time!',
    abfrlFeedbackToast:
      'Thank you for the feedback, redirecting you to LimeSurvey',
    ratingErr: 'Rating cannot be empty',
    apiErr: 'We are unable to process the request, please try again later',
    updateContactToastr:
      'Thanks for the update!, we will start sending notifications on updated contact number.',
    noDataFound:
      "The order/shipment you're trying to track has not been shipped yet or the order number entered is incorrect.",
    veryPoorAbfrl: 'least likely to recommend',
    veryPoor: 'Very Poor',
    excellentAbfrl: 'highly likely to recommend',
    excellent: 'Excellent',
    veryUnlikely: 'Very Unlikely',
    veryLikely: 'Very Likely',
    submit: 'Submit',
    youFacedAnyIssue: 'Did you face any other issue?',
    updateContact: 'Update Contact',
    updateContactTooltip: 'Click here to change contact no',
    myOrders: 'My Orders',
    myOrdersTooltip: 'Click here to view your orders',
    takeAction: 'Take Action',
    takeActionTooltip: 'Click here to raise an issue',
    next: 'Next',
    anyOtherDate: 'Any other date?',
    share: 'Share',
    expectedDelivery: 'Expected Delivery',
    viewDetail: 'View Details',
    viewSummary: 'View Summary',
    inputFeedback: 'Please share your feedback',
    feedbackMatters: 'Your feedback matters!',
    ratingFeedback: 'Thank you for your valuable feedback!',
    youRatedUs: 'You rated us',
    youCommented: 'You commented',
    pageNotFound: 'Page Not Found',
    ops404: "Oops, that's a 404.",
    youFoundIncorrectLink: 'You seem to have found an incorrect link.',
    contactNumber: 'Contact Number',
    updateContactNumber: 'Update Contact Number',
    updateContactNumberToastr:
      'This allows us to contact you & send important updates about the shipment.',
    searchByTrackingId: 'Tracking ID/AWB',
    searchByOrderId: 'Order ID/No',
    langName: 'English',
    rateUs: 'How would you rate for:',
    orderPlaceOnDateFn: date => `Order Placed On ${date}`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    submittedConcern: courier =>
      `We will followup with ${courier} to process your request.`,
    askRatingFeedback: courier =>
      `How was your delivery experience with ${courier}?`,
    askRatingFeedbackTrell:
      'Based on your recent delivery experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvind: 'How was your shopping experience with NNNOW?',
    askRatingFeedbackAbfrl: brandName =>
      `Based on this delivery experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience: 'ನಿಮ್ಮ ಒಟ್ಟಾರೆ ರಿಟರ್ನ್ಸ್ ಅನುಭವ ಹೇಗಿತ್ತು?',
    askNonPickedExperience:
      'ನಿಮ್ಮ ಶಿಪ್‌ಮೆಂಟ್ ಅನ್ನು ಪಿಕಪ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ ಎಂದು ನಾವು ಗಮನಿಸಿದ್ದೇವೆ. ನಿಮ್ಮ ಒಟ್ಟಾರೆ ರಿಟರ್ನ್ಸ್ ಅನುಭವ ಹೇಗಿತ್ತು?',
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (title, websiteUrl) =>
      `Please contact ${title} at ${websiteUrl} for the correct tracking link and for information about your order.`,
    statusOnDateFn: (status, date) => `${status} on ${date}`,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'Please tell us what went wrong!';
        case 6:
        case 7:
        case 8:
          return 'How can we improve?';
        default:
          return 'Give a compliment!';
      }
    },
    edd: (expression, message) => {
      switch (expression) {
        case 1:
          return message;
        case 2:
          return 'Arriving Soon: Reached at a location near you';
        case 3:
          return 'Shipment delayed: Courier Partner notified to get the shipment delivered to you at the earliest';
        case 4:
          return 'Moving as expected';
        case 5:
          return 'Shipment delayed: Logistics team notified';
        case 7:
          return 'Will be reattempted in next 24-48 hours based on your delivery preference';
        case 8:
          return message;
        case 9:
          return 'Shipment will be delivered the same day once dispatched';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our courier partner was unable to deliver your order since they could not get in touch with you. Please provide your preferred date for delivery:`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our Courier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our courier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver! In case you want to let us know your preferred delivery, enter the information below:`;
        case 4:
          return `Order Undelivered: Our courier partner was unable to make a delivery attempt today. <br /><br /> Please provide your preferred date for delivery:`;
        case 5:
          return `Order Undelivered: Our courier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our courier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our courier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt at your preferred date for delivery. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our courier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our courier partner informed us that you will be collecting the shipment from their location. <br /><br /> If this is not the case, please provide your preferred delivery date. In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our courier partner informed us that you requested for open delivery. Please provide your preferred delivery date. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our Courier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. Please provide your preferred delivery date. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. Please provide your preferred delivery date. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Order Undelivered: We are identifying the issue with your shipment. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    fdForm: {
      address:
        'The courier partner informs us that they tried to reach your address but could not find your address. Can you provide us more details about the address?',
      timing: {
        a:
          'Our courier partners attempt 3 times for a delivery. In the case, where the package could not be delivered the first time, we reattempt it again the next day excluding Sundays and public holidays.',
        b:
          'Kindly make sure that someone is available at your delivery address to receive the package.',
        c: 'Do you want to get the delivery attempt on specific days?'
      },
      other: () =>
        'Please share any additional details that could help us to deliver the order.',
      phone: {
        a:
          'In some cases, courier partners call from landline which might feel as spam to you. We will ask the courier partner to reattempt the delivery.',
        b: () =>
          'Do you want the courier partner to call you on the same number as given during order placement?'
      },
      reject:
        'Please confirm if you want us to make another delivery attempt for your shipment:'
    },
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `Pickup Failed: Our courier partner was unable to pickup order since they could not get in touch with you. Please provide your contact number or address`;
        case 2:
          return `Pickup Failed: Our courier partner informed us that your package could not be pickup up as per your request to cancel the pickup request. <br /><br /> Click on 'Yes' to pickup, 'No' to cancel the pickup.`;
        case 3:
        case '3a':
          return `Product was damaged or used`;
        case 4:
          return `Either product color or brand is different`;
        case 5:
        case '5a':
          return `The total number of items were different than requested`;
        case 6:
          return `Pickup Failed: Please provide your preferred pickup date.`;
        case 7:
          return `Pickup Failed: Our courier company was unable to pickup since your address is out service for our courier.`;
        case 8:
        case '8a':
          return 'Our Courier notified that multiple attempts were made for the pickup but the same could not be processed hence please provide a preferred date for the pickup on the basis of your availability, in case you have a concern, feel free to write to us in comment section below';
        case 9:
          return 'Courier could not attempt the Pickup for your item/(s), please keep the item/(s) handy as the pickup will be attempted on the next business day, if you want to share any feedback, then please write it in the comments section';
        case 10:
          return 'Our Courier partner notified that you refused to accept the Pickup request, if this is not the case then please provide the next preference of your Pickup date.In case you have a concern, feel free to write to us in comment section below';
        case 11:
          return 'Our Courier partner notified that the Product was not ready for pickup, if this is not the case then please provide the  the next preference of your Pickup date.In case you have a concern, feel free to write to us in comment section below';
        case 13:
          return `Our carrier partner informed us that they couldn't pick up your package as they were unable to locate your address. <br /><br /> Kindly provide your complete address, including any landmarks, below.`;
        case 14:
          return `Our carrier partner informed us that they couldn't process your pickup request as you were unavailable. Please select your preferred pickup date.`;
        case 0:
        default:
          return `Pickup Failed: We are identifying the issue with your request. Please provide your preferred pickup date. In case you have a concern, feel free to write to us in comment section below:`;
      }
    },
    nprFdForm: {
      address:
        'The courier partner informs us that they tried to reach your address but could not find your address. Can you provide us more details about the address?',
      timing: {
        a:
          'Our courier partners attempt 3 times for a pickup. In the case, where the package could not be picked up the first time, we reattempt it again the next day excluding Sundays and public holidays.',
        b:
          'Kindly make sure that someone is available at your pickup address to handover the package.',
        c: 'Do you want to get the pickup attempt on specific days?'
      },
      other:
        'Please share any additional details that could help us to pickup the order, also please cross verify your return request in case of any product/ quantity mismatch',
      phone: {
        a:
          'In some cases, courier partners call from landline which might feel as spam to you. We will ask the courier partner to reattempt the pickup.',
        b:
          'Do you want the courier partner to call you on the same number as given during order placement?'
      },
      reject:
        'Please confirm if you want us to cancel the pickup attempt for your shipment:'
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `Total <strong>${creditValue}</strong> credits used for payment of this order will be credited back to your store account against this order`,
      totalAsCredit: creditValue =>
        `Would you like refund for ${
          creditValue > 0 ? 'rest of' : ''
        } the amount paid as store credit?`,
      totalAsCreditNew: 'Please choose your preferred mode of refund payment',
      prepaid: (calculatedAmt, currency) =>
        `Your refund amount of <strong>${currency} ${calculatedAmt}</strong> will be credited back to the source.`,
      cod: (calculatedAmt, brandName) =>
        ({
          rforrabbit:
            'Please enter your bank account details. You will receive cash paid in the bank account provided below.'
        }[brandName] ??
        `Please enter your bank account details. You will receive cash paid(Rs. <strong>${calculatedAmt}</strong>) in the bank account provided below.`),
      refundTotalAsCredit: (totalAmount, brandName) =>
        ({
          TGM:
            'You will receive your refund as a TGM store credit voucher. (Within 24 Hours)',
          Brand: `Refund to Wallet Credits: <strong>${totalAmount}</strong>`,
          rforrabbit: 'Refund to Wallet Credits'
        }[brandName] ??
        `Refund to ${brandName} Credits: <strong>${totalAmount}</strong>`),
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: brandName =>
        brandName === 'TGM' // Can be modified to switch case in future to cater to multiple brands
          ? 'You will receive your refund as a TGM store credit voucher. (Within 24 Hours)'
          : `Your refund amount will be credited back to your ${brandName} Credits`,
      defaultPrepaid: (totalAmount, currency, brandName) =>
        ({
          Jolene: `Your refund amount of INR ${totalAmount} -(minus) the Shipping and Returns charges will be credited back to the source subject to Quality Checks approval on return.`,
          urturms: `Your refund amount of INR ${totalAmount} -(minus) the Shipping and Returns charges will be credited back to the source subject to Quality Checks approval on return.`
        }[brandName] ??
        `Your refund amount  <strong>${currency ? `of ${currency}` : ''} 
        ${totalAmount}</strong> will be credited back to the source`)
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'Shipped',
    readyForDispatch: 'Ready For Dispatch',
    trackOrder: 'Track Order',
    deliveryFeedback: 'Delivery Feedback',
    raiseConcern: 'Raise Concern',
    expired: 'Expired',
    downloadApp: 'Download the app',
    allRightsReserved: 'All rights reserved',
    emailUs: 'Email us: ',
    callUs: 'Call us: ',
    poweredBy: 'Powered by ',
    login: 'Login',
    username: 'Username',
    psswrd: 'Password',
    returnRequestReceived: 'Return request received',
    returnPlaceDate: 'Return placed date',
    reason: 'Reason',
    anyOtherComment: 'Comment',
    addImage: 'Add Image',
    quantity: 'Quantity',
    back: 'Back',
    edit: 'Edit',
    returnSummary: 'Return Summary',
    returnStatus: 'Return Status',
    pickup: 'Pickup',
    selectReversePickupAddress: 'Select Reverse Pickup Address',
    selectOrAddAddress: 'Select/Add Pickup Address',
    phoneFormat: format =>
      `Please enter a phone number in the format ${format} without the Phone Code`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Request Pickup',
    selfShip: 'Self Ship',
    review: 'Review',
    drop: 'Drop Instruction',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Pickup Date',
    orderStatus: 'Order Status',
    expectedPickupDate: 'Expected Pickup Date',
    estimatedReturnDate: 'Estimated Return Date',
    expectedDropDate: 'Expected Drop Date',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Approved',
    goHome: 'Go Home',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Update Pickup Address',
    fullName: 'Full Name',
    emailId: 'Email ID',
    city: 'City',
    area: 'Area',
    state: 'State',
    country: 'Country',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    bankDetail: 'Bank Details For Refund',
    upiDetails: 'UPI Details',
    brandCredits: (brandName, brandCreditText) =>
      `${brandName}  Credits ${brandCreditText}`,
    backToSource: 'Back to Source',
    bankAccount: 'Bank Account',
    accountHolderName: 'Account Holder Name',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Account Number',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Fetch',
    enterReversePickupAddress: 'Enter Reverse Pickup Address',
    pleaseUploadSupportingImage: (imageUploadNote, imageQuantity) =>
      `Note: Please upload ${imageQuantity} ${imageUploadNote}`,
    processing: 'Processing',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} images required for this request`,
    returns: 'Returns',
    exchange: (exchangeText, returnText) => `${returnText} and ${exchangeText}`,
    enterYourOrderInformation: 'Enter your order information to get started.',
    ourReturnExchangePolicy: (exchangeText, returnText) =>
      `Our ${returnText} & ${exchangeText} Policy`,
    refundPolicy: 'Refund Policy',
    termsConditions: 'Terms & Conditions',
    stepOf: currentStep => `Step ${currentStep} of 3`,
    allPriceShownPerItem: 'All prices shown are at item level',
    qtyPerOrderNote: 'Note: Qty shows original ordered item quantity',
    qty: 'Qty',
    returnedQty: 'Returned quantity',
    placeFreshPickupRequest: 'Pickup cancelled, place fresh pickup request.',
    remove: 'Remove',
    return: 'Return',
    startReturn: 'Start Return',
    whyAreYouReturning: 'Why are you returning the product?', // optional
    whyYouWantReturn: 'Why do you want to return?',
    selfShipMode:
      'As you selected Self Ship Mode. Please pack the items securely to prevent any loss or damage during transit.',
    requestPlacedDate: 'Request Placed Date',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'Shipping Soon',
    dropInstruction:
      'Request you to please drop the shipment at the nearest shipping center of the assigned carrier partner.',
    max200Chars: 'Max. 200 characters',
    select: 'Select',
    charsLeft: chars => `${chars} characters left`
  },
  fr: {
    courier: 'Transporteur',
    trackingId: 'Numéro de colis',
    orderId: 'Numéro de commande',
    lastUpdate: 'Dernière mise à jour',
    orderPlacedOn: 'Commande passée le',
    orderType: 'Type de commande',
    riderInfo: 'Rider Info',
    orderPlaced: 'Commande passée',
    dispatched: 'Expédiée',
    inTransit: 'En transit',
    failedDelivery: 'Livraison échouée',
    ofd: 'En cours de livraison',
    delivered: 'Livrée',
    pickupRequested: 'Ramassage demandé',
    updatedOn: 'Updated On',
    returnInstructions: 'Reattempt Instructions',
    returnPlaced: 'Return Placed',
    pickupCancelled: 'Ramassage annulé',
    ofp: 'En cours de ramassage',
    pickedup: 'Ramassé',
    returned: 'Retourné',
    reverseReturned: 'Retourné',
    readyToShip: 'Prêt à être expédié',
    lost: 'Perdu',
    damaged: 'Endommagé',
    cancelled: 'Annulé',
    requestPlaced: 'Demande placée',
    confirmed: 'Confirmé',
    requestAccepted: 'Request Accepted',
    requestAcceptedWithDate: date => `Your return has been accepted on ${date}`,
    confirm: 'Confirm',
    requestReturn: 'Request Return',
    pickupFailed: 'Ramassage échoué',
    reachedW: "Arrivé à l'entrepôt",
    requestCancelled: 'Demande annulée',
    requestRejected: 'Request Rejected',
    location: 'Lieu',
    time: 'Temps',
    remark: 'Remarque',
    shippingLabel: 'Shipping Label',
    shippingLabelInProgress:
      'Fetching the label from the carrier, it will be available for download in a few minutes',
    shippingLabelReady:
      'Your shipping label has been generated, now you can download it',
    pleaseTellUs: "Veuillez nous dire ce qui s'est passé ?",
    pressNextBtn: 'Appuyez sur le bouton Suivant en bas après la sélection.',
    agentDidntCall: "Le livreur ne m'a pas appelé",
    noAttemptToAddress:
      "Aucune tentative n'a été faite à l'adresse de livraison",
    wasNotAvailable:
      "Je n'étais pas disponible lors de la tentative de livraison",
    nprAgentDidntCall: "L'agent de ramassage ne m'a pas appelé",
    nprNoAttemptToAddress:
      "Aucune tentative n'a été faite à l'adresse de ramassage",
    nprWasNotAvailable:
      "Je n'étais pas disponible lors de la tentative de ramassage",
    doNotWantProduct: 'Je ne veux plus du produit',
    doNotWantReturnProduct: 'Ne veux plus retourner le produit.',
    others: 'Autres',
    yes: 'Oui',
    no: 'Non',
    preferredDate: 'Date de livraison préférée',
    nprPreferredDate: 'Date de ramassage préférée',
    landmark: 'Point de repère',
    address: 'Adresse',
    pincode: 'Code postal',
    provideAltNo: 'Veuillez fournir un numéro alternatif',
    comments: 'Commentaires',
    searchBy: 'Rechercher par',
    checkCurrentStatus: 'Vérifier le statut actuel de votre envoi.',
    trackYourOrder: 'Track Your Order',
    incorrectSearch:
      "La commande/l'envoi que vous essayez de suivre n'a pas encore été expédié ou le numéro de commande saisi est incorrect.",
    viewTracking: 'Voir le suivi',
    on: 'le',
    status: 'Statut',
    orderDetails: 'Détails de la commande',
    noScan1: 'Prêt à être expédié !',
    noScan2: 'Les informations de suivi seront disponibles sous peu.',
    addressErr:
      "L'adresse ne peut pas être vide ou avoir moins de 10 caractères.",
    pincodeErr: 'Veuillez saisir un code postal à 6 chiffres.',
    pincodeNotServiceable:
      "Le code postal n'est pas desservi, veuillez nous expédier la commande.",
    provideLandmark:
      'Veuillez fournir un point de repère pour que le partenaire de livraison puisse localiser votre adresse.',
    landmarkErr:
      'Le point de repère ne peut pas être vide ou avoir moins de 5 caractères.',
    dateErr: 'Veuillez saisir une date valide.',
    commentErr: 'Le commentaire ne peut pas être vide.',
    addressCharLimit:
      'Veuillez saisir au moins 10 caractères et pas plus de 600.',
    phoneErr: 'Veuillez saisir un numéro de téléphone valide à 10 chiffres.',
    emailidErr: 'Veuillez saisir une adresse e-mail valide.',
    invalidImageErr: 'The file format is not supported',
    chooseOption: 'Veuillez choisir une option.',
    actionInProgress: 'Action en cours...',
    feedbackToastr1:
      'Merci pour vos commentaires ! Nous sommes impatients de vous servir à nouveau.',
    feedbackToastr2:
      'Merci pour vos commentaires ! Nous travaillerons à vous offrir une meilleure expérience la prochaine fois !',
    abfrlFeedbackToast:
      'Thank you for the feedback, redirecting you to LimeSurvey',
    ratingErr: 'La note ne peut pas être vide',
    apiErr:
      'Nous ne parvenons pas à traiter la demande, veuillez réessayer plus tard',
    updateContactToastr:
      'Merci pour la mise à jour ! Nous commencerons à envoyer des notifications sur le numéro de contact mis à jour.',
    noDataFound:
      "La commande/l'envoi que vous essayez de suivre n'a pas encore été expédié ou le numéro de commande saisi est incorrect.",
    veryPoorAbfrl: 'least likely to recommend',
    veryPoor: 'Très mauvais',
    excellentAbfrl: 'highly likely to recommend',
    excellent: 'Excellent',
    veryUnlikely: 'Very Unlikely',
    veryLikely: 'Very Likely',
    submit: 'Soumettre',
    youFacedAnyIssue: 'Avez-vous rencontré un autre problème ?',
    updateContact: 'Mettre à jour le contact',
    updateContactTooltip: 'Cliquez ici pour changer le numéro de contact',
    myOrders: 'Mes commandes',
    myOrdersTooltip: 'Click here to view your orders',
    takeAction: 'Prendre des mesures',
    takeActionTooltip: 'Prendre des mesures',
    next: 'Suivant',
    anyOtherDate: 'Une autre date ?',
    share: 'Partager',
    expectedDelivery: 'Livraison prévue',
    viewDetail: 'Voir les détails',
    viewSummary: 'Voir le résumé',
    inputFeedback: 'Veuillez partager vos commentaires',
    feedbackMatters: 'Your feedback matters!',
    ratingFeedback: 'Merci pour vos précieux commentaires !',
    youRatedUs: 'You rated us',
    youCommented: 'You commented',
    pageNotFound: 'Page non trouvée',
    ops404: "Oups, c'est une erreur 404.",
    youFoundIncorrectLink: 'Il semble que vous ayez trouvé un lien incorrect.',
    contactNumber: 'Numéro de contact',
    updateContactNumber: 'Mettre à jour le numéro de contact',
    updateContactNumberToastr:
      "Cela nous permet de vous contacter et de vous envoyer des mises à jour importantes sur l'envoi.",
    searchByTrackingId: 'Identifiant de suivi / AWB',
    searchByOrderId: 'Order ID/No',
    langName: 'Anglais',
    rateUs: 'How would you rate for:',
    orderPlaceOnDateFn: date => `Commande passée le ${date}`,
    recommendToFriend: npsQuestion => `${npsQuestion}`,
    submittedConcern: courier =>
      `Nous suivrons avec ${courier} pour traiter votre demande.`,
    askRatingFeedback: courier =>
      `Comment était votre expérience de livraison avec la livraison de ${courier}?`,
    askRatingFeedbackTrell:
      'Based on your recent delivery experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvind: 'How was your shopping experience with NNNOW?',
    askRatingFeedbackAbfrl: brandName =>
      `Based on this delivery experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    askRatingFeedbackReverse: courier =>
      `How was your pickup experience with ${courier}?`,
    askReturnExperience:
      'Comment s’est déroulée votre expérience globale en matière de retours ?',
    askNonPickedExperience:
      "Nous avons remarqué que votre envoi n'a pas pu être récupéré. Comment s’est déroulée votre expérience globale en matière de retours ?",
    askRatingFeedbackTrellReverse:
      'Based on your recent pickup experience, how likely are you to recommend Trell to your friends and family?',
    askRatingFeedbackArvindReverse:
      'How was your pickup experience with NNNOW?',
    askRatingFeedbackAbfrlReverse: brandName =>
      `Based on this pickup experience, on a scale of 0-10 how likely are you to recommend ${brandName} to a friend?`,
    notFoundDescFn: (title, websiteUrl) =>
      `Veuillez contacter ${title} à ${websiteUrl} pour le lien de suivi correct et pour des informations sur votre commande.`,
    statusOnDateFn: (status, date) => `${status} le ${date}`,
    npsQuestions: {
      'How satisfied are you with the timeliness of the deliveries made by our logistics company':
        'How satisfied are you with the timeliness of the deliveries made by our logistics company',
      'Rate delivery person behaviour': 'Rate delivery person behaviour',
      'Rate condition of Packaging /shipment on delivery':
        'Rate condition of Packaging /shipment on delivery',
      'Rate overall delivery performance': 'Rate overall delivery performance'
    },
    ratingMeta: expression => {
      switch (expression) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return "Veuillez nous dire ce qui s'est mal passé !";
        case 6:
        case 7:
        case 8:
          return 'Comment pouvons-nous nous améliorer ?';
        default:
          return 'Faites un compliment !';
      }
    },
    edd: (expression, message) => {
      switch (expression) {
        case 1:
          return message;
        case 2:
          return 'Bientôt arrivé : Atteint un emplacement près de chez vous';
        case 3:
          return "Livraison retardée : Le partenaire de messagerie a été informé pour que l'envoi soit livré dès que possible";
        case 4:
          return 'Déplacement comme prévu';
        case 5:
          return "Livraison retardée : L'équipe logistique a été informée";
        case 7:
          return 'Sera réessayé dans les prochaines 24 à 48 heures en fonction de vos préférences de livraison';
        case 8:
          return message;
        case 9:
          return 'Shipment will be delivered the same day once dispatched';
        case 6:
        default:
          return '';
      }
    },
    ndrBucket: expression => {
      switch (expression) {
        case 1:
          return `Commande non livrée : Notre partenaire de messagerie n'a pas pu livrer votre commande car ils n'ont pas pu vous contacter. Veuillez fournir votre date préférée pour la livraison :`;
        case 2:
        case 9:
        case 14:
          return `Commande non livrée : Notre partenaire de messagerie nous a informés que votre colis n'a pas pu être livré comme demandé pour refuser la livraison. <br /><br /> Cliquez sur 'Oui' pour recevoir la commande, 'Non' pour annuler la commande.`;
        case 3:
          return `Commande non livrée : Notre partenaire de messagerie n'a pas pu livrer votre commande car vous avez demandé un report. Nous reviendrons et livrerons ! Si vous souhaitez nous faire part de votre date de livraison préférée, entrez les informations ci-dessous :`;
        case 4:
          return `Commande non livrée : Notre partenaire de messagerie n'a pas pu faire de tentative de livraison aujourd'hui. <br /><br /> Veuillez fournir votre date de livraison préférée :`;
        case 5:
          return `Commande non livrée : Notre partenaire de messagerie n'a pas pu livrer votre commande car nous n'avons pas pu vous contacter. Veuillez fournir votre contact alternatif afin que notre partenaire de messagerie puisse vous joindre. <br /><br /> Voulez-vous que le partenaire de messagerie vous appelle sur le même numéro que celui donné lors de la commande ?`;
        case 6:
          return `Commande non livrée : Notre partenaire de messagerie n'a pas pu livrer votre commande car ils n'ont pas pu localiser votre adresse. Veuillez fournir votre adresse complète avec point de repère ci-dessous :`;
        case 7:
          return `Order Undelivered: Our courier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt at your preferred date for delivery. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our courier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Commande non livrée : Notre partenaire de messagerie nous a informés que vous allez retirer l'envoi à leur emplacement. <br /><br /> Si ce n'est pas le cas, veuillez fournir votre date de livraison préférée. Si vous avez des préoccupations, n'hésitez pas à nous écrire dans la section des commentaires ci-dessous :`;
        case 11:
          return `Avez-vous rencontré un problème lors de la livraison ? Veuillez fournir vos commentaires ci-dessous :`;
        case 12:
          return `Commande non livrée : Notre partenaire de messagerie nous a informés que vous avez demandé une livraison ouverte. Veuillez fournir votre date de livraison préférée. Si vous avez des préoccupations, n'hésitez pas à nous écrire dans la section des commentaires ci-dessous et nous transmettrons les commentaires à notre équipe.`;
        case 13:
          return `Commande non livrée : Notre partenaire de messagerie a malheureusement dirigé votre envoi vers un mauvais emplacement. Nous faisons tout pour assurer une livraison rapide chez vous. Veuillez fournir votre date de livraison préférée. Si vous souhaitez partager des commentaires, veuillez nous écrire dans la section des commentaires ci-dessous :`;
        case 0:
        default:
          return `Commande non livrée : Nous identifions le problème avec votre envoi. Veuillez fournir votre date de livraison préférée. Si vous avez des préoccupations, n'hésitez pas à nous écrire dans la section des commentaires ci-dessous :`;
      }
    },
    ndrBucketNoDate: expression => {
      switch (expression) {
        case 1:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not get in touch with you. Our carrier partner will attempt delivery again soon.`;
        case 2:
        case 9:
        case 14:
          return `Order Undelivered: Our carrier partner informed us that your package could not be delivered as per your request to Reject the Delivery. <br /><br /> Click on 'Yes' to get the order delivered, 'No' to cancel the order.`;
        case 3:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since you requested for a reschedule. We will come again and deliver!`;
        case 4:
          return `Order Undelivered: Our carrier partner was unable to make a delivery attempt today. <br /><br />`;
        case 5:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since we could not get in touch with you. Please provide your alternate contact so that our courier partner can reach you. <br /><br />Do you want the courier partner to call you on the same number as given during order placement?`;
        case 6:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate your address. Please provide your complete address with landmark below:`;
        case 7:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since the payment was not ready at the time of delivery. <br /><br />Don't worry, we will ask them to make another attempt. In case you faced a different issue, please provide your feedback below.`;
        case 8:
          return `Order Undelivered: Our carrier partner was unable to deliver your order since they could not locate the address. Please provide your complete address with landmark below.`;
        case 10:
          return `Order Undelivered: Our carrier partner informed us that you will be collecting the shipment from their location. <br /><br /> In case you have a concern, feel free to write to us in comment section below:`;
        case 11:
          return `Did you face any issue during delivery? Please provide your feedback below:`;
        case 12:
          return `Order Undelivered: Our carrier partner informed us that you requested for open delivery. In case you have a concern, feel free to write to us in comment section below and we will pass the feedback to our team.`;
        case 13:
          return `Order Undelivered: Our carrier partner unfortunately directed your shipment to a wrong location. We are doing everything to ensure a fast delivery to you. If you want to share any feedback, please write to us in comment section below:`;
        case 0:
        default:
          return `Commande non livrée : Nous identifions le problème avec votre envoi. Veuillez fournir votre date de livraison préférée. Si vous avez des préoccupations, n'hésitez pas à nous écrire dans la section des commentaires ci-dessous :`;
      }
    },
    fdForm: {
      address:
        "Le partenaire de messagerie nous informe qu'ils ont essayé de joindre votre adresse mais n'ont pas pu la trouver. Pouvez-vous nous fournir plus de détails sur l'adresse ?",
      timing: {
        a:
          "Nos partenaires de messagerie tentent 3 fois de livrer. Dans le cas où le colis n'a pas pu être livré la première fois, nous le retentons le lendemain, en excluant les dimanches et les jours fériés.",
        b:
          "Veuillez vous assurer qu'une personne est disponible à votre adresse de livraison pour recevoir le colis.",
        c:
          'Souhaitez-vous recevoir la tentative de livraison les jours spécifiques ?'
      },
      other: () =>
        'Veuillez partager tout détail supplémentaire qui pourrait nous aider à livrer la commande.',
      phone: {
        a:
          'Dans certains cas, les partenaires de messagerie appellent depuis une ligne fixe, ce qui peut sembler être du spam pour vous. Nous demanderons au partenaire de messagerie de retenter la livraison.',
        b: () =>
          'Souhaitez-vous que le partenaire de messagerie vous appelle sur le même numéro que celui donné lors de la commande ?'
      },
      reject:
        'Veuillez confirmer si vous souhaitez que nous tentions une nouvelle livraison pour votre envoi :'
    },
    // Truncated content (3a, 5a, 8a) shown for Nykaa brands when form is not visible
    nprBucket: expression => {
      switch (expression) {
        case 1:
          return `Our carrier partner informed us that they were unable to pick up your order as they couldn't reach you. Should we call on same number? <br /><br /> Please click "Yes' to confirm or 'No' to edit your contact number.`;
        case 2:
          return `Our carrier partner informed us that they could not pick up your package due to your return cancellation request. <br /><br /> Please click 'Yes' to confirm cancellation or 'No' to proceed with pickup.`;
        case 3:
        case '3a':
          return `Le produit était endommagé ou utilisé`;
        case 4:
          return `Soit la couleur du produit soit la marque est différente`;
        case 5:
        case '5a':
          return `Le nombre total d'articles était différent de celui demandé`;
        case 6:
          return `Please select an alternate date for order pickup`;
        case 7:
          return `Ramassage échoué : Notre entreprise de messagerie n'a pas pu récupérer car votre adresse n'est pas desservie par notre service de messagerie.`;
        case 8:
        case '8a':
          return "Notre messagerie nous a informés que plusieurs tentatives ont été faites pour le ramassage mais cela n'a pas pu être traité, veuillez donc fournir une date préférée pour le ramassage en fonction de votre disponibilité, en cas de préoccupation, n'hésitez pas à nous écrire dans la section des commentaires ci-dessous";
        case 9:
          return "La messagerie n'a pas pu tenter le ramassage pour votre(s) article(s), veuillez donc garder l'article/(s) à portée de main car le ramassage sera tenté le prochain jour ouvrable, si vous souhaitez partager des commentaires, veuillez les écrire dans la section des commentaires";
        case 10:
          return "Notre partenaire de messagerie nous a informés que vous avez refusé d'accepter la demande de ramassage, si ce n'est pas le cas, veuillez fournir la prochaine préférence de votre date de ramassage. En cas de préoccupation, n'hésitez pas à nous écrire dans la section des commentaires ci-dessous";
        case 11:
          return "Notre partenaire de messagerie nous a informés que le produit n'était pas prêt pour le ramassage, si ce n'est pas le cas, veuillez fournir la prochaine préférence de votre date de ramassage. En cas de préoccupation, n'hésitez pas à nous écrire dans la section des commentaires ci-dessous";
        case 13:
          return `Our carrier partner informed us that they couldn't pick up your package as they were unable to locate your address. <br /><br /> Kindly provide your complete address, including any landmarks, below.`;
        case 14:
          return `Our carrier partner informed us that they couldn't process your pickup request as you were unavailable. Please select your preferred pickup date.`;
        case 0:
        default:
          return 'We are currently working to identify the issue with your request. Kindly select your preferred pickup date. <br /><br />  In case of concerns, please share them in the comments section below.';
      }
    },
    nprFdForm: {
      address: `Le partenaire de messagerie nous informe qu'ils ont essayé de joindre votre adresse mais n'ont pas pu la trouver. Pouvez-vous nous fournir plus de détails sur l'adresse ?`,
      timing: {
        a:
          "Nos partenaires de messagerie tentent 3 fois de ramasser. Dans le cas où le colis n'a pas pu être récupéré la première fois, nous retentons le lendemain, en excluant les dimanches et les jours fériés.",
        b:
          "Veuillez vous assurer qu'une personne est disponible à votre adresse de ramassage pour remettre le colis.",
        c:
          'Souhaitez-vous recevoir la tentative de ramassage les jours spécifiques ?'
      },
      other:
        'Veuillez partager tout détail supplémentaire qui pourrait nous aider à récupérer la commande, veuillez également vérifier votre demande de retour en cas de différence de produit/quantité',
      phone: {
        a:
          'Dans certains cas, les partenaires de messagerie appellent depuis une ligne fixe, ce qui peut sembler être du spam pour vous. Nous demanderons au partenaire de messagerie de retenter le ramassage.',
        b:
          'Souhaitez-vous que le partenaire de messagerie vous appelle sur le même numéro que celui donné lors de la commande ?'
      },
      reject:
        'Veuillez confirmer si vous souhaitez que nous annulions la tentative de ramassage pour votre envoi :'
    },
    refundTxt: {
      storeCreditOnly: creditValue =>
        `Le total des <strong>${creditValue}</strong> crédits utilisés pour le paiement de cette commande sera crédité sur votre compte magasin pour cette commande`,
      totalAsCredit: creditValue =>
        `Souhaitez-vous un remboursement pour ${
          creditValue > 0 ? 'le reste du' : ''
        } montant payé sous forme de crédit magasin ?`,
      totalAsCreditNew: 'Please choose your preferred mode of refund payment',
      prepaid: (calculatedAmt, currency) =>
        `Your refund amount of <strong>${currency} ${calculatedAmt}</strong> will be credited back to the source.`,
      cod: (calculatedAmt, brandName) =>
        ({
          rforrabbit:
            'Please enter your bank account details. You will receive cash paid in the bank account provided below.'
        }[brandName] ??
        `Veuillez saisir les détails de votre compte bancaire. Vous recevrez le montant en espèces payé (Rs. ${calculatedAmt}) sur le compte bancaire fourni ci-dessous.`),
      refundTotalAsCredit: (totalAmount, brandName) =>
        ({
          TGM:
            'You will receive your refund as a TGM store credit voucher. (Within 24 Hours)',
          Brand: `Refund to Wallet Credits: <strong>${totalAmount}</strong>`,
          rforrabbit: 'Refund to Wallet Credits',
          plumgoodness: `You will be receiving an equivalent valued coupon worth ${totalAmount} in your registered email ID, which you can use during your future purchases at Plumgoodness.com.`
        }[brandName] ??
        `Remboursement sous forme de crédit magasin : ${totalAmount}`),
      refundTotalAsGiftCard: (totalAmount, currency, brandName) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to you as a ${
          brandName === 'runners' ? 'store' : ''
        } gift card`,
      refundOriginalPaymentMode: (totalAmount, currency) =>
        `Your refund amount of ${currency} ${totalAmount} will be credit back to the original payment method.`,
      codAsStroreCredit: brandName =>
        brandName === 'TGM' // Can be modified to switch case in future to cater to multiple brands
          ? 'You will receive your refund as a TGM store credit voucher. (Within 24 Hours)'
          : `Your refund amount will be credited back to your ${brandName} Credits`,
      defaultPrepaid: (totalAmount, currency, brandName) =>
        ({
          Jolene: `Your refund amount of INR ${totalAmount} -(minus) the Shipping and Returns charges will be credited back to the source subject to Quality Checks approval on return.`,
          urturms: `Your refund amount of INR ${totalAmount} -(minus) the Shipping and Returns charges will be credited back to the source subject to Quality Checks approval on return.`,
          bonkerscorner: `Your refund amount  <strong>${
            currency ? `of ${currency}` : ''
          } ${totalAmount}</strong> will be credited <strong>to your Bonkers Corner wallet</strong>`
        }[brandName] ??
        `Your refund amount  <strong>${currency ? `of ${currency}` : ''} 
        ${totalAmount}</strong> will be credited back to the source`)
    },
    termandconditionsText: [
      'Only products that have not been used or damaged will be accepted for return.',
      'Ensure that products being returned are with MRP tags and labels.',
      'All offer items and free gift(s) associated with this order are to be returned along with the product.',
      'All cash on delivery (COD) orders will be refunded in the S&N Credits ONLY.'
    ],
    shipped: 'Shipped',
    readyForDispatch: 'Ready For Dispatch',
    trackOrder: 'Track Order',
    deliveryFeedback: 'Delivery Feedback',
    raiseConcern: 'Raise Concern',
    expired: 'Expired',
    downloadApp: 'Download the app',
    allRightsReserved: 'All rights reserved',
    emailUs: 'Email us: ',
    callUs: 'Nous contacter: ',
    poweredBy: 'Powered by ',
    login: 'Login',
    username: 'Username',
    psswrd: 'Password',
    returnRequestReceived: 'Return request received',
    returnPlaceDate: 'Return placed date',
    reason: 'Reason',
    anyOtherComment: 'Comment',
    addImage: 'Add Image',
    quantity: 'Quantity',
    back: 'Back',
    edit: 'Edit',
    returnSummary: 'Return Summary',
    returnStatus: 'Return Status',
    pickup: 'Pickup',
    selectReversePickupAddress: 'Select Reverse Pickup Address',
    selectOrAddAddress: 'Select/Add Pickup Address',
    phoneFormat: format =>
      `Please enter a phone number in the format ${format} without the Phone Code`,
    format: 'format',
    addNewAddress: 'Add new address',
    proceed: 'Proceed',
    requestPickup: 'Request Pickup',
    selfShip: 'Self Ship',
    review: 'Review',
    drop: 'Drop Instruction',
    pickupAddress: 'Pickup Address',
    pickupDate: 'Pickup Date',
    orderStatus: 'Order Status',
    expectedPickupDate: 'Expected Pickup Date',
    estimatedReturnDate: 'Estimated Return Date',
    expectedDropDate: 'Expected Drop Date',
    reachedWarehouse: 'Reached Warehouse',
    approved: 'Approved',
    goHome: 'Go Home',
    updateReversePickupAddress: 'Update Reverse Pickup Address',
    updatePickupAddress: 'Update Pickup Address',
    fullName: 'Full Name',
    emailId: 'Email ID',
    city: 'City',
    area: 'Area',
    state: 'State',
    country: 'Country',
    phoneNumber: 'Phone Number',
    phoneCode: 'Phone Code',
    bankDetail: 'Bank Details For Refund',
    upiDetails: 'UPI Details',
    brandCredits: (brandName, brandCreditText) =>
      `${brandName}  Credits ${brandCreditText}`,
    backToSource: 'Back to Source',
    bankAccount: 'Bank Account',
    accountHolderName: 'Account Holder Name',
    upiName: 'UPI Name',
    upiId: 'UPI ID',
    accountNumber: 'Account Number',
    verifyAccountNumber: 'Re-enter Account Number',
    iban: 'IBAN',
    verifyIban: 'Re-enter IBAN to verify',
    ifscCode: 'IFSC Code',
    bankName: 'Bank Name',
    branchName: 'Branch Name',
    payoutLink: 'Refund via Payout links',
    discountCoupon: 'Refund via Discount Codes',
    giftCard: 'Refund via Gift Card',
    fetch: 'Fetch',
    enterReversePickupAddress: 'Enter Reverse Pickup Address',
    pleaseUploadSupportingImage: (imageUploadNote, imageQuantity) =>
      `Note: Please upload ${imageQuantity} ${imageUploadNote}`,
    processing: 'Processing',
    minimumImagesRequired: imageQuantity =>
      `Minimum ${imageQuantity} images required for this request`,
    returns: 'Returns',
    exchange: (exchangeText, returnText) => `${returnText} and ${exchangeText}`,
    enterYourOrderInformation: 'Enter your order information to get started.',
    ourReturnExchangePolicy: (exchangeText, returnText) =>
      `Our ${returnText} & ${exchangeText} Policy`,
    refundPolicy: 'Refund Policy',
    termsConditions: 'Terms & Conditions',
    stepOf: currentStep => `Step ${currentStep} of 3`,
    allPriceShownPerItem: 'All prices shown are at item level',
    qtyPerOrderNote: 'Note: Qty shows original ordered item quantity',
    qty: 'Qty',
    returnedQty: 'Returned quantity',
    placeFreshPickupRequest: 'Pickup cancelled, place fresh pickup request.',
    remove: 'Remove',
    return: 'Return',
    startReturn: 'Start Return',
    whyAreYouReturning: 'Why are you returning the product?', // optional
    whyYouWantReturn: 'Why do you want to return?',
    selfShipMode:
      'As you selected Self Ship Mode. Please pack the items securely to prevent any loss or damage during transit.',
    requestPlacedDate: 'Request Placed Date',
    logMeIn: 'Log me in',
    selectDate: 'Select Date',
    shippingSoon: 'Shipping Soon',
    dropInstruction:
      'Request you to please drop the shipment at the nearest shipping center of the assigned carrier partner.',
    max200Chars: 'Max. 200 characters',
    select: 'Select',
    charsLeft: chars => `${chars} characters left`
  }
};

export const stylishopStrings = {
  en: {
    men: 'Men',
    women: 'Women',
    clothing: 'Clothing',
    accessories: 'Accessories',
    lingerie: 'Lingerie & Nightwear',
    underwear: 'Underwear & Nightwear',
    help: 'Help',
    contactUs: 'Contact us',
    shipping: 'Shipping',
    returnsPolicy: 'Returns Policy',
    faq: 'FAQ',
    downloadApps: 'Download our apps',
    keepInTouch: 'Keep in touch',
    copyright: '@2021 Retail World Limited.',
    privacyPolicy: 'Privacy Policy',
    termOfUse: 'Terms Of Use'
  },
  ar: {
    men: 'الرجال',
    women: 'النساء',
    clothing: 'الملابس',
    accessories: 'إكسسوارات',
    lingerie: 'لانجري وملابس نوم',
    underwear: 'ملابس داخلية وملابس نوم',
    help: 'المساعدة',
    contactUs: 'اتصل بنا',
    shipping: 'الشحن',
    returnsPolicy: 'سياسة الإرجاع',
    faq: 'الأسئلة الشائعة',
    downloadApps: 'حمل التطبييق',
    keepInTouch: 'تواصل معنا',
    copyright: 'حقوق الطبع © 2021 - ريتيل وارلد ليمتد. جميع الحقوق محفوظة',
    privacyPolicy: 'سياسة الخصوصية',
    termOfUse: 'شروط الاستخدام'
  }
};

export const copyrightYear = new Date().getFullYear();
